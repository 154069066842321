

class User {
    // User DAO에 활용
    private _email: string;
    private _phone: string;
    private _full_name: string;
    private _user_type: string;
    private _point: number;


	constructor(email: string, phone: string, full_name: string, user_type: string, point : number) {
		this._email = email;
		this._phone = phone;
		this._full_name = full_name;
		this._user_type = user_type;
        this._point = point;
	}


    /**
     * Getter email
     * @return {string}
     */
	public get email(): string {
		return this._email;
	}

    /**
     * Getter phone
     * @return {string}
     */
	public get phone(): string {
		return this._phone;
	}

    /**
     * Getter full_name
     * @return {string}
     */
	public get full_name(): string {
		return this._full_name;
	}

    /**
     * Getter user_type
     * @return {string}
     */
	public get user_type(): string {
		return this._user_type;
	}

    /**
     * Setter email
     * @param {string} value
     */
	public set email(value: string) {
		this._email = value;
	}

    /**
     * Setter phone
     * @param {string} value
     */
	public set phone(value: string) {
		this._phone = value;
	}

    /**
     * Setter full_name
     * @param {string} value
     */
	public set full_name(value: string) {
		this._full_name = value;
	}

    /**
     * Setter user_type
     * @param {string} value
     */
	public set user_type(value: string) {
		this._user_type = value;
	}


    /**
     * Getter point
     * @return {number}
     */
	public get point(): number {
		return this._point;
	}

    /**
     * Setter point
     * @param {number} value
     */
	public set point(value: number) {
		this._point = value;
	}



}

export default User;