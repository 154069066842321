import { useState } from "react";
import { useHistory } from "react-router-dom";
import { UserLoginStatusType } from "src/data/type/status";
import { useSelector } from "src/hooks/useSelector";
import { userJoin } from "src/services/user/user.actions";
import completeImg from "src/assets/png/coplete.png";

import {
  ChildForm,
  LineDiv,
  RegisterBackground,
  RegisterInner,
  StepDiv,
  StepWrapperDiv,
  TitleDiv,
  ContentDiv,
  ProgressItem,
  ProgressIcon,
  CompleteIcon,
  CompleteItem,
  CompleteBtn,
  CompleteBtnContainer,
} from "./registerCompletionPage.style";
import useLoginModal from "../loginModal/LoginModal.hook";

export interface RegformProp {
  setStep(step: number): void;
  setFormData(form: any): void;
  formData: userJoin;
}

export const RegisterCompletePage = () => {
  const history = useHistory();
  const login_status = useSelector((state) =>
    state.userReducer.get_login_status()
  );
  if (login_status === UserLoginStatusType.LOGGEDIN) {
    history.push("/");
    return null;
  }
  const { open_login_modal } = useLoginModal();

  const goMainPage = () => {
    // To to main and Login
    history.push("/");
    try {
      open_login_modal();
    } catch (e) {
      console.error(`로그인 모달 열기 실패, 원인: ${e}`);
    }
  };

  return (
    <RegisterBackground>
      <RegisterInner>
        <CompleteItem>
          <CompleteIcon src={completeImg} />
          <TitleDiv>가입이 완료되었습니다!</TitleDiv>
        </CompleteItem>
        <CompleteBtnContainer>
          <CompleteBtn onClick={() => goMainPage()}>
            로그인
          </CompleteBtn>
        </CompleteBtnContainer>
      </RegisterInner>
    </RegisterBackground>
  );
};
