import { DOTS } from "src/components/table/usePagination";
import usePagination from "src/components/table/usePagination";
import {
  AbPaginationContainer,
  AbArrowLeft,
  AbArrowRight,
  AbPaginationItem,
  AbPaginationItemDots,
  PaginationBar,
  TableContainer,
} from "./paginationStyle";
import {
  AbTable,
  AbThead,
  AbTr,
  AbTh,
  AbTbodyTr,
  AbTbodyTd,
  BtnContainer,
  LookupBtn,
  LookupDisabledBtn,
  BtnDisabledContainer
} from "src/components/table/searchPageTableStyle";
import useSearchPage from "../searchPage/searchPage.hooks";
import useFloorSelectModal from "src/components/floorSelectModal/FloorSelectModal.hook";

interface paginationProp {
  siblingCount: number;
  pageSize: number;
}

const SearchPageTable = (properties: paginationProp) => {
  const p = properties;
  const {
    currentPage,
    setCurrentPage,
    currentSearchResult,
    currentResultMsg,
    totalCount,
    openPriceLookupPopup,
    pageBtnClick,
    pageCount,
    currentPageAtService
  } = useSearchPage();

  const { floorSelectModalVisible, openFloorSelectModal } =
    useFloorSelectModal();

  const paginationRange = usePagination(
    totalCount,
    pageCount,
    p.siblingCount,
    currentPageAtService
  );

  const onNext = () => {
    setCurrentPage(currentPageAtService + 1);
    pageBtnClick(currentPageAtService + 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPageAtService - 1);
    pageBtnClick(currentPageAtService - 1);
  };

  const clickPage = (num: number) => {
    setCurrentPage(num);
    pageBtnClick(num);
  };

  let lastPage: number | string = 0;
  if (paginationRange) {
    lastPage = paginationRange[paginationRange.length];
  }

  function isOdd(n: number) {
    if (n === 0) {
      return false;
    } else if (n % 2 === 1) {
      return true;
    } else {
      return false;
    }
  }

  if (totalCount > 0) {
    return (
      <TableContainer>
        <AbTable>
          <AbThead>
            <AbTr>
              <AbTh>구분</AbTh>
              <AbTh>고유번호</AbTh>
              <AbTh>소재지번</AbTh>
              <AbTh> </AbTh>
            </AbTr>
          </AbThead>
          <tbody>
            {currentSearchResult?.map((item, index) => {
              if (item.type === "집합건물" || item.type === "건물") {
                return (
                  <AbTbodyTr
                    key={index}
                    backgroundColor={isOdd(index) ? "fff" : "#f4f4f5"}
                  >
                    <AbTbodyTd>{item.type}</AbTbodyTd>
                    <AbTbodyTd>{item.addrid}</AbTbodyTd>
                    <AbTbodyTd>{item.addrtext}</AbTbodyTd>
                    <AbTbodyTd width={"12%"} mobileWidth={"25%"}>
                      <BtnContainer>
                        <LookupBtn
                          fontSize={"90%"}
                          onClick={() => {
                            item.type === "집합건물"
                              ? openPriceLookupPopup(
                                  item.type,
                                  item.addrid,
                                  item.addrtext,
                                  "0"
                                )
                              : openFloorSelectModal(
                                  item.type,
                                  item.addrtext,
                                  item.addrid
                                );
                          }}
                        >
                          <span> 주택가격 조회 </span>
                        </LookupBtn>
                      </BtnContainer>
                    </AbTbodyTd>
                  </AbTbodyTr>
                );
              } else {
                return (
                  <AbTbodyTr
                    key={index}
                    backgroundColor={isOdd(index) ? "fff" : "#f4f4f5"}
                  >
                    <AbTbodyTd>{item.type}</AbTbodyTd>
                    <AbTbodyTd>{item.addrid}</AbTbodyTd>
                    <AbTbodyTd>{item.addrtext}</AbTbodyTd>
                    <AbTbodyTd width={"12%"} mobileWidth={"25%"}>
                      <BtnDisabledContainer>
                        <LookupDisabledBtn
                          fontSize={"90%"}
                        >
                          <span> 조회불가 유형 </span>
                        </LookupDisabledBtn>
                      </BtnDisabledContainer>
                    </AbTbodyTd>
                  </AbTbodyTr>
                );
              }
            })}
          </tbody>
        </AbTable>
        <PaginationBar>
          <AbPaginationContainer>
            {/* {currentPage != 1 && (
              <AbPaginationItem onClick={onPrevious}>
                <AbArrowLeft />
              </AbPaginationItem>
            )} */}
            {paginationRange?.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <AbPaginationItemDots key={index}>
                    &#8230;
                  </AbPaginationItemDots>
                );
              } else if (pageNumber === currentPageAtService) {
                return (
                  <AbPaginationItem
                    key={index}
                    active={true}
                    // onClick={() => clickPage(Number(pageNumber))}
                  >
                    {pageNumber}
                  </AbPaginationItem>
                );
              } else {
                return (
                  <AbPaginationItem
                    key={index}
                    active={false}
                    onClick={() => clickPage(Number(pageNumber))}
                  >
                    {pageNumber}
                  </AbPaginationItem>
                );
              }
            })}
            {/* {currentPage != lastPage && (
              <AbPaginationItem onClick={onNext}>
                <AbArrowRight />
              </AbPaginationItem>
            )} */}
          </AbPaginationContainer>
        </PaginationBar>
      </TableContainer>
    );
  }
  return <p>empty result</p>;
};

export default SearchPageTable;
