import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Path, _client } from "src/apis/client";
import { GenReportResponse } from "src/data/api-response/address";
import { AnbuResponse, AnbuHistoryResponse } from "src/data/api-response/anbu";
import { GenReportStatusType } from "src/data/type/search";


export const GET_ANBU_HISTORY = "GET_ANBU_HISTORY";
export const GENERATE_ANBU_REPORT = "GENERATE_ANBU_REPORT";
export const GET_FREE_ANBU_HISTORY = "GET_FREE_ANBU_HISTORY";
export const GENERATE_FREE_ANBU_REPORT = "GENERATE_FREE_ANBU_REPORT";

export interface anbuRequest {
    user_phone: string
}


export interface genReportRequest {
    report_id: number,
    deposit: string,
    is_agent_contract: boolean,
    is_contract_longer_than_1_year: boolean,
    is_borrow_money_from_bank: boolean,
    user_input_house_price: string,
    user_input_auction_ratio: string,
    user_input_iros_debt: string,
    user_input_prior_deposit: string,
    user_input_house_type: string,
    user_input_officetel_type: boolean
}

export const getFreeAnbuHistory = createAsyncThunk<AnbuResponse>(
    GET_FREE_ANBU_HISTORY,
    async (data) => {
      return await _client.postApi(Path.get_free_anbu_history, data);
    }
);

export const generateFreeAnbuReport = createAsyncThunk<GenReportResponse, genReportRequest>(
    GENERATE_FREE_ANBU_REPORT,
    async (data) => {
      return await _client.postApi(Path.generate_free_anbu_report, data);
    }
);

export const getAnbuHistory = createAsyncThunk<AnbuResponse, anbuRequest>(
    GET_ANBU_HISTORY,
    async (data) => {
      return await _client.postApiWithToken(Path.get_anbu_history, data);
    }
);

export const generateAnbuReport = createAsyncThunk<GenReportResponse, genReportRequest>(
    GENERATE_ANBU_REPORT,
    async (data) => {
      return await _client.postApiWithToken(Path.generate_anbu_report, data);
    }
);

export const clearReportResponse = createAction("reportResponse/clear")
export const clearAnbuHistory = createAction("AnbuHistory/clear")