import { PaymentData } from "src/data/api-response/payment";
import Payment from "src/data/models/payment";
import Service from "../service";


export default class PaymentService extends Service {
    private _payments: Payment[] = [];

    public updatePayments = (paymentDataList : PaymentData[]): void => {
        paymentDataList.forEach(
            paymentData => {
                console.log(paymentData);
            }
        )
    }

}