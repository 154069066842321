import { UserLoginStatusType } from "src/data/type/status";
import {
  clearAnbuHistory,
  clearReportResponse,
  getAnbuHistory,
  getFreeAnbuHistory,
} from "src/services/anbu/anbu.actions";
import useLoginModal from "../../loginModal/LoginModal.hook";
import { useSelector } from "src/hooks/useSelector";
import {
  user_logout,
  user_me,
  user_set_login_status,
  user_verify_token,
} from "src/services/user/user.actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import logoGreen from "src/assets/png/logo_green.png";
// import logoGreen from "src/assets/png/HeaderLogo.png";
import Coin from "src/assets/png/coin.png";
import { useHistory } from "react-router-dom";
import {
  HeaderContainer,
  LeftContainer,
  LeftHeaderItem,
  RightContainer,
  WrapperContentHeader,
  RightHeaderItem,
  LeftHeaderSubText,
  MainLogoImg,
  PointContainer,
  CoinImg,
} from "./Header.style";
import { _client } from "src/apis/client";
import { setCurrentTab } from "src/services/search/search.actions";

export const GrayHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loginStatus, user, point } = useSelector((state) => {
    return {
      loginStatus: state.userReducer.get_login_status(),
      user: state.userReducer.get_user(),
      point: state.userReducer.get_user()?.point,
    };
  });

  //
  useEffect(() => {
    let storageToken = localStorage.getItem("accessToken");
    let sessionToken = sessionStorage.getItem("accessToken");
    // console.log(`영구: ${storageToken}, 임시: ${sessionToken}`);
    if (storageToken) sessionStorage.setItem("accessToken", storageToken);
    dispatch(user_verify_token());
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(
        getAnbuHistory({
          user_phone: user.phone,
        })
      );
    } else {
      dispatch(getFreeAnbuHistory());
    }
  }, [user]);

  useEffect(() => {
    // console.log(`유저 로그인 상태 변경 ${loginStatus}`);
    if (loginStatus === UserLoginStatusType.LOGGEDIN) dispatch(user_me());
  }, [loginStatus]);

  const logout = () => {
    history.push("/");
    dispatch(user_logout());
    // 로그아웃시 안부 리포트 값 등이 리엑트 store에 남아있음, 초기화
    dispatch(clearReportResponse());
    dispatch(clearAnbuHistory());
  };
  const { open_login_modal } = useLoginModal();
  const openLoginPopup = () => {
    try {
      open_login_modal();
    } catch (e) {
      console.error(`로그인 모달 열기 실패, 원인: ${e}`);
    }
  };

  const goJoinPage = () => {
    history.push("/join");
  };

  const goRegisterPage = () => {
    dispatch(clearReportResponse());
    dispatch(clearAnbuHistory());
    history.push("/register");
  };

  const goMainPage = () => {
    dispatch(clearReportResponse());
    dispatch(clearAnbuHistory());
    history.push("/");
  };

  const goSearchMainPage = () => {
    history.push("/search");
    dispatch(setCurrentTab(0));
  };

  const handleLogoutClick = () => {
    logout();
  };

  if (loginStatus === UserLoginStatusType.LOADING) {
    return (
      <WrapperContentHeader>
        <HeaderContainer>
          <LeftContainer>
            <LeftHeaderItem onClick={goMainPage}>
              {/* <img style={{width: 206.7, height: 18.72}} src={logoGreen}></img> */}
              <MainLogoImg src={logoGreen}></MainLogoImg>
              {/* <img src={logoGreen}></img> */}
            </LeftHeaderItem>
            <LeftHeaderSubText> 전세 계약을 위한 모든 것</LeftHeaderSubText>
          </LeftContainer>
          <RightContainer>
            {/* <RightHeaderItem onClick={goJoinPage}> 회원가입 </RightHeaderItem> */}
            <RightHeaderItem> </RightHeaderItem>
            <RightHeaderItem> </RightHeaderItem>
          </RightContainer>
        </HeaderContainer>
      </WrapperContentHeader>
    );
  }

  if (loginStatus === UserLoginStatusType.LOGGEDOUT) {
    return (
      <WrapperContentHeader>
        <HeaderContainer>
          <LeftContainer>
            <LeftHeaderItem onClick={goMainPage}>
              {/* <img style={{width: 206.7, height: 18.72}} src={logoGreen}></img> */}
              <MainLogoImg src={logoGreen}></MainLogoImg>
              {/* <img src={logoGreen}></img> */}
            </LeftHeaderItem>
            <LeftHeaderSubText> 전세 계약을 위한 모든 것</LeftHeaderSubText>
          </LeftContainer>
          <RightContainer>
            {/* <RightHeaderItem onClick={goJoinPage}> 회원가입 </RightHeaderItem> */}
            <RightHeaderItem onClick={goRegisterPage}>
              {" "}
              회원가입{" "}
            </RightHeaderItem>
            <RightHeaderItem onClick={openLoginPopup}> 로그인 </RightHeaderItem>
          </RightContainer>
        </HeaderContainer>
      </WrapperContentHeader>
    );
  } else {
    return (
      <WrapperContentHeader>
        <HeaderContainer>
          <LeftContainer>
            <LeftHeaderItem onClick={() => goSearchMainPage()}>
              <MainLogoImg src={logoGreen}></MainLogoImg>
            </LeftHeaderItem>
            <LeftHeaderSubText> 전세 계약을 위한 모든 것</LeftHeaderSubText>
          </LeftContainer>
          <RightContainer>
            <RightHeaderItem onClick={handleLogoutClick}>
              {" "}
              로그아웃{" "}
            </RightHeaderItem>
            <RightHeaderItem onClick={() => history.push("/mypage")}>
              {" "}
              내정보{" "}
            </RightHeaderItem>
            <RightHeaderItem>
              <PointContainer>
                <CoinImg src={Coin}></CoinImg>
                {point}
              </PointContainer>
            </RightHeaderItem>
          </RightContainer>
        </HeaderContainer>
      </WrapperContentHeader>
    );
  }
};
