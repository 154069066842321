import styled from "styled-components";

export const UserInfoTitle = styled.h1`
  font-size: 16px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  color: #48484a;
`;

export interface activeProp {
  active?: boolean;
  finished?: boolean;
}

export const RedStar = styled.span`
  color: red;
`;

export const GrayText = styled.span`
  color: #757575;
`;

export const SubInfo = styled.h1`
  font-size: 12px;
  padding-top: 10px;
  padding-left: 5px;
  font-weight: 550;
  height: 12px
  line-height: 1.4;
  color: #008128;

`;

export interface active {
  active?: boolean;
}

export const FormStatement = styled.h1`
  font-size: 15px;
  padding-top: 10px;
  padding-left: 5px;
  font-weight: 550;
  line-height: 1.4;
  color: #48484a;
  height: 14px;
`;

export const InputContainer = styled.div<active>`
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 3px;
  border-radius: 5px;
  border: solid 1px #d1d1d6;
  background-color: ${(props) => (props.active ? "#f4f4f5" : "#fff")};
`;


export const Input = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

export interface confirmProp {
  active?: boolean;
}
export const ConfirmBtnContainer = styled.div`
  display:  "flex";
  flex-direction: column;
  height: 40px;
  border-radius: 5px;

  background-color: #008128;
  margin-top: 30px;
  font-family: NotoSansKR;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  :hover {
    background-color: rgba(0, 162, 51, .9);
  }
`;

export const ConfirmBtn = styled.button`
  width: 100%;
  height: 100%;

  padding-top: 5px;
  padding-bottom: 5px;

  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #008128;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }

  @media (max-width: 500px){
    font-size: 15px;
  }

`;
