import { useState } from "react";
import { Path, _client } from "src/apis/client";
import { PhoneAuthType } from "src/data/type/phoneAuth";
import { RegformProp } from "./registerPage";
import {
  Statement,
  GrayText,
  PhoneNumberInputContainer,
  PhoneNumberInput,
  VerifyBtn,
  ConfirmBtn,
  Comment,
  FailComment,
  ContinueBtn,
  ContinueBtnContainer,
} from "./verifyPhonePage.style";

export const VerifyPhonePage = (prop: RegformProp) => {
  const { setStep, setFormData, formData } = prop;

  const [phone, setPhone] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [codeHasBeenSent, setCodeHasBeenSent] = useState(false);

  const [verifyCode, setVerifyCode] = useState("");
  const [validVerifyCode, setValidVerifyCode] = useState(false);
  const [verifyHasBeenSent, setVerifyHasBeenSent] = useState(false);

  const [isButtonActive, setIsButtonActive] = useState(false);

  const onChangePhoneNumber = (e: string) => {
    setPhone(e);
  };

  const onChangeVerifiyCode = (e: string) => {
    setVerifyCode(e);
  };

  const clickHandlerCreate = () => {
    console.log("clickHandlerCreate()")
    // let regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    let regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    setValidPhoneNumber(regExp.test(phone));
    console.log(`핸드폰번호 유효성 검사 : ${regExp.test(phone)}`);

    setCodeHasBeenSent(true)

    if (regExp.test(phone)) {
        console.log("msg request has been sent")
      _client
        .postApi(
          Path.phone_auth(PhoneAuthType.CREATE, {
            phone: phone,
            verify_code: undefined,
          })
        )
        .catch((e) => {
          setIsButtonActive(false);
          alert(`다음과 같은 이유로 인증에 실패하였습니다: ${e}`);
        })
        .then(() => setIsButtonActive(true));
    }
  };

  const clickHandlerVerfiy = () => {
    let regExp = /^\d{4}$/;
    setValidVerifyCode(regExp.test(verifyCode));
    console.log(`핸드폰 인증번호 4자리 : ${regExp.test(verifyCode)}`);

    _client
      .postApi(
        Path.phone_auth(PhoneAuthType.VERIFY, {
          phone: phone,
          verify_code: verifyCode,
        })
      )
      .then(() => {
        setFormData({ ...formData, phone });
        setStep(3);
      })
      .catch((e) => {
        setVerifyHasBeenSent(true);
        console.error(e);
      });
  };

  return (
    <>
      <Statement>
        {"  "}휴대폰번호 인증{" "}
        <GrayText>(휴대폰 번호가 로그인을 위한 ID로 사용됩니다.)</GrayText>
      </Statement>
      {/* <PhoneNumberInputContainer active={true} finished={verifySuccess}> */}
      <PhoneNumberInputContainer active={true} finished={false}>
        <PhoneNumberInput
          type="number"
          value={phone}
          pattern="[0-9]*"
          inputMode="numeric"
          disabled={false}
          onChange={(e) => onChangePhoneNumber(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              clickHandlerCreate();
            }
          }}
          placeholder="본인의 휴대폰 번호를 입력하세요"
        />
        <VerifyBtn
          className="VerifyBtn"
          disabled={false}
        //   disabled={!validPhoneNumber || isButtonActive}
          onClick={() => clickHandlerCreate()}
        >
          {" "}
          인증하기{" "}
        </VerifyBtn>
      </PhoneNumberInputContainer>
      <Comment active={codeHasBeenSent && validPhoneNumber}>
        {" "}
        * 해당 휴대폰번호로 인증번호를 전송했습니다. 문자를 확인해주시기
        바랍니다.{" "}
      </Comment>
      <FailComment active={codeHasBeenSent && !validPhoneNumber}>
        {" "}
        * 올바른 휴대폰 번호를 입력해주세요.{" "}
      </FailComment>
      <PhoneNumberInputContainer
        active={isButtonActive}
        finished={false}
      >
        <PhoneNumberInput
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          value={verifyCode}
          disabled={false}
          onChange={(e) => onChangeVerifiyCode(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              clickHandlerVerfiy();
            }
          }}
          placeholder="인증 코드"
        />
        <ConfirmBtn
          className="btn"
          disabled={!isButtonActive}
          onClick={() => clickHandlerVerfiy()}
        >
          {" "}
          확인{" "}
        </ConfirmBtn>
      </PhoneNumberInputContainer>
      {/* <Comment active={codeHasBeenSent && validVerifyCode}>
        {" "}
        * 인증이 정상적으로 완료되었습니다.
      </Comment> */}
      <FailComment active={verifyHasBeenSent}>
        {" "}
        * 인증이 실패하였습니다. 다시 시도해주세요.{" "}
      </FailComment>
    </>
  );
};
