import styled from "styled-components";

export const MyPageBgr = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
`;

export const OuterCtnr = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 1260px;
  overflow-wrap: break-word;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;

  box-sizing: border-box;

  @media (max-width: 1440px) {
    margin-top: 25px;
    width: 70%;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const TopCtnr = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  box-sizing: border-box;

  margin-top: 25px;
  margin-bottom: 25px;
  padding-bottom: 10px;
  padding-top: 10px;

  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.1px;
  text-align: left;
  color: #fff;

  opacity: 0.95;
  border-radius: 14px;
  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.07);
  background-color: #48484a;
`;

export const MiddleCtnr = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  box-sizing: border-box;

  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;

  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.1px;
  text-align: left;

  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.07);
  border: solid 2px #d1d1d6;
  background-color: #fff;
  border-radius: 14px;
  color: #48484a;

  @media (max-width: 500px) {
    padding: 20px;
    height: auto;
  }
`;

export const UsrInfoCtnr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  min-width: 200px;
  height: auto;
  padding: 20px;

  box-sizing: border-box;

  @media (max-width: 1025px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    height: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
`;
export const UsrInfoRowCtnr = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: auto;

  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.1px;
  text-align: left;
`;

export const MoreMenuCtnr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  min-width: 200px;
  height: auto;
  padding-right: 20px;

  box-sizing: border-box;

  @media (max-width: 500px) {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
`;

export const StatisticsCtnr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  min-width: 200px;
  height: auto;
  padding-right: 20px;

  box-sizing: border-box;

  @media (max-width: 500px) {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
`;

export const StatsInnerCtnr = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 150px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;

  border-radius: 8px;
  box-shadow: 0px 17px 25px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;

  font-family: NotoSansKR;
  font-size: 19px;
  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.98;
  letter-spacing: 0.12px;
  text-align: left;
  color: #48484a;

  @media (max-width: 1440px) {
    height: 180px;
    width: 200px;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const StatsTittleCtnr = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
  border-bottom: 1px solid #d1d1d6;
  box-sizing: border-box;
`;

export const StatsContentCtnr = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 115px;
  box-sizing: border-box;
`;

export const StatsIconCtnr = styled.div`
  display: flex;
  width: 30%;
  box-sizing: border-box;
  height: 40px;
  margin-top: 50px;
`;

export const Img = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
`;

export const StatsNumberCtnr = styled.div`
  display: flex;
  width: 70%;
  box-sizing: border-box;
  justify-content: right;
  align-items: center;
  margin-top: 30px;

  font-size: 65px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.45px;
  text-align: right;
  color: #00982f;
`;

export const MoreMenuInnerCtnr = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 150px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;

  box-sizing: border-box;

  border-radius: 8px;
  box-shadow: 0px 17px 25px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;

  font-family: NotoSansKR;
  font-size: 19px;
  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.98;
  letter-spacing: 0.12px;
  text-align: left;
  color: #48484a;

  @media (max-width: 1440px) {
    height: 180px;
    width: 200px;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

// Tittle height + Row height = MoreMenuInnerCtnr height
export const MoreMenuTittleCtnr = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;
  margin-bottom: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid #d1d1d6;
  color: #48484a;
`;

export const MoreMenuRowCtnr = styled.div`
  display: inline-flex;
  width: 100%;
  height: 33px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.45px;
  text-align: left;
  color: #2c2c2e;
  cursor: pointer;
`;
//   color: #aeaeb2;

export const BottomCtnr = styled.div`
  display: inline-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  margin-top: 25px;
  margin-bottom: 25px;

  box-sizing: border-box;

  font-family: NotoSansKR;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 0.13px;
  text-align: left;
  color: #48484a;
`;

export const MyShellCtnr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 49%;
  min-width: 250px;
  height: auto;
  padding: 20px;
  margin-top: 10px;

  box-sizing: border-box;

  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.07);
  border: solid 2px #d1d1d6;
  background-color: #fff;
  border-radius: 14px;

  @media (max-width: 700px) {
    height: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
`;

export const MyShellTittle = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 40px;
  font-weight: 600;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const MyShellContents = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 40px;
  font-weight: 600;
  color: #00982f;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const MyShellSubtittle = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 20px;
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const MyShellImg = styled.div`
  display: flex;
  align-text: left;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
  }
`;

export const MyShellInner = styled.div`
  display: flex;
  align-text: left;
  width: 120px;
  height: 135px;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
  }
`;

export const MyShellCount = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;

  font-family: NotoSansKR;
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.31px;
  text-align: center;
  color: #00982f;

  @media (max-width: 500px) {
  }
`;

export const MyShellBtn = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  height: 40px;
  justify-content: center;

  border-radius: 5px;
  background-color: #008128;

  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.16;
  letter-spacing: 0.11px;
  text-align: left;
  color: #fff;

  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const InquiryCtnr = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  min-width: 250px;
  height: auto;
  padding: 20px;
  margin-top: 10px;

  box-sizing: border-box;

  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.07);
  border: solid 2px #d1d1d6;
  border-radius: 14px;
  background-color: #fff;

  @media (max-width: 700px) {
    height: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
`;

export const InquiryTittle = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  font-weight: 600;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const InquiryTittleInput = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  @media (max-width: 500px) {
  }
`;

export const InqueryNotice = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  margin-top: 20px;
  align-items: center;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  @media (max-width: 500px) {
    text-align: center;
    justify-content: center;
  }
`;

export const InquiryEmailInput = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  @media (max-width: 500px) {
  }
`;

export const InquiryContentInput = styled.div`
  display: flex;
  width: 100%;
  height: 135px;

  @media (max-width: 500px) {
  }
`;

export const InquiryBtn = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;

  @media (max-width: 500px) {
  }
`;

export const CoinImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 150px;
  height: 100px;
`;

export const AgentOuterCtnr = styled.div`
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  margin-top: 25px;
  margin-bottom: 25px;

  box-sizing: border-box;

  font-family: NotoSansKR;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 0.13px;
  text-align: left;
  color: #48484a;
`;

export const AgentShellCtnr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 250px;
  height: auto;
  padding: 20px;
  margin-top: 10px;
  box-sizing: border-box;

  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.07);
  border: solid 2px #d1d1d6;
  background-color: #fff;
  border-radius: 14px;

  @media (max-width: 700px) {
    height: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
`;

export const AgentDescription = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding-left: 10px;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: #e2e3e5;

  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: light;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.16;
  letter-spacing: 0.11px;
  text-align: left;
  color: #383d41;

  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const AgentBtn = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  height: 40px;
  justify-content: center;

  border-radius: 5px;
  background-color: #008128;

  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.16;
  letter-spacing: 0.11px;
  text-align: left;
  color: #fff;

  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const AgentItemDesc = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: #e2e3e5;
  border: 2px solid rgba(0, 0, 0, 0.15);

  font-family: NotoSansKR;
  text-indent: 1em;
  font-size: 18px;
  font-weight: light;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.16;
  letter-spacing: 0.11px;
  text-align: left;
  color: #383d41;

  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const RedItemDesc = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: #fadbd8;;
  border: 2px solid #f8cdc8;

  font-family: NotoSansKR;
  text-indent: 1em;
  font-size: 18px;
  font-weight: light;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.16;
  letter-spacing: 0.11px;
  text-align: left;
  color: #78281f;

  @media (max-width: 500px) {
    width: 80%;
  }
`;
