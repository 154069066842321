import { createAction } from "@reduxjs/toolkit";
import { UiComponentType } from "src/data/type/ui";

export interface UiComponentVisible {
    id: UiComponentType,
    visible: boolean
}

export interface UiComponentClicked {
    id: UiComponentType,
    clicked: boolean
}

export const UI_VISIBLE = "UI_VISIBLE";
export const UI_CLICKED = "UI_CLICKED";

export const uiVisible = createAction<UiComponentVisible>(UI_VISIBLE);
export const uiClicked = createAction<UiComponentClicked>(UI_CLICKED);