import { ComponentType, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserLoginStatusType } from 'src/data/type/status';
import { useSelector } from 'src/hooks/useSelector';


interface AuthRouteProp {
    path: string
    exact?: boolean
    component: ComponentType
}

export const PrivateRoute = (props : AuthRouteProp) => {
    const {path, exact, component} = props;
    const loginStatus = useSelector((state) => {
        return state.userReducer.get_login_status()
    });
    useEffect(
        ()=>{
            console.log(`private route : 로그인 상태 ${loginStatus}, path: ${path}`)
        },[loginStatus]
    )
    if (loginStatus !== UserLoginStatusType.LOGGEDOUT) {
        return <Route exact={exact} path={path} component={component} />;
    } else return <Redirect to="/" />
    
}