import usePriceLookupModal from "./PriceLookupModal.hook";
import { Link, useHistory } from "react-router-dom";
import ringLoadingGif from "src/assets/gif/ring-loading.gif";
import { UserLoginStatusType } from "src/data/type/status";
import {
  ModalInnerDiv,
  PriceLookupMainDiv,
  CloseBtn,
  TitleRow,
  BldInfoRow,
  BtnRow,
  BldInfoItemRow,
  InfoTittle,
  InfoContent,
  UnitName,
} from "./PriceLookupModal.style";
// import Modal from "../common/Modal";
import React, { CSSProperties, ReactNode } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { UiComponentType } from "src/data/type/ui";
import { uiVisible } from "src/services/ui/ui.actions";
import closeBtnImg from "src/assets/png/close.png";
import { useSelector } from "src/hooks/useSelector";
import { PriceLookupStatusType } from "src/data/type/search";
import {
  cleanPriceLookup,
  initPriceLookupResult,
  priceLookup,
} from "src/services/search/search.actions";
import useLoginModal from "../loginModal/LoginModal.hook";

interface ModalProps {
  isOpen: boolean;
  modalMainStyle?: CSSProperties;
  closeBtnStyle?: CSSProperties;
  children: ReactNode;
}

const backgroudStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  minWidth: "400px",
  minHeight: "400px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 10,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -50,
  display: "block",
  width: "28px",
  height: "27px",
  transition: "transform 0.1s",
  border: 0,
  backgroundColor: "transparent",
};

const PriceLookupModalPanel = (prop: ModalProps) => {
  const { isOpen, modalMainStyle, closeBtnStyle, children } = prop;
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(cleanPriceLookup());
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_PRICE_LOOKUP,
        visible: false,
      })
    );
  };

  let mainStyle = defatulMainStyle;
  if (modalMainStyle) mainStyle = modalMainStyle;

  if (isOpen)
    return (
      <>
        <div style={backgroudStyle} onClick={closeModal} />
        <PriceLookupMainDiv>
          <CloseBtn onClick={closeModal} backgroundImage={closeBtnImg} />
          {children}
        </PriceLookupMainDiv>
      </>
    );
  else return null;
};

export const PriceLookupModal = () => {
  const dispatch = useDispatch();
  const {
    confirm_anbu_issue,
    priceLookupModalVisible,
    priceLookupApiStatus,
    priceLookupComment,
    houseType,
    isDasaedae,
    priceType,
    privArea,
    housePrice,
  } = usePriceLookupModal();
  const history = useHistory();

  const closeModal = () => {
    dispatch(cleanPriceLookup());
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_PRICE_LOOKUP,
        visible: false,
      })
    );
  };

  const gotoPay = () => {
    // close modal first
    dispatch(cleanPriceLookup());
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_PRICE_LOOKUP,
        visible: false,
      })
    );
    history.push("/mypage");
  };

  if (
    priceLookupModalVisible &&
    priceLookupApiStatus === PriceLookupStatusType.Possible
  ) {
    return (
      <>
        <PriceLookupModalPanel isOpen={priceLookupModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              <span>
                결과 조회가 가능한 매물입니다.
                <br />
                아래 목록에서 유형과 면적 정보를 한번 더 확인해주세요.
              </span>
            </TitleRow>
            <BldInfoRow>
              <BldInfoItemRow>
                <InfoTittle>주택 유형</InfoTittle>
                <InfoContent>
                  <span>{houseType}</span>
                </InfoContent>
              </BldInfoItemRow>
              {/* <BldInfoItemRow>
                <InfoTittle>등기 유형</InfoTittle>
                <InfoContent>
                  <span>{isDasaedae}</span>
                </InfoContent>
              </BldInfoItemRow> */}
              <BldInfoItemRow>
                <InfoTittle>전용 면적</InfoTittle>
                <InfoContent>
                  <span>{privArea}</span>
                  <UnitName>제곱미터</UnitName>
                </InfoContent>
              </BldInfoItemRow>
              <BldInfoItemRow>
                <InfoTittle>시세 유형</InfoTittle>
                <InfoContent>
                  <span>{priceType}</span>
                </InfoContent>
              </BldInfoItemRow>
              <BldInfoItemRow>
                <InfoTittle>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#005d1d",
                      textDecoration: "none",
                      whiteSpace: "pre-wrap",
                      textDecorationLine: "none",
                      width: "100%",
                    }}
                    href="https://www.khug.or.kr/hug/web/ig/dr/igdr000001.jsp"
                  >
                    HUG
                    <br />
                    기준 시세
                  </a>
                </InfoTittle>
                <InfoContent>
                  <span>안부 조회 후 확인 가능</span>
                </InfoContent>
              </BldInfoItemRow>
            </BldInfoRow>
            <BtnRow onClick={confirm_anbu_issue}>
              안부 조회하기 <br />
              <br />
              <div style={{ fontSize: "17px" }}>
                (등본 발급 및 껍데기 1개 차감)
              </div>
            </BtnRow>
          </ModalInnerDiv>
        </PriceLookupModalPanel>
      </>
    );
  } else if (
    priceLookupModalVisible &&
    priceLookupApiStatus == PriceLookupStatusType.Impossible
  ) {
    return (
      <>
        <PriceLookupModalPanel isOpen={priceLookupModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              <span>
                {" "}
                아래와 같은 문제가 발생하여 해당 매물은 안전성 분석이
                불가합니다.{" "}
              </span>
            </TitleRow>
            <BldInfoRow>{priceLookupComment}</BldInfoRow>
            <BtnRow onClick={closeModal}>확인</BtnRow>
          </ModalInnerDiv>
        </PriceLookupModalPanel>
      </>
    );
  } else if (
    priceLookupModalVisible &&
    priceLookupApiStatus == PriceLookupStatusType.LackOfCoin
  ) {
    return (
      <>
        <PriceLookupModalPanel isOpen={priceLookupModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              <span>
                본 서비스는 실시간 등본 발급 행정 수수료
                (700원)를 포함한 유료 서비스입니다.
                <br />
                이용권을 충전해주세요.
              </span>
            </TitleRow>
            <BtnRow onClick={gotoPay}>충전하기</BtnRow>
          </ModalInnerDiv>
        </PriceLookupModalPanel>
      </>
    );
  } else if (
    priceLookupModalVisible &&
    priceLookupApiStatus == PriceLookupStatusType.Paying
  ) {
    return (
      <>
        <PriceLookupModalPanel isOpen={priceLookupModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              <span>
                {" "}
                요청을 처리중입니다. <br />
                잠시 뒤 조회 내역으로 이동합니다.{" "}
              </span>
            </TitleRow>
            <img src={ringLoadingGif} width="10%" />
          </ModalInnerDiv>
        </PriceLookupModalPanel>
      </>
    );
  } else if (
    priceLookupModalVisible &&
    priceLookupApiStatus === PriceLookupStatusType.Loading
  ) {
    return (
      <>
        <PriceLookupModalPanel isOpen={priceLookupModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              <span>
                {" "}
                건축물 대장 및 시세를 조회중입니다 <br />
                잠시만 기다려주세요 <br />
                (최대 1분)
              </span>
            </TitleRow>
            <img src={ringLoadingGif} width="10%" />
          </ModalInnerDiv>
        </PriceLookupModalPanel>
      </>
    );
  } else {
    return null;
  }
};
