import { createAsyncThunk } from "@reduxjs/toolkit";
import { Path, _client } from "src/apis/client";
import { PaymentsResponse } from "src/data/api-response/payment";

export interface PaymentInfo {
    userId: string
}

export interface PaymentRequest {
    paymentKey: string
    orderId: string
    amount: number
}

export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const REQ_PAYMENTS = "REQ_PAYMENTS";


export const fetchPayments = createAsyncThunk<PaymentsResponse, string>(
    FETCH_PAYMENTS,
    async (data) => {
        return await _client.fetchApiWithToken(Path.payment_list);
    }
);