export enum SearchInputStatusType {
    Enabled = "Enabled", // 주소 입력 가능 상태
    Disabled = "Disabled", // 주소 입력 불가능 상태
}

export enum SearchResultStatusType {
    Initial = "Initial", // 초기상태, row에 암것도 없음
    Ready = "Ready", // Status = 정상인 상태, 검색전 
    Loading = "Loading", // 검색중
    Zero = "Zero", // 주소를 찾지못했습니다. 확인 후 다시 입력해주세요.
    IrosError = "IrosError", // 인터넷 등기소 점검중입니다. 공지사항을 확인해주세요.
    InternalError = "InternalError", // 서비스 점검중입니다. 잠시 후 다시 이용해주세요.
    TooMany = "TooMany",
    Normal = "Normal" // 현재 검색결과가 표시되고 있는 상태
}

export enum PriceLookupStatusType {
    Initial = "Initial",
    Loading = "Loading", 
    Possible = "Possible",
    Impossible = "Imossible",
    LackOfCoin = "LackOfCoin",
    InternalError = "InternalError",
    Paying = "Paying", 
}

export enum FloorSelectStatusType {
    Possible = "Possible",
    Impossible = "Impossible",
    Loading = "Loading"
}

export enum GenReportStatusType {
    Initial = "Initial",
    Loading = "Generating", 
    Possible = "Done",
    Impossible = "Fail",
    InternalError = "InternalError"
}

export enum ResultPartType {
    None = "None",
    Loading = "Loading", 
    Done = "Done"
}

export enum ConclusionType {
    None = "None",
    Safe = "Safe",
    SafeEquip = "SafeEquip",
    WatchOut = "WatchOut",
    Danger = "Danger"
}

export enum OpenHistoryStatus {
    HistoryMoreThanOne = "HistoryMoreThanOne",
    HistoryZero = "HistoryZero", 
    NotLogged = "NotLogged"
}
