import { DOTS } from "src/components/openHistory/useHistoryPagination";
import {
  ConclusionType,
  GenReportStatusType,
  OpenHistoryStatus,
  ResultPartType,
} from "src/data/type/search";
import usePagination from "src/components/openHistory/useHistoryPagination";
import {
  OuterContainer,
  Card,
  CardStateInner,
  CardStateRow,
  CardStateLeft,
  CardStateRight,
  CardPreviewRow,
  CardPreviewLeft,
  CardPreviewRight,
  CardContentRow,
  CardContentLeft,
  CardContentRight,
  AnbuContent,
  CardConfirmRow,
  CardConfirmInner,
  CardConfirmLeft,
  CardConfirmRight,
  State,
  CardRightSideButton,
  CardRightSideImg,
  PreviewAddr,
  PreviewSpan,
  ContentItem,
  ContentTittle,
  CommentTittle,
  ContentComments,
  Content,
  InputContent,
  DepositInput,
  OpenBtn,
  OpenImg,
  CardPreviewInner,
  CardContentInner,
  CardComment,
  RadioBtnContainer,
  RadioBtnLabel,
  RadioBtn,
  GenerateButton,
  GenerateButtonDisabled,
  DetailComment,
  DetailCommentArrow,
  DetailOuterCtnr,
  Wrapper,
  Item,
  RadioButtonLabel,
  RadioButton,
  OfficetelComment,
  CardResultRow,
  CardResultInner,
  CardResultLeft,
  CardResultRight,
  ResultLogo,
  ReportButton,
  ReportButtonDisabled,
  ExplainComment,
  ExplainCtnr,
  ExplainTittle,
  ExplainArrow,
  InitialGenerateButton,
} from "src/components/openHistory/openHistoryStyle";

import useOpenHistory, {
  progressState,
  ShowingData,
} from "./openHistory.hooks";
import trashCanImg from "src/assets/png/trash.png";
import exportImg from "src/assets/png/export_icon.png";
import refreshImg from "src/assets/png/refresh.png";
import safeImg from "src/assets/png/safe_icon.png";
import safeEquipImg from "src/assets/png/safe_equip_icon.png";
import watchOutImg from "src/assets/png/watch_out_icon.png";
import dangerImg from "src/assets/png/danger_icon.png";
import pdfDownImg from "src/assets/png/pdf_down.png";
import clickImg from "src/assets/png/click_hand.png";

// https://loading.io 에서 생성 & 다운 받음
import ringLoadingGif from "src/assets/gif/ring-loading.gif";
import arrowDownImg from "src/assets/png/arrow-down.png";

function createSingleCard(
  anbuData: ShowingData,
  exportCard: (idx: number) => void,
  refreshAllCards: () => void,
  updateDeposit: (id: number, deposit: string) => void,
  updatePriorDeposit: (id: number, deposit: string) => void,
  updateAuctionRatio: (id: number, deposit: string) => void,
  updatePriorDebt: (id: number, deposit: string) => void,
  toggleFold: (id: number) => void,
  toggleCommentFold: (id: number) => void,
  toggleNoticeFold: (id: number) => void,
  toggleGuideFold: (id: number) => void,
  setAgent: (id: number, yes: boolean) => void,
  set1Year: (id: number, yes: boolean) => void,
  setLoan: (id: number, yes: boolean) => void,
  setOfficetel: (id: number, yes: boolean) => void,
  genReport: (id: number) => void,
  openIrosCopyUrl: (idx: number) => void,
  openBldCopyUrl: (idx: number) => void,
  openAnbuUrl: (idx: number) => void,
  currentAnbuUrl: string
) {
  let iros_issue_state: string;
  let bld_info_issue_state: string;
  let addr_type: string = anbuData.houseType;
  if (anbuData.irosIssueState) {
    iros_issue_state = "등기부등본 원본 보기";
  } else {
    iros_issue_state = "등기부등본 조회 진행중";
  }
  if (anbuData.bldInfoIssueState) {
    bld_info_issue_state = "건축물 대장부 원본 보기";
  } else {
    bld_info_issue_state = "건축물 대장부 조회 진행중";
  }

  let border_color: string;
  let state_color: string;
  if (anbuData.conclusionType == ConclusionType.None) {
    border_color = "#005d1d";
    state_color = "#00982f";
  } else if (anbuData.conclusionType == ConclusionType.Safe) {
    // border_color = "#d1d1d6";
    border_color = "#48484a";
    // border_color = "#00982f";
    state_color = border_color;
  } else if (anbuData.conclusionType == ConclusionType.SafeEquip) {
    border_color = "#48484a";
    // border_color = "#d1d1d6";
    // border_color = "#ff9500";
    state_color = border_color;
  } else if (anbuData.conclusionType == ConclusionType.WatchOut) {
    border_color = "#48484a";
    // border_color = "#d1d1d6";
    // border_color = "#ff9500";
    state_color = border_color;
  } else {
    border_color = "#48484a";
    // border_color = "#d1d1d6";
    // border_color = "#ff453a";
    state_color = border_color;
  }
  border_color = "#005d1d";
  state_color = border_color;

  let disableClick = false;
  if (Number(anbuData.generateState) === progressState.length - 3) {
    disableClick = true;
  }

  return (
    <Card
      active={!anbuData.isFolded}
      key={anbuData.key}
      borderColor={"#d1d1d6"}
    >
      <CardStateRow>
        <CardStateInner>
          <CardStateLeft>
            <State
              state={anbuData.irosIssueState === true}
              color={state_color}
              onClick={() => {
                anbuData.irosIssueState ? openIrosCopyUrl(anbuData.key) : "";
              }}
            >
              {iros_issue_state}
              <div
                style={{
                  display: anbuData.irosIssueState === true ? "None" : "flex",
                  marginLeft: "10px",
                }}
              >
                <img src={ringLoadingGif} width="15px" />
              </div>
              {/* <div
                style={{
                  display: anbuData.irosIssueState === true ? "flex" : "None",
                  marginLeft: "10px",
                }}
              >
                <img src={clickImg} width="15px" />
              </div> */}
            </State>
            {/* <State state={anbuData.bldInfoIssueState === true}>
              {bld_info_issue_state}
              <div
                style={{
                  display:
                    anbuData.bldInfoIssueState === true ? "None" : "flex",
                  marginLeft: "10px",
                }}
              >
                <img src={ringLoadingGif} width="15px" />
              </div>
            </State> */}
          </CardStateLeft>
          <CardStateRight>
            <CardRightSideButton
              style={{ width: "38px" }}
              onClick={() => refreshAllCards()}
            >
              <CardRightSideImg src={refreshImg} />
            </CardRightSideButton>
            <CardRightSideButton
              style={{ width: "40px" }}
              onClick={() => exportCard(anbuData.key)}
            >
              <CardRightSideImg style={{ opacity: "0.6" }} src={exportImg} />
            </CardRightSideButton>
          </CardStateRight>
        </CardStateInner>
      </CardStateRow>
      <CardPreviewRow>
        <CardPreviewInner>
          <CardPreviewLeft>
            <PreviewAddr>
              <PreviewSpan>
                [{anbuData.houseType}] {anbuData.irosUniqueNumber}
              </PreviewSpan>
              {anbuData.fullAddr}{" "}
            </PreviewAddr>
          </CardPreviewLeft>
          <CardPreviewRight>
            <OpenBtn onClick={() => toggleFold(anbuData.key)}>
              <OpenImg src={arrowDownImg} folded={!anbuData.isFolded} />
            </OpenBtn>
          </CardPreviewRight>
        </CardPreviewInner>
      </CardPreviewRow>
      <CardContentRow active={!anbuData.isFolded}>
        <CardContentInner>
          <CardContentLeft>
            <ContentItem>
              <ContentTittle>보증금</ContentTittle>
              <Content>
                <InputContent>
                  <DepositInput
                    type="text"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={anbuData.deposit.toLocaleString()}
                    onChange={({ target: { value } }) =>
                      updateDeposit(anbuData.key, value)
                    }
                  ></DepositInput>
                  원
                </InputContent>
              </Content>
            </ContentItem>
            <ContentItem inactive={anbuData.isDasaedae === true ? true : false}>
              <ContentTittle>
                선순위 <br />
                임차보증금 합계
              </ContentTittle>
              <Content>
                <InputContent>
                  <DepositInput
                    type="text"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={anbuData.priorTenantDept.toLocaleString()}
                    onChange={({ target: { value } }) =>
                      updatePriorDeposit(anbuData.key, value)
                    }
                  ></DepositInput>
                  원
                </InputContent>
              </Content>
            </ContentItem>
            <DetailOuterCtnr
              inactive={anbuData.isDasaedae === true ? true : false}
            >
              <DetailComment>
                <CommentTittle onClick={() => toggleCommentFold(anbuData.key)}>
                  {" "}
                  자세히 보기
                </CommentTittle>
                <DetailCommentArrow
                  onClick={() => toggleCommentFold(anbuData.key)}
                  active={anbuData.isCommentFolded}
                />
              </DetailComment>
              <div
                style={{
                  display: anbuData.isCommentFolded ? "block" : "none",
                  marginTop: "10px",
                  wordBreak: "break-all",
                }}
              >
                * 고객님의 전입날짜 보다 먼저 전입신고한 세입자들의 보증금 총
                합계 금액을 의미합니다. 공인중개사 혹은 집주인에게 해당 정보를
                요청하여 입력하세요. (본 정보 미비로 인한 임차인 피해 발생시
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#005d1d",
                    textDecoration: "none",
                    whiteSpace: "pre-wrap",
                    textDecorationLine: "none",
                    width: "100%",
                  }}
                  href="https://www.law.go.kr/LSW//precInfoP.do?precSeq=160247#AJAX"
                >
                  {" "}
                  공인중개사의 업무 및 부동산 거래신고에 관한 법률 제30조에 의한
                  손해배상의 책임
                </a>
                이 중개인에게 발생 할 수 있습니다.)
                <br /> <br />* 계약서 작성 후 관할 동사무소에 신분증과 계약서
                지참 후 방문하여 '임대차 정보제공 요청서'를 발급 받아 기존
                전달받은 정보와 일치하는지 한번 더 확인하시기 바랍니다.{" "}
              </div>
            </DetailOuterCtnr>
            <ContentItem>
              <ContentTittle width={"65%"}>공인중계사와 계약</ContentTittle>
              <RadioBtnContainer>
                <Item>
                  <RadioButton
                    type="radio"
                    name={"agentRadio" + anbuData.key}
                    value={"true"}
                    // defaultChecked={anbuData.isAgentContract === true}
                    checked={anbuData.isAgentContract === true}
                    onChange={() => setAgent(anbuData.key, true)}
                  />
                  <RadioButtonLabel />
                  <div>예</div>
                </Item>
                <Item>
                  <RadioButton
                    type="radio"
                    name={"agentRadio" + anbuData.key}
                    value={"false"}
                    // defaultChecked={anbuData.isAgentContract === false}
                    checked={anbuData.isAgentContract === false}
                    onChange={() => setAgent(anbuData.key, false)}
                  />
                  <RadioButtonLabel />
                  <div>아니오</div>
                </Item>
              </RadioBtnContainer>
              {/* <RadioBtnContainer>
                <RadioBtn
                  type="radio"
                  name={"agentRadio" + anbuData.key}
                  value={"true"}
                  defaultChecked={anbuData.isAgentContract === true}
                  active={anbuData.isAgentContract === true}
                  onChange={() => setAgent(anbuData.key, true)}
                />
                <div>예</div>
                <RadioBtn
                  type="radio"
                  name={"agentRadio" + anbuData.key}
                  value={"false"}
                  defaultChecked={anbuData.isAgentContract === false}
                  active={anbuData.isAgentContract === false}
                  onChange={() => setAgent(anbuData.key, false)}
                />
                <div>아니오</div>
              </RadioBtnContainer> */}
            </ContentItem>
            <ContentItem>
              <ContentTittle width={"65%"}>계약 기간 1년 이상</ContentTittle>
              <RadioBtnContainer>
                <Item>
                  <RadioButton
                    type="radio"
                    name={"year" + anbuData.key}
                    value={"yes"}
                    checked={anbuData.isLongerThan1Year === true}
                    onChange={() => set1Year(anbuData.key, true)}
                  />
                  <RadioButtonLabel />
                  <div>예</div>
                </Item>
                <Item>
                  <RadioButton
                    type="radio"
                    name={"year" + anbuData.key}
                    checked={anbuData.isLongerThan1Year === false}
                    value={"false"}
                    onChange={() => set1Year(anbuData.key, false)}
                  />
                  <RadioButtonLabel />
                  <div>아니오</div>
                </Item>
              </RadioBtnContainer>
            </ContentItem>
            <ContentItem>
              <ContentTittle width={"65%"}>전세대출 실행여부</ContentTittle>
              <RadioBtnContainer>
                <Item>
                  <RadioButton
                    type="radio"
                    name={"loan" + anbuData.key}
                    value={"true"}
                    checked={anbuData.isLoan === true}
                    onChange={() => setLoan(anbuData.key, true)}
                  />
                  <RadioButtonLabel />
                  <div>예</div>
                </Item>
                <Item>
                  <RadioButton
                    type="radio"
                    name={"loan" + anbuData.key}
                    checked={anbuData.isLoan === false}
                    value={"false"}
                    onChange={() => setLoan(anbuData.key, false)}
                  />
                  <RadioButtonLabel />
                  <div>아니오</div>
                </Item>
              </RadioBtnContainer>
            </ContentItem>
            <ContentItem inactive={addr_type === "오피스텔" ? false : true}>
              <ContentTittle>
                오피스텔 유형
                {/* <div style={{ fontSize: "13px" }}> */}
                {/* <div style={{ fontSize: "0.6vw" }}>
                  *전입신고가 가능한경우 주거용에 체크하시기 바랍니다.{" "}
                </div> */}
              </ContentTittle>
              <RadioBtnContainer>
                <Item>
                  <RadioButton
                    type="radio"
                    name={"officetelType" + anbuData.key}
                    value={"true"}
                    checked={anbuData.isOfficetelForHouse === true}
                    onChange={() => setOfficetel(anbuData.key, true)}
                  />
                  <RadioButtonLabel />
                  <div>주거용</div>
                </Item>
                <Item>
                  <RadioButton
                    type="radio"
                    name={"officetelType" + anbuData.key}
                    value={"false"}
                    checked={anbuData.isOfficetelForHouse === false}
                    onChange={() => setOfficetel(anbuData.key, false)}
                  />
                  <RadioButtonLabel />
                  <div>업무용</div>
                </Item>
              </RadioBtnContainer>
            </ContentItem>
            <ContentComments inactive={addr_type === "오피스텔" ? false : true}>
              <OfficetelComment width="100%">
                *전입신고가 가능한 경우 주거용, 그렇지 않은 경우는 업무용에
                체크하시기 바랍니다.{" "}
              </OfficetelComment>
            </ContentComments>
          </CardContentLeft>
          <CardContentRight>
            <ContentItem>
              <ContentTittle>공시가격</ContentTittle>
              <AnbuContent>
                {anbuData.housePrice.toLocaleString()} 원{" "}
              </AnbuContent>
            </ContentItem>
            <ContentItem>
              <ContentTittle>예상 낙찰율 (%)</ContentTittle>
              <Content>
                <InputContent>
                  <DepositInput
                    type="text"
                    inputMode="numeric"
                    value={((Number(anbuData.auctionRatio))*100).toLocaleString()}
                    onChange={({ target: { value } }) =>
                      updateAuctionRatio(anbuData.key, value)
                    }
                  ></DepositInput>
                  %
                </InputContent>
              </Content>
              {/* <AnbuContent>{anbuData.auctionRatio}</AnbuContent> */}
            </ContentItem>
            <ContentItem>
              <ContentTittle>채권 최고액</ContentTittle>
              <Content>
                <InputContent>
                  <DepositInput
                    type="text"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={anbuData.irosPriorDept.toLocaleString()}
                    onChange={({ target: { value } }) =>
                      updatePriorDebt(anbuData.key, value)
                    }
                  ></DepositInput>
                  원
                </InputContent>
              </Content>
              {/* <AnbuContent>
                {anbuData.irosPriorDept.toLocaleString()} 원
              </AnbuContent> */}
            </ContentItem>
            <DetailOuterCtnr inactive={false}>
              <DetailComment>
                <CommentTittle onClick={() => toggleNoticeFold(anbuData.key)}>
                  안내 사항
                </CommentTittle>
                <DetailCommentArrow
                  onClick={() => toggleNoticeFold(anbuData.key)}
                  active={anbuData.isNoticeFolded}
                />
              </DetailComment>
              <div
                style={{
                  display: anbuData.isNoticeFolded ? "block" : "none",
                  marginTop: "10px",
                  wordBreak: "break-all",
                }}
              >
                * 공시가격, 예상 낙찰율, 채권 최고액은 시스템에 의해 자동으로
                입력되는 값입니다. <br /> <br />* 등본 발급이 완료 되면 안내
                문자가 전송되며 새로고침 버튼을 누른 후 보증금을 입력하고 하단의
                결과리포트 생성하기를 눌러보세요. <br /><br />
                * 계약시 보증금을 통해 근저당을 변경하는 경우 (e.g., 근저당 말소 조건)에는 채권 최고액을 변경하여 리포트를 생성할 수 있습니다. <br /> <br />
                * 예상 낙찰율에는 해당 부동산 유형의 관할 법원 최근 낙찰가율이 자동 반영 됩니다. 경매 전문성을 갖춘 중개사가 아니라면 '예상 낙찰율'을 조정하지 마세요. 
                {/* <br /> <br />* 공시가격의 경우
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#005d1d",
                    textDecoration: "none",
                    whiteSpace: "pre-wrap",
                    textDecorationLine: "none",
                    width: "100%",
                  }}
                  href="https://www.khug.or.kr/hug/web/ig/dr/igdr000001.jsp"
                >
                  {" "}
                  주택도시 보증공사
                </a>
                의 주택가격 산정 방식으로 조회한 값이며 6개월이내 감정평가액이
                별도로 있는 경우 전세보증보험 가입시 해당 가격이 우선 적용되오니
                참고부탁드립니다. */}
              </div>
            </DetailOuterCtnr>
          </CardContentRight>
        </CardContentInner>
      </CardContentRow>
      <CardConfirmRow active={!anbuData.isFolded}>
        <CardConfirmInner>
          <CardConfirmLeft>
            <CardComment>
              - 결과 리포트 생성을 위한 정보 입력란입니다. 위험도 평가를 위한
              정보를 입력하시기 바랍니다.
            </CardComment>
            <CardComment>
              - 결과 리포트는 입력정보를 수정하여 재생성 할 수 있습니다.{" "}
            </CardComment>
            {/* <CardComment>
              - 리포트 생성 후 새 창이 실행되지 않을 경우{" "}
              <a
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#005d1d",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  whiteSpace: "pre-wrap",
                  width: "100%",
                }}
                href={currentAnbuUrl}
              >
                여기
              </a>
              를 클릭해주세요.
            </CardComment> */}
            <CardComment>- 조회내역 고유번호: {anbuData.key}</CardComment>
          </CardConfirmLeft>
          <CardConfirmRight
            active={anbuData.resultPartState !== ResultPartType.None}
          >
            {anbuData.irosIssueState && anbuData.bldInfoIssueState ? (
              <GenerateButton
                onClick={() => {
                  disableClick ? null : genReport(anbuData.key);
                }}
                borderColor={border_color}
                clickable={!disableClick}
              >
                {Number(anbuData.generateState) === progressState.length - 3 ? (
                  <img
                    src={ringLoadingGif}
                    width="15px"
                    style={{ marginRight: "3px" }}
                  />
                ) : null}
                {progressState[Number(anbuData.generateState)]}
              </GenerateButton>
            ) : (
              <GenerateButtonDisabled
                ratioLeft={
                  ((Number(anbuData.generateState) / 35) * 100).toString() + "%"
                }
              >
                <img
                  src={ringLoadingGif}
                  width="15px"
                  style={{ marginRight: "3px" }}
                />
                {progressState[Number(anbuData.generateState)]}
              </GenerateButtonDisabled>
            )}
          </CardConfirmRight>
          <CardConfirmRight
            active={anbuData.resultPartState === ResultPartType.None}
          >
            {anbuData.irosIssueState && anbuData.bldInfoIssueState ? (
              <InitialGenerateButton
                onClick={() => (disableClick ? null : genReport(anbuData.key))}
                clickable={!disableClick}
              >
                {Number(anbuData.generateState) === progressState.length - 3 ? (
                  <img
                    src={ringLoadingGif}
                    width="15px"
                    style={{ marginRight: "3px" }}
                  />
                ) : null}
                {progressState[Number(anbuData.generateState)]}
              </InitialGenerateButton>
            ) : (
              <GenerateButtonDisabled
                ratioLeft={
                  ((Number(anbuData.generateState) / 35) * 100).toString() + "%"
                }
              >
                <img
                  src={ringLoadingGif}
                  width="15px"
                  style={{ marginRight: "3px" }}
                />
                {progressState[Number(anbuData.generateState)]}
              </GenerateButtonDisabled>
            )}
          </CardConfirmRight>
        </CardConfirmInner>
      </CardConfirmRow>
      <CardResultRow
        active={anbuData.resultPartState === ResultPartType.Loading}
      >
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <img
            src={ringLoadingGif}
            width="15px"
            style={{ marginRight: "3px" }}
          />
          결과 리포트를 생성하고 있습니다...
        </div>
      </CardResultRow>
      <CardResultRow
        active={
          anbuData.resultPartState === ResultPartType.Done &&
          anbuData.conclusionType === ConclusionType.Safe
        }
      >
        <CardResultInner borderColor={border_color}>
          <CardResultLeft>
            <ResultLogo src={safeImg}></ResultLogo>
            등본 및 공개된 정보를 통해 분석한 결과 문제가 발견되지 않았습니다.
          </CardResultLeft>
          <CardResultRight>
            {anbuData.irosIssueState && anbuData.bldInfoIssueState ? (
              <ReportButton
                onClick={() => openAnbuUrl(anbuData.key)}
                borderColor={border_color}
              >
                결과리포트 확인하기
              </ReportButton>
            ) : (
              <ReportButtonDisabled>생성 대기</ReportButtonDisabled>
            )}
          </CardResultRight>
        </CardResultInner>
        <CardResultInner borderColor={border_color}>
          <ExplainCtnr>
            <ExplainComment>
              <ExplainTittle onClick={() => toggleGuideFold(anbuData.key)}>
                {" "}
                분석결과 맞춤 가이드
              </ExplainTittle>
              <ExplainArrow
                active={anbuData.isGuideFolded}
                onClick={() => toggleGuideFold(anbuData.key)}
              />
            </ExplainComment>
            <div
              style={{
                display: anbuData.isGuideFolded ? "block" : "none",
                wordBreak: "break-all",
                fontSize: "15px",
                lineHeight: "1.2",
              }}
            >
              <br />- 등본에 나타나 있지 않은 임대인 미납 국세의 경우 전세사기
              방지를 위한 미납 국세 열람 개선 방안의 도입으로 임차인이 임대인
              동의 없이 직접 열람 가능합니다. <br />
              <br />
              임대차 계약서 및 신분증을 지참하여 가까운 세무서(민원봉사실)를
              방문하여 미납국세열람을 신청하시거나, 임대인에게 국세 완납
              증명서를 요청해보세요. <br />
              <br />- 리포트 내 분석 결과에서 시세 대비 여유금액을 확인하신 후
              여유 금액이 크지 않은 경우
              <a
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#005d1d",
                  textDecoration: "none",
                  whiteSpace: "pre-wrap",
                  textDecorationLine: "none",
                  width: "100%",
                }}
                href="https://www.khug.or.kr/hug/web/ig/dr/igdr000001.jsp"
              >
                {" "}
                전세보증보험{" "}
              </a>
              가입을 고려해보세요. <br />
              <br />- 보증보험의 가입조건에 하자 사유가 발견된 경우 해당 부분을
              해소 할 수 있는지 중개인 또는 임대인에게 문의해보시기 바랍니다.{" "}
              <br />
              <div style={{ padding: "10px", lineHeight: "2.6" }}>
                1){" "}
                <span style={{ textDecoration: "underline" }}>
                  시세 대비 근저당 비율이 높아 보증보험 가입조건중 채권기준 1을
                  불만족하는 경우
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  {" "}
                  임대인에게 근저당 채권 최고액 조정 가능 여부를 문의해보세요.
                  계약 전이라면 잔금일날 보증금을 통해 근저당의 비율을 시세 대비
                  60%이하로 조정하거나 말소하는 것을 협의해볼 수 있습니다.
                </div>
                2){" "}
                <span style={{ textDecoration: "underline" }}>
                  시세 대비 보증금의 비율이 높아 보증보험 가입조건중 채권기준
                  3을 불만족 하는 경우
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  보증금을 낮추고 해당 금액을 월세로 전환하는것을 고려해볼 수
                  있습니다. <br />
                </div>
                3){" "}
                <span style={{ textDecoration: "underline" }}>
                  등기부등본 갑구에 압류, 가처분 등이 발견된 경우{" "}
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  관련 진행상황을 임대인 또는 중개사에게 문의하신 후 해당 부분이
                  해소될 수 있는 시점을 확인해보세요. <br />
                </div>{" "}
                {/* 4){" "}
                <span style={{ textDecoration: "underline" }}>
                  공시된 주택 가격이 실제 거래 시세와 차이가 나는 경우
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  {" "}
                  감정 평가를 신청하여 최신 시세 반영 후 보증보험 가입을
                  신청해보세요.
                </div> */}
              </div>
            </div>
          </ExplainCtnr>
        </CardResultInner>
      </CardResultRow>
      <CardResultRow
        active={
          anbuData.resultPartState === ResultPartType.Done &&
          anbuData.conclusionType === ConclusionType.SafeEquip
        }
      >
        <CardResultInner borderColor={border_color}>
          <CardResultLeft>
            <ResultLogo src={safeEquipImg}></ResultLogo>
            별도의 비용으로 전세권 설정시 안전합니다.
          </CardResultLeft>
          <CardResultRight>
            {anbuData.irosIssueState && anbuData.bldInfoIssueState ? (
              <ReportButton
                onClick={() => openAnbuUrl(anbuData.key)}
                borderColor={border_color}
                // borderColor={"#00982f"}
              >
                결과리포트 확인하기
              </ReportButton>
            ) : (
              <ReportButtonDisabled>생성 대기</ReportButtonDisabled>
            )}
          </CardResultRight>
        </CardResultInner>
        <CardResultInner borderColor={border_color}>
          <ExplainCtnr>
            <ExplainComment>
              <ExplainTittle onClick={() => toggleGuideFold(anbuData.key)}>
                {" "}
                분석결과 맞춤 가이드
              </ExplainTittle>
              <ExplainArrow
                active={anbuData.isGuideFolded}
                onClick={() => toggleGuideFold(anbuData.key)}
              />
            </ExplainComment>
            <div
              style={{
                display: anbuData.isGuideFolded ? "block" : "none",
                wordBreak: "break-all",
                fontSize: "15px",
                lineHeight: "1.2",
              }}
            >
              <br />- 전입신고가 불가한 오피스텔의 경우 별도의 비용으로 전세권을
              설정하셔야 합니다. <br />
              <br />- 업무용 오피스텔의 경우
              <a
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#005d1d",
                  textDecoration: "none",
                  whiteSpace: "pre-wrap",
                  textDecorationLine: "none",
                  width: "100%",
                }}
                href="https://www.khug.or.kr/hug/web/ig/dr/igdr000001.jsp"
              >
                {" "}
                전세보증보험{" "}
              </a>
              가입이 불가하므로 주의하셔야 하며, 리포트 내 분석 결과에서 시세 및
              보증금 대비 여유 금액을 확인하시기 바랍니다. <br />
              <br />- 등본에 나타나 있지 않은 임대인 미납 국세의 경우 전세사기
              방지를 위한 미납 국세 열람 개선 방안의 도입으로 임차인이 임대인
              동의 없이 직접 열람 가능합니다. <br />
              <br />
              임대차 계약서 및 신분증을 지참하여 가까운 세무서(민원봉사실)를
              방문하여 미납국세열람을 신청하시거나, 임대인에게 국세 완납
              증명서를 요청해보세요. <br />
            </div>
          </ExplainCtnr>
        </CardResultInner>
      </CardResultRow>
      <CardResultRow
        active={
          anbuData.resultPartState === ResultPartType.Done &&
          anbuData.conclusionType === ConclusionType.WatchOut
        }
      >
        <CardResultInner borderColor={border_color}>
          <CardResultLeft>
            <ResultLogo src={watchOutImg}></ResultLogo>
            분석 결과 주의사항이 발견되었습니다.
          </CardResultLeft>
          <CardResultRight>
            {anbuData.irosIssueState && anbuData.bldInfoIssueState ? (
              <ReportButton
                onClick={() => openAnbuUrl(anbuData.key)}
                borderColor={border_color}
                // borderColor={"#00982f"}
              >
                결과리포트 확인하기
              </ReportButton>
            ) : (
              <ReportButtonDisabled>생성 대기</ReportButtonDisabled>
            )}
          </CardResultRight>
        </CardResultInner>
        <CardResultInner borderColor={border_color}>
          <ExplainCtnr>
            <ExplainComment>
              <ExplainTittle onClick={() => toggleGuideFold(anbuData.key)}>
                {" "}
                분석결과 맞춤 가이드
              </ExplainTittle>
              <ExplainArrow
                active={anbuData.isGuideFolded}
                onClick={() => toggleGuideFold(anbuData.key)}
              />
            </ExplainComment>
            <div
              style={{
                display: anbuData.isGuideFolded ? "block" : "none",
                wordBreak: "break-all",
                fontSize: "15px",
                lineHeight: "1.2",
              }}
            >
              <br /> - 결과 리포트를 통해 분석 결과가 '주의'로 분류된 원인을
              확인하시기 바랍니다. <br />
              <br />- 등본에 나타나 있지 않은 임대인 미납 국세의 경우 전세사기
              방지를 위한 미납 국세 열람 개선 방안의 도입으로 임차인이 임대인
              동의 없이 직접 열람 가능합니다. <br />
              <br />
              임대차 계약서 및 신분증을 지참하여 가까운 세무서(민원봉사실)를
              방문하여 미납국세열람을 신청하시거나, 임대인에게 국세 완납
              증명서를 요청해보세요. <br />
              <br />- 안전을 위해 임대차 계약 기간 중 1/2 경과 이전인 경우
              리포트 내의
              <a
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#005d1d",
                  textDecoration: "none",
                  whiteSpace: "pre-wrap",
                  textDecorationLine: "none",
                  width: "100%",
                }}
                href="https://www.khug.or.kr/hug/web/ig/dr/igdr000001.jsp"
              >
                {" "}
                전세보증보험{" "}
              </a>
              가입 조건을 확인하신 후 모든 조건 만족 시 가입을 추천해 드립니다.{" "}
              <br />
              <br />- 보증보험의 가입조건에 하자 사유가 발견된 경우 해당 부분을
              해소 할 수 있는지 중개인 또는 임대인에게 문의해보시기 바랍니다.{" "}
              <br />
              <div style={{ padding: "10px", lineHeight: "2.6" }}>
                1){" "}
                <span style={{ textDecoration: "underline" }}>
                  시세 대비 근저당 비율이 높아 보증보험 가입조건중 채권기준 1을
                  불만족하는 경우
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  {" "}
                  임대인에게 근저당 채권 최고액 조정 가능 여부를 문의해보세요.
                  계약 전이라면 잔금일날 보증금을 통해 근저당의 비율을 시세 대비
                  60%이하로 조정하거나 말소하는 것을 협의해볼 수 있습니다.
                </div>
                2){" "}
                <span style={{ textDecoration: "underline" }}>
                  시세 대비 보증금의 비율이 높아 보증보험 가입조건중 채권기준
                  3을 불만족 하는 경우
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  보증금을 낮추고 해당 금액을 월세로 전환하는것을 고려해볼 수
                  있습니다. <br />
                </div>
                3){" "}
                <span style={{ textDecoration: "underline" }}>
                  등기부등본 갑구에 압류, 가처분 등이 발견된 경우{" "}
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  관련 진행상황을 임대인 또는 중개사에게 문의하신 후 해당 부분이
                  해소될 수 있는 시점을 확인해보세요. <br />
                </div>{" "}
                {/* 4){" "}
                <span style={{ textDecoration: "underline" }}>
                  공시된 주택 가격이 실제 거래 시세와 차이가 나는 경우
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  {" "}
                  감정 평가를 신청하여 최신 시세 반영 후 보증보험 가입을
                  신청해보세요.
                </div> */}
              </div>
            </div>
          </ExplainCtnr>
        </CardResultInner>
      </CardResultRow>
      <CardResultRow
        active={
          anbuData.resultPartState === ResultPartType.Done &&
          anbuData.conclusionType === ConclusionType.Danger
        }
      >
        <CardResultInner borderColor={border_color}>
          <CardResultLeft>
            <ResultLogo src={dangerImg}></ResultLogo>
            경매가 진행될 경우 보증금 전액이 위험할 수 있습니다.
          </CardResultLeft>
          <CardResultRight>
            {anbuData.irosIssueState && anbuData.bldInfoIssueState ? (
              <ReportButton
                onClick={() => openAnbuUrl(anbuData.key)}
                borderColor={border_color}
              >
                결과리포트 확인하기
              </ReportButton>
            ) : (
              <ReportButtonDisabled>생성 대기</ReportButtonDisabled>
            )}
          </CardResultRight>
        </CardResultInner>
        <CardResultInner borderColor={border_color}>
          <ExplainCtnr>
            <ExplainComment>
              <ExplainTittle onClick={() => toggleGuideFold(anbuData.key)}>
                {" "}
                분석결과 맞춤 가이드
              </ExplainTittle>
              <ExplainArrow
                active={anbuData.isGuideFolded}
                onClick={() => toggleGuideFold(anbuData.key)}
              />
            </ExplainComment>
            <div
              style={{
                display: anbuData.isGuideFolded ? "block" : "none",
                wordBreak: "break-all",
                fontSize: "15px",
                lineHeight: "1.2",
              }}
            >
              <br /> - 리포트 원본을 통해 분석 결과가 '위험'으로 분류된 원인을
              확인하시기 바랍니다. 계약 전인 경우 중개인 또는 임대인과 해당
              사항에 대해 논의 후 계약을 진행하시는 것을 권장합니다. <br />
              <br />- 등본에 나타나 있지 않은 임대인 미납 국세의 경우 전세사기
              방지를 위한 미납 국세 열람 개선 방안의 도입으로 임차인이 임대인
              동의 없이 직접 열람 가능합니다. <br />
              <br />
              임대차 계약서 및 신분증을 지참하여 가까운 세무서(민원봉사실)를
              방문하여 미납국세열람을 신청하시거나, 임대인에게 국세 완납
              증명서를 요청해보세요. <br />
              <br />- 계약을 체결한 이후라면 안전을 위해 임대차 계약 기간 중 1/2
              경과 이전인 경우 리포트 내의
              <a
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#005d1d",
                  textDecoration: "none",
                  whiteSpace: "pre-wrap",
                  textDecorationLine: "none",
                  width: "100%",
                }}
                href="https://www.khug.or.kr/hug/web/ig/dr/igdr000001.jsp"
              >
                {" "}
                전세보증보험{" "}
              </a>
              가입 조건을 확인하신 후 가입을 고려해보세요. <br />
              <br />- 보증보험의 가입조건에 하자 사유가 발견된 경우 해당 부분을
              해소 할 수 있는지 중개인 또는 임대인에게 문의해보시기 바랍니다.{" "}
              <br />
              <div style={{ padding: "10px", lineHeight: "2.6" }}>
                1){" "}
                <span style={{ textDecoration: "underline" }}>
                  시세 대비 근저당 비율이 높아 보증보험 가입조건중 채권기준 1을
                  불만족하는 경우
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  {" "}
                  임대인에게 근저당 채권 최고액 조정 가능 여부를 문의해보세요.
                  계약 전이라면 잔금일날 보증금을 통해 근저당의 비율을 시세 대비
                  60%이하로 조정하거나 말소하는 것을 협의해볼 수 있습니다.
                </div>
                2){" "}
                <span style={{ textDecoration: "underline" }}>
                  시세 대비 보증금의 비율이 높아 보증보험 가입조건중 채권기준
                  3을 불만족 하는 경우
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  보증금을 낮추고 해당 금액을 월세로 전환하는것을 고려해볼 수
                  있습니다. <br />
                </div>
                3){" "}
                <span style={{ textDecoration: "underline" }}>
                  등기부등본 갑구에 압류, 가처분 등이 발견된 경우{" "}
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  관련 진행상황을 임대인 또는 중개사에게 문의하신 후 해당 부분이
                  해소될 수 있는 시점을 확인해보세요. <br />
                </div>{" "}
                {/* 4){" "}
                <span style={{ textDecoration: "underline" }}>
                  공시된 주택 가격이 실제 거래 시세와 차이가 나는 경우
                </span>{" "}
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "0px",
                    paddingBottom: " 0px",
                  }}
                >
                  {" "}
                  감정 평가를 신청하여 최신 시세 반영 후 보증보험 가입을
                  신청해보세요.
                </div> */}
              </div>
            </div>
          </ExplainCtnr>
        </CardResultInner>
      </CardResultRow>
    </Card>
  );
}

function createCardList(
  anbuData: ShowingData[],
  exportCard: (idx: number) => void,
  refreshAllCards: () => void,
  updateDeposit: (idx: number, deposit: string) => void,
  updatePriorDeposit: (idx: number, deposit: string) => void,
  updateAuctionRatio: (idx: number, deposit: string) => void,
  updatePriorDebt: (idx: number, deposit: string) => void,
  toggleFold: (idx: number) => void,
  toggleCommentFold: (idx: number) => void,
  toggleNoticeFold: (idx: number) => void,
  toggleGuideFold: (idx: number) => void,
  setAgent: (id: number, yes: boolean) => void,
  setOver1Year: (id: number, yes: boolean) => void,
  setLoan: (id: number, yes: boolean) => void,
  setOfficetel: (id: number, yes: boolean) => void,
  genReport: (id: number) => void,
  openIrosCopyUrl: (idx: number) => void,
  openBldCopyUrl: (idx: number) => void,
  openAnbuUrl: (idx: number) => void,
  currentAnbuUrl: string
) {
  let AnbuCards: Array<JSX.Element> = [];

  // anbuData.sort((a: ShowingData, b: ShowingData): number => {
  //   return b.key - a.key;
  // });

  anbuData.forEach((value, index, arr) => {
    AnbuCards.push(
      createSingleCard(
        value,
        exportCard,
        refreshAllCards,
        updateDeposit,
        updatePriorDeposit,
        updateAuctionRatio,
        updatePriorDebt,
        toggleFold,
        toggleCommentFold,
        toggleNoticeFold,
        toggleGuideFold,
        setAgent,
        setOver1Year,
        setLoan,
        setOfficetel,
        genReport,
        openIrosCopyUrl,
        openBldCopyUrl,
        openAnbuUrl,
        currentAnbuUrl
      )
    );
  });
  return <div>{AnbuCards}</div>;
}

const OpenHistoryList = () => {
  const {
    totalCount,
    setCurrentPage,
    currentPage,
    openHistoryStatus,
    exportCard,
    refreshAllCards,
    updateDepositById,
    updatePriorDepositById,
    updateAuctionRatioById,
    updatePriorDebtById,
    toggleFoldStateById,
    toggleCommentFoldStateById,
    toggleNoticeFoldStateById,
    toggleGuideStateById,
    showingData,
    setAgentContract,
    setOver1Year,
    setLoan,
    setOfficetelType,
    generateReport,
    openIrosCopyUrl,
    openBldCopyUrl,
    openAnbuyUrl,
    currentAnbuUrl,
  } = useOpenHistory();

  const pageSize = 10;
  const siblingCount = 5;

  const paginationRange = usePagination(
    totalCount,
    pageSize,
    siblingCount,
    currentPage
  );

  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };
  let lastPage: number | string = 0;
  if (paginationRange) {
    lastPage = paginationRange[paginationRange.length - 1];
  }

  if (
    openHistoryStatus === OpenHistoryStatus.HistoryMoreThanOne &&
    showingData
  ) {
    return createCardList(
      showingData,
      exportCard,
      refreshAllCards,
      updateDepositById,
      updatePriorDepositById,
      updateAuctionRatioById,
      updatePriorDebtById,
      toggleFoldStateById,
      toggleCommentFoldStateById,
      toggleNoticeFoldStateById,
      toggleGuideStateById,
      setAgentContract,
      setOver1Year,
      setLoan,
      setOfficetelType,
      generateReport,
      openIrosCopyUrl,
      openBldCopyUrl,
      openAnbuyUrl,
      currentAnbuUrl
    );
  }
  return <p>안부 조회 목록이 존재하지 않습니다.</p>;
};

export default OpenHistoryList;
