import { Component, ComponentType, useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { UserLoginStatusType } from 'src/data/type/status';
import { useSelector } from 'src/hooks/useSelector';


interface AuthRouteProp {
    path: string
    exact?: boolean
    restricted: boolean
    component: ComponentType
}

export const PublicRoute = (props : AuthRouteProp) => {
    const {path, exact, restricted, component} = props;
    // const history = useHistory();
    const loginStatus = useSelector((state) => {
        return state.userReducer.get_login_status()
    });

    useEffect(
        ()=>{
            console.log(`public route : login_status ${loginStatus}, ${restricted} path: ${path}`)
        },[loginStatus]
    )
    
    if (loginStatus === UserLoginStatusType.LOGGEDIN && restricted) return <Redirect to="/search"></Redirect>;
    else return <Route exact={exact} path={path} component={component} />
}