/**
 * UI 관련 Component 정의 
 * 대분류: BUTTON, PAGE, POPUP, 등
 * 소분류: 상세 id (로그인에 사용되는 버튼 등.)
 * 구성 {대분류_소분류}
 */
export enum UiComponentType {
    MODAL_LOGIN = "modal_login",
    MODAL_AGENT_SEARCH = "modal_agent_search",
    MODAL_PAYMENT = "modal_payment",
    MODAL_PAY = "modal_pay",
    MODAL_PRICE_LOOKUP = "modal_price_lookup",
    MODAL_FLOOR_SELECT = "modal_floor_select",
    MODAL_AGENT_3_MONTH = "modal_agent_3month",
    MODAL_AGENT_12_MONTH = "modal_agent_12month",
    BUTTON = "button",
    PAGE = "page"
}