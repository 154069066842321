import styled from "styled-components";

export const Background = styled.div`
  position: relative;

  padding: 0;
  margin: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  top: 4px;

  display: flex;
  font-family: NotoSansKR;
  justify-content: center;
  box-sizing: border-box;
  background-color: #eee;
  @media (max-width: 500px) {
    top: 2px;
  }

  @media (max-height: 800px) {
    height: 900px;
  }
`;

export const Inner = styled.div`
  display: block;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 50px;
  width: 502px;
  height: 200px;
  border-radius: 14px;
  align-items: center;

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;

  border-radius: 14px;
  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;

  @media (max-width: 700px) {
    margin-top: 50px;
    width: 80%;
    height: 300px;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  font-family: NotoSansKR;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.84px;
  text-align: center;
  color: #48484a;
`;

export const LineDiv = styled.div`
  height: 2px;
  padding: 10px;
  border-bottom: solid 2px #eee;
`;

export const StepWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepDiv = styled.div`
  padding: 20px;
`;

export const ChildForm = styled.div`
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export interface prop {
  active?: boolean;
}

export const ProgressItem = styled.div<prop>`
  display: flex;
  flex-direction: column;
  width: 190px;
  align-items: center;
  justify-content: center;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  text-align: center;
  color: #48484a;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const ProgressIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 50%;
  margin-bottom: 10px;
`;

export const CompleteItem = styled.div<prop>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  color: #48484a;
  @media (max-width: 500px) {
    font-size: 14px;
  }
  border-bottom: 2px solid #eee;
`;

export const ContentDiv = styled.div`
  display: flex;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 0.08px;
  width: 100%;
`;

export const CompleteIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 15%;
`;

export interface _prop {
  active?: boolean;
}

export const CompleteBtnContainer = styled.div<_prop>`
  box-sizing: border-box;
  display: ${(props) => (props.active ? "flex" : "none")};
  height: 37px;
  width: 100%;
  border-radius: 5px;
  border: solid 3px #008128;
  background-color: #008128;
  text-align: center;
  align-items: center;
  justify-content: center;
  :hover {
    border: 3px solid rgba(0, 162, 51, 0.8);
    opacity: 0.8;
  }

  margin-top: 0px;

  @media (max-width: 700px) {
    margin-top: 0%;
  }

  @media (max-width: 500px) {
    margin-top: 14px;
  }
`;

export const CompleteBtn = styled.button`
  width: 100%;
  height: 25px;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

export const Statement = styled.h1`
  font-size: 15px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  color: #48484a;
`;

export interface activeProp {
  active?: boolean;
  finished?: boolean;
}

export const Comment = styled.h5<activeProp>`
  display: ${(props) => (props.active ? "" : "none")};
  font-size: 12px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  font-family: NotoSansKR;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.08px;
  text-align: left;
  color: #008128;
`;

export const FailComment = styled.h5<activeProp>`
  display: ${(props) => (props.active ? "" : "none")};
  height: 20px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  font-family: NotoSansKR;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.08px;
  text-align: left;
  color: red;
`;

export const PhoneNumberInputContainer = styled.div<activeProp>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: row;
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 3px;
  border-radius: 5px;
  border: solid 1px #d1d1d6;
  background-color: ${(props) => (props.finished ? "#f4f4f5" : "#fff")};
`;

export const PhoneNumberInput = styled.input`
  display: flex;
  width: 100%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

export const VerifyBtn = styled.button`
  display: flex;
  width: 35%;
  height: 100%;
  font-size: 14px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;

  border-radius: 5px;
  background-color: #008128;

  :hover {
    opacity: 0.9;
  }

  @media (max-width: 700px) {
    font-size: 10px;
  }



`