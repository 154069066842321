import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export interface PaymentPayloadType {
    isError: boolean;
    errorMsg: string | undefined;
    orderId: string | null;
}

interface PaymentReduxState {
    isError: boolean;
    errorMsg: string | undefined;
    orderId: string | null;
}
  
const initialState: PaymentReduxState = {
    isError: false,
    errorMsg: undefined,
    orderId: null
}

const payment = createSlice({
  name: "payment",
  initialState,
  reducers: {
        updatePayment(state: PaymentReduxState, action: PayloadAction<PaymentPayloadType>) {
            state.errorMsg = action.payload.errorMsg;
            state.isError = action.payload.isError;
            state.orderId = action.payload.orderId;
        }
    }
});

export const reducerActions = {...payment.actions};
export default payment;