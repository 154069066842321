import { useDispatch } from "react-redux";
import { Path, _client } from "src/apis/client";
import { UiComponentType } from "src/data/type/ui";
import { useSelector } from "src/hooks/useSelector";
import { uiVisible } from "src/services/ui/ui.actions";
import { useEffect, useState } from "react";
import useCompare from "src/hooks/usePrevious";
import useLoginModal from "../loginModal/LoginModal.hook";
import { UserLoginStatusType } from "src/data/type/status";

export default function usePayModal() {
  const dispatch = useDispatch();
  const [payAmount, setPayAmount] = useState<number>(1);

  const payModalVisible = useSelector((state) => {
    return state.uiReducer.getUiComponent(UiComponentType.MODAL_PAY)
      .visible;
  });
  const payAgent3MonthModalVisible = useSelector((state) => {
    return state.uiReducer.getUiComponent(UiComponentType.MODAL_AGENT_3_MONTH)
      .visible;
  });
  const payAgent12MonthModalVisible = useSelector((state) => {
    return state.uiReducer.getUiComponent(UiComponentType.MODAL_AGENT_12_MONTH)
      .visible;
  });

  const open_pay_modal = () => {
    dispatch(uiVisible({ id: UiComponentType.MODAL_PAY, visible: true }));

  };

  const close_pay_modal = () => {
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_PAY,
        visible: false,
      })
    );
  };

  const open_agent_3month_modal = () => {
    window.scrollTo(0, 0);
    dispatch(uiVisible({ id: UiComponentType.MODAL_AGENT_3_MONTH, visible: true }));
  };

  const close_agent_3month_modal = () => {
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_AGENT_3_MONTH,
        visible: false,
      })
    );
  };

  const open_agent_12month_modal = () => {
    window.scrollTo(0, 0);
    dispatch(uiVisible({ id: UiComponentType.MODAL_AGENT_12_MONTH, visible: true }));
  };

  const close_agent_12month_modal = () => {
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_AGENT_12_MONTH,
        visible: false,
      })
    );
  };


  const confirm_payment = (amount: number) => {
    console.log("comfirm_payment() has beem clicked: " + amount.toString() )
  };

  const { loginStatus, user, point } = useSelector((state) => {
    return {
      loginStatus: state.userReducer.get_login_status(),
      user: state.userReducer.get_user(),
      point: state.userReducer.get_user()?.point,
    };
  });

  return {
    payModalVisible,
    payAgent3MonthModalVisible,
    payAgent12MonthModalVisible,
    open_pay_modal,
    close_pay_modal,
    open_agent_3month_modal,
    open_agent_12month_modal,
    confirm_payment,
    loginStatus,
    point,
    payAmount,
    setPayAmount
  };
}
