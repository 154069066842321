import { useDispatch } from "react-redux";
import { Path, _client } from "src/apis/client";
import { UiComponentType } from "src/data/type/ui";
import {
  PriceLookupStatusType,
  SearchResultStatusType,
} from "src/data/type/search";
import { useSelector } from "src/hooks/useSelector";
import {
  cleanPriceLookup,
  initPriceLookupResult,
  priceLookup,
  setPriceLookupApiStatus,
  setPriceLookupComment,
  setCurrentTab,
} from "src/services/search/search.actions";
import { uiVisible } from "src/services/ui/ui.actions";
import { useEffect, useState } from "react";
import useCompare from "src/hooks/usePrevious";
import useLoginModal from "../loginModal/LoginModal.hook";
import { UserLoginStatusType } from "src/data/type/status";
import { user_me } from "src/services/user/user.actions";

export default function usePriceLookupModal() {
  const dispatch = useDispatch();
  const [houseType, setHouseType] = useState<string>("");
  const [housePrice, setHousePrice] = useState<string>("");
  const [isDasaedae, setIsDasaedae] = useState<string>("");
  const [priceType, setPriceType] = useState<string>("");
  const [privArea, setPrivArea] = useState<string>("");

  const priceLookupModalVisible = useSelector((state) => {
    return state.uiReducer.getUiComponent(UiComponentType.MODAL_PRICE_LOOKUP)
      .visible;
  });

  const priceLookupResult = useSelector((state) =>
    state.searchReducer.get_price_lookup()
  );

  const priceLookupApiStatus = useSelector((state) =>
    state.searchReducer.get_price_lookup_api_status()
  );

  const priceLookupComment = useSelector((state) =>
    state.searchReducer.get_price_lookup_comment()
  );

  useEffect(() => {
    if (priceLookupResult?.possible === true) {
      dispatch(setPriceLookupApiStatus(PriceLookupStatusType.Possible));
      dispatch(setPriceLookupComment(priceLookupResult.result_comment));
      setHouseType(priceLookupResult.house_type);
      // setHousePrice(parseInt(priceLookupResult.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setHousePrice(parseInt(priceLookupResult.price).toLocaleString());
      if (priceLookupResult.is_dasaedae) {
        setIsDasaedae("다세대");
      } else {
        setIsDasaedae("다가구");
      }
      if (priceLookupResult.price_type === "kb") {
        setPriceType("KB 시세");
      } else if (priceLookupResult.price_type === "officetel") {
        setPriceType("국세청 홈텍스 오피스텔 기준시가");
      } else if (priceLookupResult.price_type === "gongdong") {
        setPriceType("공동주택 공시가격");
      } else if (priceLookupResult.price_type === "dagagu") {
        setPriceType("개별단독주택 가격");
      } else {
        setPriceType("알 수 없는 유형:" + priceLookupResult.price_type);
      }
      setPrivArea(priceLookupResult.priv_area);
    } else if (priceLookupResult?.possible === false) {
      dispatch(setPriceLookupApiStatus(PriceLookupStatusType.Impossible));
      dispatch(setPriceLookupComment(priceLookupResult.result_comment));
    } else if (priceLookupResult?.possible === null) {
      dispatch(setPriceLookupApiStatus(PriceLookupStatusType.Impossible));
      dispatch(setPriceLookupComment(priceLookupResult.result_comment));
    }
  }, [priceLookupResult]);

  const open_price_lookup_modal = (
    addr_type: string,
    addr_id: string,
    addr_text: string,
    floor: string
  ) => {
    //priceLookup service 초기화 후 시작
    dispatch(cleanPriceLookup());
    if (addr_type === "집합건물") {
      dispatch(setPriceLookupApiStatus(PriceLookupStatusType.Loading));
      dispatch(
        priceLookup({
          addrtype: addr_type,
          addrtext: addr_text,
          addrid: addr_id,
          is_underground: false,
          floor: "0", //다세대는 고객이 선택한 주소 full string를 통해 백엔드에서 알아서 층 및 용도/면적 다 계산함
        })
      );
      dispatch(
        uiVisible({ id: UiComponentType.MODAL_PRICE_LOOKUP, visible: true })
      );
    } else if (addr_type === "건물") {
      dispatch(setPriceLookupApiStatus(PriceLookupStatusType.Loading));

      //TODO: 다가구는 고객이 선택한 층을 argument로 보냄
      dispatch(
        priceLookup({
          addrtype: addr_type,
          addrtext: addr_text,
          addrid: addr_id,
          is_underground: true, // 이건 걍 무시, 지2층, 2층, 지상2층 등 있을 수 있으며 백엔드에서 판단
          floor: floor,
        })
      );
      dispatch(
        uiVisible({ id: UiComponentType.MODAL_PRICE_LOOKUP, visible: true })
      );
    } else {
      dispatch(setPriceLookupApiStatus(PriceLookupStatusType.Impossible));
      dispatch(
        setPriceLookupComment(
          "건물 및 집합건물 유형에 대해서만 안전성 검사가 가능합니다."
        )
      );
      dispatch(
        uiVisible({ id: UiComponentType.MODAL_PRICE_LOOKUP, visible: true })
      );
    }
  };

  const { loginStatus, user, point } = useSelector((state) => {
    return {
      loginStatus: state.userReducer.get_login_status(),
      user: state.userReducer.get_user(),
      point: state.userReducer.get_user()?.point,
    };
  });
  const { open_login_modal } = useLoginModal();
  const confirm_anbu_issue = () => {
    if (loginStatus === UserLoginStatusType.LOGGEDOUT) {
      try {
        open_login_modal();
      } catch (e) {
        console.error(`로그인 모달 열기 실패, 원인: ${e}`);
      }
    } else {
      try {
        if (priceLookupResult) {
          if (point && point > 0) {
            dispatch(setPriceLookupApiStatus(PriceLookupStatusType.Paying));
            // Send create API
            _client
              .postApiWithToken(Path.confirm_issue_copy_of_register, {
                addr_id: priceLookupResult.iros_address_id,
                full_addr: priceLookupResult.iros_full_address,
                house_type: priceLookupResult.house_type,
                is_dasaedae: priceLookupResult.is_dasaedae,
                priv_area: priceLookupResult.priv_area,
                price_type: priceLookupResult.price_type,
                price: priceLookupResult.price,
              })
              .then(() => {
                // Close Price Lookup Modal
                dispatch(
                  uiVisible({
                    id: UiComponentType.MODAL_PRICE_LOOKUP,
                    visible: false,
                  })
                );
                //Update Point state
                dispatch(user_me());

                //goto History Tab
                dispatch(setCurrentTab(1));
              })
              .catch((err) => {
                console.error(err);
                alert("다음과 같은 이유로 발급에 실패하였습니다: " + err);
              });
          } else {
            dispatch(setPriceLookupApiStatus(PriceLookupStatusType.LackOfCoin));
          }
        }
      } catch (e) {
        let _msg = `${e}`;
        console.error(`Failed to payments ${e}`);
        alert("조회에 실패하였습니다 (" + _msg + ")");
      }
    }
  };

  return {
    priceLookupModalVisible,
    open_price_lookup_modal,
    confirm_anbu_issue,
    priceLookupApiStatus,
    priceLookupComment,
    houseType,
    isDasaedae,
    priceType,
    privArea,
    housePrice
  };
}
