import usePriceLookupModal from "./PayModal.hook";
import { useEffect, useState } from "react";
import { Path, _client } from "src/apis/client";
import {
  loadTossPayments,
  TossPaymentsInstance,
} from "@tosspayments/payment-sdk";
import coinImage from "src/assets/png/coin-big.png";
import { UserLoginStatusType } from "src/data/type/status";
import {
  ModalInnerDiv,
  PayMainDiv,
  CloseBtn,
  TitleRow,
  CashOptionRow,
  BtnRow,
  BtnRowInvert,
  BtnRowGray,
  CashOptionItemRow,
  InfoTittle,
  InfoContent,
  UnitName,
  ProgressWrapper,
  ProgressItem,
  ProgressIcon,
  PayContentRow,
  CurrentCashCntr,
  MyShellCount,
  MyShellImg,
  MyShellInner,
  MyShellSubtittle,
  MyShellTittle,
  CoinImg,
  InfoBtn,
  PaymentBtn,
  PaymentContent,
  PaymentTittle,
  RadioBtn,
  RefundNoticeRow,
  RefundNoticeExplains,
} from "./PayModal.style";
// import Modal from "../common/Modal";

import { frontSetting } from "src/apis/setting";

import React, { CSSProperties, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { UiComponentType } from "src/data/type/ui";
import { uiVisible } from "src/services/ui/ui.actions";
import closeBtnImg from "src/assets/png/close.png";
import { useSelector } from "src/hooks/useSelector";
import usePayModal from "./PayModal.hook";

// const successUrl = `${frontSetting.https ? "https" : "http"}://${
const successUrl = `${
  window.location.origin
  // frontSetting.host
  // }:${frontSetting.port}/payment/success`;
}/payment/success`;
// const failUrl = `${frontSetting.https ? "https" : "http"}://${
const failUrl = `${
  window.location.origin
  // frontSetting.host
}/payment/fail`;
// }:${frontSetting.port}/payment/fail`;

const myPageUrlForVirtualPayment = `${
  window.location.origin
  // frontSetting.host
  // }:${frontSetting.port}/payment/success`;
}/mypage`;

// // const successUrl = `${frontSetting.https ? "https" : "http"}://${frontSetting.host}:${frontSetting.port}/payment/success`;
// const successUrl = `${window.location.origin.toString()}/payment/success`;

// // const failUrl = `${frontSetting.https ? "https" : "http"}://${frontSetting.host}:${frontSetting.port}/payment/fail`
// const failUrl = `${window.location.origin.toString()}/payment/fail`;

// TODO: productId 디비로 변경
const products = [
  {
    productId: "G001",
    orderName: "껍데기 1",
    price: 6600,
  },
  {
    productId: "G002",
    orderName: "껍데기 2", // 10% 할인
    price: 11000,
  },
  {
    productId: "G003",
    orderName: "껍데기 4", // 20% 할인
    price: 19000,
  },
];

interface ModalProps {
  isOpen: boolean;
  modalMainStyle?: CSSProperties;
  closeBtnStyle?: CSSProperties;
  children: ReactNode;
}

const backgroudStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const PayModalPanel = (prop: ModalProps) => {
  const { isOpen, children } = prop;
  const dispatch = useDispatch();
  const closePayModal = () => {
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_PAY,
        visible: false,
      })
    );
  };

  if (isOpen)
    return (
      <>
        <div style={backgroudStyle} onClick={closePayModal} />
        <PayMainDiv>
          <CloseBtn onClick={closePayModal} backgroundImage={closeBtnImg} />
          {children}
        </PayMainDiv>
      </>
    );
  else return null;
};

export const PayModal = () => {
  const [step, setStep] = useState(1);

  const { user } = useSelector((state) => {
    return {
      user: state.userReducer.get_user(),
    };
  });

  const { point, payModalVisible, payAmount, setPayAmount } = usePayModal();
  // const clientKey = "test_ck_4Gv6LjeKD8aR9GWB0Ae3wYxAdXy1";
  const clientKey = process.env.REACT_APP_CLIENT_KEY
    ? process.env.REACT_APP_CLIENT_KEY
    : "";
  const [tossPayments, setTossPayments] =
    useState<Promise<TossPaymentsInstance>>();

  const ClickPayButtonUsingRealTimeAccount = (index: number) => {
    try {
      if (tossPayments && user) {
        let orderId = user.phone + "-"+Math.random().toString(36).substring(2, 15);
        console.log("Try to pay: " + products[index].orderName);
        _client.postApiWithToken(Path.payment_create, {
          productId: products[index].productId,
          orderId: orderId,
          amount: products[index].price,
        });
        tossPayments
          .then((tossPayment) =>
            tossPayment.requestPayment("계좌이체", {
              amount: products[index].price,
              orderId: orderId,
              orderName: products[index].orderName,
              customerName: user.phone,
              successUrl: successUrl,
              failUrl: failUrl,
            })
          )
          .catch((e) => {
            console.error("error : " + e);
            alert("결제를 취소하였습니다.");
          });
      }
    } catch (e) {
      console.error(`Failed to payments ${e}`);
      alert("결제에 실패하였습니다.");
    }
  };

  const ClickPayButton = (index: number) => {
    try {
      if (tossPayments && user) {
        let orderId = user.phone + "-"+Math.random().toString(36).substring(2, 15);
        console.log("Try to pay: " + products[index].orderName);
        _client.postApiWithToken(Path.payment_create, {
          productId: products[index].productId,
          orderId: orderId,
          amount: products[index].price,
        });
        tossPayments
          .then((tossPayment) =>
            tossPayment.requestPayment("카드", {
              amount: products[index].price,
              orderId: orderId,
              orderName: products[index].orderName,
              customerName: user.phone,
              successUrl: successUrl,
              failUrl: failUrl,
            })
          )
          .catch((e) => {
            console.error("error : " + e);
            alert("결제를 취소하였습니다.");
          });
      }
    } catch (e) {
      console.error(`Failed to payments ${e}`);
      alert("결제에 실패하였습니다.");
    }
  };

  const ClickPayButtonUsingVirtualAccount = (index: number) => {
    try {
      if (tossPayments && user) {
        let orderId = user.phone + "-"+Math.random().toString(36).substring(2, 15);
        console.log("Try to pay: " + products[index].orderName);
        _client.postApiWithToken(Path.payment_create, {
          productId: products[index].productId,
          orderId: orderId,
          amount: products[index].price,
        });
        tossPayments
          .then((tossPayment) =>
            tossPayment.requestPayment("가상계좌", {
              amount: products[index].price,
              orderId: orderId,
              orderName: products[index].orderName,
              customerName: user.phone,
              successUrl: myPageUrlForVirtualPayment,
              virtualAccountCallbackUrl: 'https://api.graytortoise.com/payment/virtual-account-callback',
              failUrl: failUrl,
            })
          )
          .catch((e) => {
            console.error("error : " + e);
            alert("결제를 취소하였습니다.");
          });
      }
    } catch (e) {
      console.error(`Failed to payments ${e}`);
      alert("결제에 실패하였습니다.");
    }
  };

  useEffect(() => {
    setTossPayments(loadTossPayments(clientKey));
  }, []);

  if (payModalVisible) {
    return (
      <>
        <PayModalPanel isOpen={payModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              열람권 충전하기 <br />
              (1 껍데기 = 1회 열람)
            </TitleRow>
            {/* <ProgressWrapper
          <ProgressItem active={step == 1}>
            <ProgressIcon src={step === 1 ? firstActive : firstInactive} />
            이용약관 동의
          </ProgressItem>
          <ProgressItem active={step == 2 || step == 5}>
            <ProgressIcon
              src={step === 2 || step === 6 ? secondActive : secondInactive}
            />
            본인 인증하기
          </ProgressItem>
          <ProgressItem active={step == 3 || step == 6}>
            <ProgressIcon
              src={step === 3 || step == 6 ? thirdActive : thirdInactive}
            />
            기본정보 입력
          </ProgressItem>
        </ProgressWrapper> */}
            <PayContentRow>
              <script src="https://js.tosspayments.com/v1"></script>
              <CurrentCashCntr>
                <MyShellTittle>나의 껍데기</MyShellTittle>
                <MyShellImg>
                  <MyShellInner>
                    <CoinImg src={coinImage} />
                  </MyShellInner>
                </MyShellImg>
                <MyShellCount>{point} 개</MyShellCount>
              </CurrentCashCntr>
              <CashOptionRow>
                <CashOptionItemRow>
                  <InfoBtn></InfoBtn>
                  <InfoTittle>충전량</InfoTittle>
                  <InfoContent>금액</InfoContent>
                </CashOptionItemRow>
                <CashOptionItemRow>
                  <PaymentBtn>
                    <RadioBtn
                      type="radio"
                      name={"agentRadio" + payAmount.toString()}
                      value={"true"}
                      defaultChecked={payAmount === 0}
                      active={payAmount === 0}
                      onChange={() => setPayAmount(0)}
                    />
                  </PaymentBtn>
                  <PaymentTittle>껍데기 1개</PaymentTittle>
                  <PaymentContent>6,600원</PaymentContent>
                </CashOptionItemRow>
                <CashOptionItemRow>
                  <PaymentBtn>
                    <RadioBtn
                      type="radio"
                      name={"agentRadio" + payAmount.toString()}
                      value={"true"}
                      defaultChecked={payAmount === 1}
                      active={payAmount === 1}
                      onChange={() => setPayAmount(1)}
                    />
                  </PaymentBtn>
                  <PaymentTittle>껍데기 2개</PaymentTittle>
                  <PaymentContent>11,000원</PaymentContent>
                </CashOptionItemRow>
                <CashOptionItemRow>
                  <PaymentBtn>
                    <RadioBtn
                      type="radio"
                      name={"agentRadio" + payAmount.toString()}
                      value={"true"}
                      defaultChecked={payAmount === 2}
                      active={payAmount === 2}
                      onChange={() => setPayAmount(2)}
                    />
                  </PaymentBtn>
                  <PaymentTittle>껍데기 4개</PaymentTittle>
                  <PaymentContent>19,000원</PaymentContent>
                </CashOptionItemRow>
              </CashOptionRow>
            </PayContentRow>
            <BtnRow onClick={() => ClickPayButton(payAmount)}>카드 결제</BtnRow>
            <BtnRowInvert
              onClick={() => ClickPayButtonUsingVirtualAccount(payAmount)}
            >
              가상 계좌
            </BtnRowInvert>
            <BtnRowGray
              onClick={() => ClickPayButtonUsingRealTimeAccount(payAmount)}
            >
              실시간 계좌이체
            </BtnRowGray>
            <RefundNoticeRow>환불 정책 알림</RefundNoticeRow>
            <RefundNoticeExplains>
              - 충전 후 사용하지 않은 껍데기는 7일까지 100%, 1개월까지 80%,
              3개월까지 50% 금액에 대해 환불 가능하며 그 이후는 환불이
              불가합니다. <br />- 사용하지 않은 껍데기는 1년 후 사용이
              불가합니다. (인터넷 등기소 열람을 위한 민원캐쉬 유효기간에 따른
              정책입니다.)
            </RefundNoticeExplains>
          </ModalInnerDiv>
        </PayModalPanel>
      </>
    );
  } else {
    return null;
  }
};
