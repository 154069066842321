import styled from "styled-components";

export const StyledDiv = styled.div`
  font-family: "NotoSansKR";
  width: 100%;
  box-shadow: 0px 4px 2.8px 0.2px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 20px;
  color: #48484a;
  font-size: 15.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.31px;
  border: 1px outset blue;
`;

export const WrapperContentHeader = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 50px;

  font-family: "NotoSansKR";
  background-color: #ffffff;
  box-sizing: border-box;
  color: #48484a;
  font-size: 15.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.31px;
  box-shadow: 0px 4px 2.8px 0.2px rgba(0, 0, 0, 0.1);

  @media (max-width: 500px) {
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    box-shadow: 0px 2px 1.4px 0.1px rgba(0, 0, 0, 0.1);
    font-weight: 400;
    line-height: 0.8;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  width: 1440px;
  padding-left: 144px;
  padding-right: 144px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (max-width: 1440px) {
    width: 80%;
    padding-right: 0%;
    padding-left: 0%;
  }

  @media (max-width: 500px) {
    width: 90%;
    justify-content: flex-start;
    padding-right: 0px;
    padding-left: 0px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  min-width: 220px;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 43%;
    min-width: 100px;
  }
`;

export const MainLogoImg = styled.img`
  width: 206.7px;
  height: 18.72px;

  @media (max-width: 500px) {
    width: 111px;
    height: 10.2px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 40%;
  min-width: 220px;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 901px) {
    width: 400px;
  }

  @media (max-width: 500px) {
    width: 57%;
    min-width: 100px;
  }
`;

export const LeftHeaderItem = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  display: inline-flex;
  margin-right: 10px;
`;

export const LeftHeaderSubText = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  margin-top: 9px;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.31px;
  text-align: left;
  color: #48484a;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const RightHeaderItem = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  font-weight: 500;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }

  @media (max-width: 700px) {
    font-size: 2.2vw;
    margin-left: 15px;
  }

  @media (max-width: 500px) {
    font-size: 3.5vw;
    margin-left: 10px;
  }
`;

export const PointContainer = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  border-radius: 14.2px;
  border: solid 2px #d1d1d6;
  background-color: #fff;
  margin: 0 0 0 19px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  min-width: 76px;
  align-items: center;

  @media (max-width: 500px) {
    min-width: 15px;
    height: 20px;
    border: solid 1px #d1d1d6;
    padding-left: 2px;
    padding-right: 2px;
    margin: 0 0 0 0;
  }
`;

export const CoinImg = styled.img`
  width: 26px;
  height: 16px;
  margin-right: 12px;
  object-fit: contain;
  @media (max-width: 500px) {
    margin-right: 3px;
    height: 12px;
  }
`;

export const TempDiv = styled.div`
  box-sizing: border-box;
  display: inline-flex;
`;
