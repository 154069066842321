import styled from "styled-components";

interface isMobile {
  mobile?: boolean;
}

export const OuterDiv = styled.div<isMobile>`
  position: relative;
  padding: ${(props) => (props.mobile ? "15px 15px" : "40px 40px")};
  text-align: center;
  font-family: "NotoSansKR";
`;

export const InputText = styled.input`
  width: 90%;
  height: 100%;
  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  outline: none;
  border: 0;
  border-bottom: 3px solid #eeeeee;
  color: ${(props) => props.color};
  &:focus {
    outline: none;
    border-bottom: 3px solid #008128;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TitleDiv = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.52px;
  color: #000;
  text-align: center;
  width: 100px;
  display: inline;
  border-bottom: 3px solid #008128;
`;

export const MobileTitleDiv = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.52px;
  color: #000;
  text-align: center;
  width: 100px;
  display: inline;
  border-bottom: 3px solid #008128;
`;
