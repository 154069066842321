import { configureStore } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import rootReducer from "src/services/index";

export type RootState = ReturnType<typeof rootReducer>;
type StateSelector<T> = (state: RootState) => T;
type EqualityFn<T> = (left: T, right: T) => boolean;

export function useRootState<T>(selector: StateSelector<T>,equalityFn?: EqualityFn<T>) {
    return useSelector(selector, equalityFn);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});



export default store;
