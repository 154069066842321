import styled from "styled-components";

export const AbPaginationContainer = styled.div`
  display: flex;
  list-style-type: none;
`;

export const TableContainer = styled.div`
  text-align: center;
  width: 100%;
`;

export const PaginationBar = styled.div`
  border-top: solid 2px #eee; 
  padding-top: 1%;
  padding-bottom: 1%;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    width: 90vw;
  }
`;

export const AbPaginationItemDots = styled.li`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
  &:hover {
    background-color: transparent;
    cursor: default;
  }
`;


export interface liProp {
  active?: boolean;
}

export const AbPaginationItem = styled.li<liProp>`
  padding: 0 12px;
  height: 40px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 20px;
  min-width: 32px;
  background-color: ${({ active }) => active ? 'rgba(0,0,0,0.04)' : 'none'};

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  &.selected {
    background-color: rgba(0, 0, 0, 0.08);
  }

  @media (max-width: 700px) {
    letter-spacing: 0em;
    height: 25px;
    margin: 0px 0px 0px 0px;
    font-size: 3vw;
    min-width: 1px;
    width: 3.5vw;
  }
`;

export const AbArrowLeft = styled.div`
  position: relative;
  content = '';
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  transform: rotate(-135deg);
`;

  // transform: rotate(-135deg) translate(-50%);
export const AbArrowRight = styled.div`
  position: relative;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  transform: rotate(45deg);
`;

export const AbArrow = styled.div`
    &::before {
      position: relative;
      /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
      content: '';
      /* By using an em scale, the arrows will size with the font */
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border-right: 0.12em solid rgba(0, 0, 0, 0.87);
      border-top: 0.12em solid rgba(0, 0, 0, 0.87);
    }

    &.left {
      transform: rotate(-135deg) translate(-50%);
    }

    &.right {
      transform: rotate(45deg);
    }
  }

  &.disabled {
    pointer-events: none;

    .arrow::before {
      border-right: 0.12em solid rgba(0, 0, 0, 0.43);
      border-top: 0.12em solid rgba(0, 0, 0, 0.43);
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`;
