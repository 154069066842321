import ringLoadingGif from "src/assets/gif/ring-loading.gif";
import { useEffect, useState } from "react";
import { Path, _client } from "src/apis/client";
import {
  ModalInnerDiv,
  PayMainDiv,
  CloseBtn,
  TitleRow,
  BtnRow,
  RefundNoticeRow,
  AgentItemTitle,
  AgentNoticeExplains,
  BtnRowDisabled,
} from "./PayModal.style";
// import Modal from "../common/Modal";

import React, { CSSProperties, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { UiComponentType } from "src/data/type/ui";
import { uiVisible } from "src/services/ui/ui.actions";
import closeBtnImg from "src/assets/png/close.png";
import { useSelector } from "src/hooks/useSelector";
import usePayModal from "./PayModal.hook";
import { AgentDescription, AgentItemDesc, RedItemDesc } from "../myPage/myPageStyle";

interface ModalProps {
  isOpen: boolean;
  modalMainStyle?: CSSProperties;
  closeBtnStyle?: CSSProperties;
  children: ReactNode;
}

const backgroudStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const Agent12MonthModalPanel = (prop: ModalProps) => {
  const { isOpen, children } = prop;
  const dispatch = useDispatch();
  const closePayModal = () => {
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_AGENT_12_MONTH,
        visible: false,
      })
    );
  };

  if (isOpen)
    return (
      <>
        <div style={backgroudStyle} onClick={closePayModal} />
        <PayMainDiv>
          <CloseBtn onClick={closePayModal} backgroundImage={closeBtnImg} />
          {children}
        </PayMainDiv>
      </>
    );
  else return null;
};

export const Agent12MonthModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const closethisModal = () => {
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_AGENT_12_MONTH,
        visible: false,
      })
    );
  };

  const { user } = useSelector((state) => {
    return {
      user: state.userReducer.get_user(),
    };
  });

  const { point, payModalVisible, payAgent3MonthModalVisible, payAgent12MonthModalVisible, payAmount, setPayAmount } =
    usePayModal();

  const ClickSubsButton = (month_length: number) => {
    try {
      if (user) {
        setIsLoading(true);
        _client
          .postApiWithToken(Path.agent_subscribe_req, {
            subscribeMonth: month_length,
            accountName: user.full_name,
          })
          .then((e) => {
            alert("정상 접수 되었습니다. 영업일 기준 1일이 소요되오니 잠시만 기다려주세요.");
          })
          .catch((e) => {
            console.error("error as follows");
            console.log(e);
            alert(e);
          })
          .finally(() => {
            setIsLoading(false);
            closethisModal()
            console.log("");
          });
      }
    } catch (e) {
      console.error(`Failed to payments ${e}`);
      alert("요청에 실패하였습니다. " + e);
    }
  };

  if (payAgent12MonthModalVisible) {
    return (
      <>
        <Agent12MonthModalPanel isOpen={payAgent12MonthModalVisible}>
          <ModalInnerDiv>
            <TitleRow>입금 확인 신청 12개월</TitleRow>
            <AgentItemTitle>상품</AgentItemTitle>
            <AgentItemDesc> 12개월 792,000 원 (부가세 포함)</AgentItemDesc>
            <AgentItemTitle>입금 계좌</AgentItemTitle>
            <RedItemDesc>신한은행 100-034-620645 (예금주: 주식회사 그레이토터스)</RedItemDesc>
            <AgentItemTitle>사업자</AgentItemTitle>
            <RedItemDesc>(주) 그레이토터스 488-88-01449</RedItemDesc>
            <RefundNoticeRow></RefundNoticeRow>
            <AgentNoticeExplains>
              - 3개월 이용권 구매를 희망하시는 고객님께서는 위 계좌에 입금 하신 후 하단의 '입금 확인 요청' 버튼을
              클릭해주세요.
              <br />
              <br />- 세금 계산서 발급이 필요하신 분은 contact@graytortoise.com으로 사업자등록증 사본 (사진, PDF 등)
              또는 사업자번호/성명/업종/업태 정보를 보내주세요.
              <br />
              <br />- 요청 접수 후 영업일 기준 1일 이내 이용권 충전이 완료되오니 업무에 참고 부탁드립니다.
            </AgentNoticeExplains>
            {!isLoading && <BtnRow onClick={() => ClickSubsButton(12)}>입금 확인 요청</BtnRow>}
            {isLoading && (
              <BtnRowDisabled>
                요청 중입니다...
                <img src={ringLoadingGif} width="4%" />
              </BtnRowDisabled>
            )}
          </ModalInnerDiv>
        </Agent12MonthModalPanel>
      </>
    );
  } else {
    return null;
  }
};
