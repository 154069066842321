import { useState } from "react";
import { useHistory } from "react-router-dom";
import { UserLoginStatusType } from "src/data/type/status";
import { useSelector } from "src/hooks/useSelector";
import { userJoin } from "src/services/user/user.actions";
import { GrayForm } from "../common/form/Form";
import { AgreementPage } from "./agreementPage";
import firstActive from "src/assets/png/1-1.png";
import firstInactive from "src/assets/png/1-2.png";
import secondActive from "src/assets/png/2-1.png";
import secondInactive from "src/assets/png/2-2.png";
import thirdActive from "src/assets/png/3-1.png";
import thirdInactive from "src/assets/png/3-2.png";

import {
  ChildForm,
  LineDiv,
  RegisterBackground,
  RegisterInner,
  StepDiv,
  StepWrapperDiv,
  TitleDiv,
  ContentDiv,
  ProgressItem,
  ProgressIcon,
} from "./registerPage.style";
import { UserForm } from "./userForm";
import { AgentUserForm } from "./agentUserForm";
import { VerifyPhonePage } from "./verifyPhonePage";
import { RegisterCompletePage } from "../registerCompletePage/registerCompletionPage";
import { AgentVerifyPhonePage } from "./agentVerifyPhonePage";

export interface RegformProp {
  setStep(step: number): void;
  setFormData(form: any): void;
  formData: userJoin;
}

export const RegisterPage = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<userJoin>({
    pw: "",
    phone: "",
    email: "",
    full_name: "",
    agent_enroll_id: "",
    user_type: "",
  });
  // type: "" // realator, common user (일발 가입자)
  const history = useHistory();
  const login_status = useSelector((state) =>
    state.userReducer.get_login_status()
  );
  if (login_status === UserLoginStatusType.LOGGEDIN) {
    history.push("/");
    return null;
  }

  const goCompletePage = () => {
    history.push("/register-complete");
  };

  const stepSwitcher = () => {
    switch (step) {
      case 1:
        return (
          <AgreementPage
            setStep={setStep}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 2:
        return (
          <VerifyPhonePage
            setStep={setStep}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 3:
        return (
          <UserForm
            setStep={setStep}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 4:
        goCompletePage();
        return null;
      case 5:
        return (
          <AgentVerifyPhonePage
            setStep={setStep}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 6:
        return (
          <AgentUserForm
            setStep={setStep}
            setFormData={setFormData}
            formData={formData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <RegisterBackground>
      <RegisterInner>
        <TitleDiv>회원가입</TitleDiv>
        <ChildForm>
          <ProgressItem active={step == 1}>
            <ProgressIcon src={step === 1 ? firstActive : firstInactive} />
            이용약관 동의
          </ProgressItem>
          <ProgressItem active={step == 2 || step == 5}>
            <ProgressIcon
              src={step === 2 || step === 5 ? secondActive : secondInactive}
            />
            본인 인증하기
          </ProgressItem>
          <ProgressItem active={step == 3 || step == 6}>
            <ProgressIcon
              src={step === 3 || step == 6 ? thirdActive : thirdInactive}
            />
            기본정보 입력
          </ProgressItem>
        </ChildForm>
        <ContentDiv>
          <div>{stepSwitcher()}</div>
        </ContentDiv>
      </RegisterInner>
    </RegisterBackground>
  );
};
