import axios, { AxiosInstance, AxiosResponse } from "axios";
import { TokenData } from "src/data/api-response/token";
import { PhoneAuthType } from "src/data/type/phoneAuth";
import { PhoneAuthParamProp } from "src/services/user/user.actions";
import {serverSetting} from "./setting"

const BASE_URL = `${serverSetting.https ? "https" : "http"}://${serverSetting.host}:${serverSetting.port.toString()}`;


const PathLogin = (type: string) => {
    return `auth/login/${type}`
}

const PathJoin = (type: string) => {
    return `auth/register/mobile`
}

const PathPhoneAuth = (action: PhoneAuthType, params: PhoneAuthParamProp) => {
    if (action === PhoneAuthType.CREATE)
        return `auth/phone_auth/${action}?phone_number=${params.phone}`
    else return `auth/phone_auth/${action}?phone_number=${params.phone}&auth_number=${params.verify_code}`
}

export const Path = {
    login: PathLogin,
    join: PathJoin,
    phone_auth: PathPhoneAuth,
    reset_password: 'auth/reset_password_generated',
    update_password: 'auth/reset_password',
    unregister: 'auth/unregister',
    verify_token: 'auth/token/verify',
    search_agent_list: 'agent/by_agent_boss_concat_string',
    search_floor_list: 'business/floor-check',
    me: 'auth/me',
    payment_create: 'payment',
    payment_approve: 'payment/approve',
    payment_list: 'payment/list',
    agent_subscribe_req: 'agent_subscribe/confirm_request',
    agent_subscribe_info: 'agent_subscribe/subscribe_info',
    search_address_list: 'iros/search-address',
    search_address_list_page: 'iros/search-address-page',
    search_address_status: 'iros/search-status',
    price_lookup: 'business/possibility-check',
    get_anbu_history: 'history/all',
    get_free_anbu_history: 'history/sample',
    generate_anbu_report: 'report/generate-pdf-report',
    generate_free_anbu_report: 'report/generate-pdf-report-free',
    confirm_issue_copy_of_register: 'issue/issue-copy-of-register'
};

export default class Client {
    private _client: AxiosInstance;

    constructor() {
        this._client = axios.create({
            baseURL: BASE_URL,
        });
    }

    public async fetchApi (path?: string): Promise<any> {
        try {
            const config = {
                headers: {'Content-Type': 'application/json', 'Cache-Control' : 'no-cache', 'Authorization': ""}
            };
            const res = await this._client.get(path || "", config);
            return res.data;
        } catch (err) {
            return Promise.reject(err);
        }
    }

    public async fetchApiWithToken (path?: string): Promise<any> {
        try {
            const config = {
                headers: {'Content-Type': 'application/json', 'Cache-Control' : 'no-cache', 'Authorization': ""}
            };
            let token = sessionStorage.getItem('accessToken');
            if (token) config.headers.Authorization = `Bearer ${token}`
            const res = await this._client.get(path || "", config);
            return res.data;
        } catch (err : any) {
            if (err.response === undefined){
                return Promise.reject("네트워크 에러")
            }
            return Promise.reject(err.response.data.error);
        }
    }

    public async postApi(path?: string, data?: any): Promise<any> {
        let res: AxiosResponse<any>;
        try {
            res = await this._client.post(path || "",  data)
            return res.data;
        } catch (error : any) {
            console.error(error)
            if (error.response === undefined){
                return Promise.reject("네트워크 에러")
            }
            console.log(error.response)
            return Promise.reject(error.response.data.error);
        }
    }

    public async postApiV2(path?: string, data?: any): Promise<any> {
        let res: AxiosResponse<any>;
        try {
            res = await this._client.post(path || "",  data)
            return res.data;
        } catch (error : any) {
            console.error(error)
            if (error.response === undefined){
                return Promise.reject("네트워크 에러")
            }
            console.log(error.response)
            return Promise.reject(error.response);
        }
    }

    public async getApi(path?: string, data?: any): Promise<any> {
        let res: AxiosResponse<any>;
        try {
            res = await this._client.get(path || "",  data)
            return res.data;
        } catch (error : any) {
            console.error(error)
            if (error.response === undefined){
                return Promise.reject("네트워크 에러")
            }
            console.log(error.response)
            return Promise.reject(error.response.data.error);
        }
    }

    public async postApiWithToken(path?: string, data?: any): Promise<any> {
        let res: AxiosResponse<any>;
        try {
            const config = {
                headers: {'Content-Type': 'application/json', 'Cache-Control' : 'no-cache', 'Authorization': ""}
            };

            let token = sessionStorage.getItem('accessToken');
            if (token) config.headers.Authorization = `Bearer ${token}`
            res = await this._client.post(path || "",  data, config)
            return res.data;
        } catch (error : any) {
            console.error(error.respose);
            if (error.response === undefined){
                return Promise.reject("네트워크 에러");
            }
            return Promise.reject(error.response.data.error);
        }
    }

    public async putApiWithToken(path?: string, data?: any): Promise<any> {
        let res: AxiosResponse<any>;
        try {
            const config = {
                headers: {'Content-Type': 'application/json', 'Cache-Control' : 'no-cache', 'Authorization': ""}
            };
            let token = sessionStorage.getItem('accessToken');
            if (token) config.headers.Authorization = `Bearer ${token}`
            res = await this._client.put(path || "",  data, config)
            return res.data;
        } catch (error : any) {
            console.error(error.respose);
            if (error.response === undefined){
                return Promise.reject("네트워크 에러");
            }
            return Promise.reject(error.response.data.error);
        }
    }

    public async putApiWithTokenV2(path?: string, data?: any): Promise<any> {
        let res: AxiosResponse<any>;
        try {
            const config = {
                headers: {'Content-Type': 'application/json', 'Cache-Control' : 'no-cache', 'Authorization': ""}
            };
            let token = sessionStorage.getItem('accessToken');
            if (token) config.headers.Authorization = `Bearer ${token}`
            res = await this._client.put(path || "",  data, config)
            return res.data;
        } catch (error : any) {
            console.error(error.respose);
            if (error.response === undefined){
                return Promise.reject("네트워크 에러");
            }
            return Promise.reject(error.response);
        }
    }
}

export const _client = new Client();