import styled from "styled-components";

interface isMobile {
  mobile?: boolean;
}

export const OuterDiv = styled.div<isMobile>`
  position: relative;
  padding: ${(props) => (props.mobile ? "15px 15px" : "40px 40px")};
  text-align: center;
  font-family: "NotoSansKR";
`;

export const InputText = styled.input`
  width: 90%;
  height: 100%;
  font-family: NotoSansKR;

  border: 0;
  justify-content: center;
  display:flex;
  flex-direction: row;
  align-content: center;
  align-items: center;



  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  outline: none;
  color: ${(props) => props.color};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export const TitleDiv = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-top: 10px;
  line-height: 1;
  letter-spacing: -0.52px;
  color: #000;
  text-align: center;
  width: 100px;
  display: inline;
  border-bottom: 3px solid #008128;
`;

export const MobileTitleDiv = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.52px;
  color: #000;
  text-align: center;
  width: 100px;
  display: inline;
  border-bottom: 3px solid #008128;
`;
export const SearchButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  opacity: 1;
  :hover {
    opacity: 0.5;
  }

  :active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transition-duration: 0.4s;
  }
  :after {
    content: "";
    background: #90ee90;
    opacity: 0;
    transition: all 0.8s;
  }
  :active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }

  @media (max-width: 500px) {
    width: 150px;
  }
`;

export const BtnImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 100%;
`;

export const AgentListDiv = styled.div`
  :hover {
    background-color: #f4f4f5;
  }
  cursor: pointer;
`;
