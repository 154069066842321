import { BrowserRouter, Switch } from "react-router-dom";
// import footerLogoIllust from "src/assets/png/footer-logo.png";
import footerLogoIllust from "src/assets/png/logo-combination.png";
import appStoreBadge from "src/assets/png/appstore-badge-w3x.png";
import playStoreBadge from "src/assets/png/googleplay-badge-w3x.png";
import { GrayHeader } from "src/components/common/header/Header";
import { MyPage } from "src/components/myPage/myPage";
import { PrivateRoute } from "src/components/common/PrivateRoute";
import { IntroPage } from "src/components/introPage/IntroPage";
import { PublicRoute } from "../common/PublicRoute";
import { SearchPage } from "../searchPage/searchPage";
import { LoginModal } from "../loginModal/LoginModal";
import { PriceLookupModal } from "../priceLookupModal/PriceLookupModal";
import { PaymentFailPage } from "../paymentPage/PaymentFailPage";
import { PaymentSuccessPage } from "../paymentPage/PaymentSuccessPage";
import { resetPassword } from "../resetPassword/resetPassword";
import { JoinPage } from "../joinPage/joinPage";
import { PageWrapper, Wrapper } from "src/styles/mainPage/mainPage";
import { RegisterPage } from "../registerPage/registerPage";
import { RegisterCompletePage } from "../registerCompletePage/registerCompletionPage";
import { findPassword } from "../findPassword/findPassword";
import { AgentSearchModal } from "../agentSearchModal/AgentSearchModal";
import { PayModal} from "../payModal/PayModal";
import { Agent3MonthModal } from "../payModal/Agent3MonthModal";
import { Agent12MonthModal } from "../payModal/Agent12MonthModal";
import {
  BodyWrapper,
  FirstRowContainer,
  TextContainer,
  BlackBoldText,
  GreenBoldText,
  SubTextContainer,
  MainIllustImg,
  FirstRowItem,
  BtnRowContainer,
  MainPageBtn,
  GrayNoticeP,
  GrayP,
  NoticeContainer,
  MainIllustContainer,
  OtherRowBackground,
  OtherRowContainer,
  OtherContainer,
  BackgroundRowContainer,
  BackgroundContainer,
  OtherImg,
  FooterLogoImg,
  FooterBadgeImg,
  FooterP,
  FooterAppDownDesc,
  FooterContainer,
  FooterBadgeContainer,
  FooterRowContainer,
  FirstRowLeftContainer,
  FirstRowRightContainer,
  BgrImg,
} from "src/components/introPage/IntroPageStyle";
import { termPage } from "../term/term";
import { privacyPage } from "../privacy/privacy";
import { FloorSelectModal } from "../floorSelectModal/FloorSelectModal";
import { unregister } from "../unregister/unregister";

export const MainPage = () => {
  return (
    <>
      <BrowserRouter>
        <Wrapper>
          <PageWrapper>
            <GrayHeader />
            <Switch>
              <PublicRoute
                path="/"
                exact
                component={IntroPage}
                restricted={true}
              />
              {/* <PublicRoute path="/join" exact component={JoinPage} restricted={true} /> */}
              <PublicRoute
                path="/register"
                exact
                component={RegisterPage}
                restricted={true}
              />
              <PublicRoute
                path="/password"
                exact
                component={findPassword}
                restricted={true}
              />
              <PublicRoute
                path="/term"
                exact
                component={termPage}
                restricted={false}
              />
              <PublicRoute
                path="/privacy"
                exact
                component={privacyPage}
                restricted={false}
              />
              <PublicRoute
                path="/register-complete"
                exact
                component={RegisterCompletePage}
                restricted={true}
              />
              <PublicRoute
                path="/search"
                exact
                component={SearchPage}
                restricted={false}
              />
              <PrivateRoute path="/payment/fail" component={PaymentFailPage} />
              <PrivateRoute
                path="/payment/success"
                component={PaymentSuccessPage}
              />
              <PrivateRoute path="/mypage" exact component={MyPage} />
              <PrivateRoute
                path="/reset_password"
                exact
                component={resetPassword}
              />
              <PrivateRoute path="/unregister" exact component={unregister} />
            </Switch>
            <OtherRowBackground backgroundColor="#2c2c2e">
              <FooterRowContainer>
                {/* <FooterContainer
                  position={"relative"}
                  left={"0px"}
                  right={"0%"}
                  top={"0px"}
                  bottom={"0%"}
                  margin={"0vw 0vw 0vw 0vw"}
                  padding={"0vw 0vw 0vw 0vw"}
                  width={"35vw"}
                  height={"auto"}
                  alignItems={"center"}
                >
                  <FooterLogoImg src={footerLogoIllust} />
                </FooterContainer> */}
                <FooterContainer
                  position={"relative"}
                  left={"0%"}
                  right={"0%"}
                  top={"0%"}
                  bottom={"0%"}
                  margin={"0vw 0vw 0vw 0vw"}
                  padding={"0"}
                  width={"35vw"}
                  height={"auto"}
                  alignItems={"flex-start"}
                >
                  <FooterP
                    margin={"1%"}
                    fontWeight={"300"}
                    fontSize={"0.8vw"}
                    color="#a6a6a6"
                    fontFamily="NotoSansCJKkr"
                    mobileFontSize={"2.8vw"}
                  >
                    (주) 그레이토터스 <br />
                    공동대표이사: 심재균, 육지환 <br />
                    사업자 등록번호 488-88-01449 <br />
                    특허 번호 (등록): 제 1996797 호 <br />
                    상표 번호: 40-2020-0017118 <br />
                    유선문의: 070-4793-4825 (문자 가능)
                    <br />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#a6a6a6", textDecoration: "none" }}
                      href="https://graytortoise.com/term"
                    >
                      이용 약관
                    </a>{" "}
                    |{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#a6a6a6", textDecoration: "none" }}
                      href="https://graytortoise.com/privacy"
                    >
                      개인정보 처리 방침
                    </a>
                    <br />
                    이메일: contact@graytortoise.com <br />
                    서울특별시 서초구 강남대로 479(반포동) 신논현타워 지하1층
                    GARAGE 222호 <br />
                    Copyrightⓒ 2020 GrayTortoise Inc. All Rights Reserved.
                  </FooterP>
                </FooterContainer>
                <FooterContainer alignItems={"center"} textAlign={"center"}>
                  <FooterAppDownDesc
                    margin={"15px 0px 0px 0px"}
                    fontWeight={"700"}
                    fontSize={"1.2vw"}
                    color="#ffffff"
                    fontFamily="NotoSansCJKkr"
                    mobileFontSize={"4vw"}
                  >
                    모바일 앱 출시
                  </FooterAppDownDesc>
                  <FooterAppDownDesc
                    margin={"10px 0px 0px 0px"}
                    fontWeight={"500"}
                    fontSize={"1.0vw"}
                    color="#ffffff"
                    fontFamily="NotoSansCJKkr"
                    mobileFontSize={"3vw"}
                  >
                    Android 및 iPhone용 앱을 다운로드 하셔서 <br />
                    더욱 다양한 기능을 활용해보세요.
                  </FooterAppDownDesc>
                  <FooterBadgeContainer
                    position={"relative"}
                    left={"0px"}
                    right={"0%"}
                    top={"0px"}
                    bottom={"0%"}
                    margin={"0vw 0vw 0vw 0vw"}
                    padding={"0vw 0vw 0vw 0vw"}
                    height={"auto"}
                    alignItems={"center"}
                  >
                    <a
                      href="https://apps.apple.com/app/apple-store/id1667452861?pt=122303471&ct=web_site&mt=8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FooterBadgeImg src={appStoreBadge} />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.safe_house_please_native"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FooterBadgeImg src={playStoreBadge} />
                    </a>
                  </FooterBadgeContainer>
                </FooterContainer>
              </FooterRowContainer>
            </OtherRowBackground>
          </PageWrapper>
        </Wrapper>
        <LoginModal />
        <AgentSearchModal />
        <FloorSelectModal />
        <PriceLookupModal />
        <PayModal />
        <Agent3MonthModal />
        <Agent12MonthModal />
      </BrowserRouter>
    </>
  );
};
