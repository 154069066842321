import {AnbuData } from "../api-response/anbu";

class AnbuHistory {
  private _anbuData: AnbuData[] = [];
  private _msg: string | null;
  private _error: string | null;
  private _errorCode: number | null;

  constructor(
    msg: string | null,
    error: string | null,
    errorCode: number | null,
    data: AnbuData[]
  ) {
    this._msg = msg;
    this._error = error;
    this._errorCode = errorCode;
    this._anbuData = data;
  }

  /**
   * Getter msg
   * @return {string | null}
   */
  public get msg(): string | null {
    return this._msg;
  }

  /**
   * Getter errorCode
   * @return {string | null}
   */
  public get error(): string | null {
    return this._error;
  }

  /**
   * Getter errorCode
   * @return {number | null}
   */
  public get errorCode(): number | null {
    return this._errorCode;
  }

  /**
   * Getter anbuData
   * @return {AnbuData[]}
   */
  public get anbuData(): AnbuData[] {
    return this._anbuData;
  }

  /**
   * Setter msg
   * @param {string | null} value
   */
  public set msg(value: string | null) {
    this._msg = value;
  }

  /**
   * Setter errorCode
   * @param {string | null} value
   */
  public set error(value: string | null) {
    this._error = value;
  }

  /**
   * Setter errorCode
   * @param {number | null} value
   */
  public set errorCode(value: number | null) {
    this._errorCode = value;
  }

  /**
   * Setter search Result
   * @param {AnbuData[]}
   */
  public set anbuData(value: AnbuData[]) {
    this._anbuData = value;
  }

}

export default AnbuHistory;
