import { Link, useHistory } from "react-router-dom";
import {
  BottomCtnr,
  OuterCtnr,
  PaymentPageBgr,
  MyShellCtnr,
  MyShellTittle,
  MyShellSubtittle,
  MyShellImg,
  MyShellInner,
  CoinImg,
  MyShellCount,
  MyShellBtn,
} from "./PaymentPageStype";

export const PaymentFailPage = () => {
  const history = useHistory();
  const goSearch = () => {
    try {
      history.push("/search");
    } catch (e) {
      console.error(`Failed to go search page ${e}`);
    }
  };
  const goMyPage = () => {
    try {
      history.push("/mypage");
    } catch (e) {
      console.error(`Failed to go search page ${e}`);
    }
  };

  return (
    <PaymentPageBgr>
      <OuterCtnr>
        <BottomCtnr>
          <MyShellCtnr>
            <MyShellTittle>결제에 실패하였습니다.</MyShellTittle>
            <MyShellBtn onClick={goMyPage}>내 정보 페이지로 이동</MyShellBtn>
            <MyShellBtn onClick={goSearch}>주소 검색하기로 이동</MyShellBtn>
          </MyShellCtnr>
        </BottomCtnr>
      </OuterCtnr>
    </PaymentPageBgr>
  );
};
