export interface ServerConfig {
    https: boolean;
    host: string;
    port: number;
}



const https = process.env.REACT_APP_FRONT_HTTPS === "true";
const backendHost = process.env.REACT_APP_BACKEND_HOST ? process.env.REACT_APP_BACKEND_HOST : "8000";
const frontHost = process.env.REACT_APP_FRONT_HOST ? process.env.REACT_APP_FRONT_HOST : "443";
const port = Number(process.env.REACT_APP_FRONT_PORT);
// export const _clientKey = 'live_ck_4vZnjEJeQVxeBKkkNqYrPmOoBN0k'

export const _clientKey = process.env.REACT_APP_CLIENT_KEY? process.env.REACT_APP_CLIENT : "";
// const _clientKey = 'test_ck_4Gv6LjeKD8aR9GWB0Ae3wYxAdXy1'


export const serverSetting: ServerConfig = {
    https: https,
    host: backendHost,
    port: port
}

export const frontSetting: ServerConfig = {
    https: https,
    host: frontHost,
    port: port
}

// export const serverSetting: ServerConfig = {
//     https: false,
//     host: "localhost",
//     port: 8000
// }

// export const frontSetting: ServerConfig = {
//     https: false,
//     host: "localhost",
//     port: 3000
// }

