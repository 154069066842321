import {
  progressState,
  resultProgressLength,
  ShowingData,
} from "src/components/openHistory/openHistory.hooks";
import { SearchInput } from "src/components/searchPage/searchPageStyleTab";
import {
  AddressResponse,
  AddressStatusResponse,
  Candidate,
  PriceLookupResponse,
} from "src/data/api-response/address";
import PriceLookup from "src/data/models/priceLookup";
import SearchResult from "src/data/models/searchAddress";
import SearchStatus from "src/data/models/searchStatus";
import {
  FloorSelectStatusType,
  OpenHistoryStatus,
  PriceLookupStatusType,
  ResultPartType,
  SearchInputStatusType,
  SearchResultStatusType,
} from "src/data/type/search";
import Service from "../service";
import {
  HistoryCardBooleanState,
  HistoryCardConclusionTypeState,
  HistoryCardNumberState,
  HistoryCardResultTypeState,
  HistoryCardStringState,
} from "./search.actions";

export interface DagaguFloorInfo {
  addrid: string;
  addrtext: string;
  addrtype: string;
  floorList: string[];
}

class SearchService extends Service {
  private searchResult?: SearchResult;
  private searchStatus?: SearchStatus;
  private priceLookup?: PriceLookup;
  private priceLookupApiStatus: PriceLookupStatusType =
    PriceLookupStatusType.Loading;
  private floorLookupApiStatus: FloorSelectStatusType =
    FloorSelectStatusType.Loading;

  private priceLookupComment = "";
  private searchInputKeyword = "";
  private searchSido = "서울특별시";
  private currentPage = 1;
  private searchInputStatus: SearchInputStatusType =
    SearchInputStatusType.Disabled;
  private searchResultStatus: SearchResultStatusType =
    SearchResultStatusType.Initial;
  private currentTab = 0;
  private openHistoryStatus: OpenHistoryStatus = OpenHistoryStatus.NotLogged;
  private anbuHistoryShowingData: ShowingData[] = [];
  private dagaguAddrId = "";
  private dagaguAddrText = "";
  private dagaguAddrType = "";
  private dagaguFloorList: string[] = [];

  /**
   * Setter $dagagu_floor_info
   */
  public set_dagagu_floor_info(_info: DagaguFloorInfo | undefined): void {
    if (_info) {
      this.dagaguAddrId = _info.addrid;
      this.dagaguAddrText = _info.addrtext;
      this.dagaguAddrType = _info.addrtype;
      this.dagaguFloorList = _info.floorList;
    }
  }
  public get_dagagu_addrid(): string {
    return this.dagaguAddrId;
  }
  public get_dagagu_addrtext(): string {
    return this.dagaguAddrText;
  }
  public get_dagagu_addrtype(): string {
    return this.dagaguAddrType;
  }
  public get_dagagu_floor_list(): string[] {
    return this.dagaguFloorList;
  }

  /**
   * Getter $generate Report Status String
   * @return {string }
   */
  public get_gen_report_status(id: number): string {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == id);
    return this.anbuHistoryShowingData[idx].generateState;
  }

  /**
   * Setter $Open History Status String
   */
  public set_gen_report_status(id: number, val: string): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == id);
    this.anbuHistoryShowingData[idx].generateState = val;
  }

  /**
   * Getter $Open History Status
   * @return {OpenHistoryStatus }
   */
  public get_open_history_status(): OpenHistoryStatus {
    return this.openHistoryStatus;
  }

  /**
   * Setter $Open History Status
   */
  public set_open_history_status(val: OpenHistoryStatus): void {
    this.openHistoryStatus = val;
  }

  /**
   * Getter $Anbu History Data at History TAB
   * @return {ShowingData[] }
   */
  public get_anbu_history_showing_data(): ShowingData[] {
    return this.anbuHistoryShowingData;
  }

  /**
   * Setter $Anbu History Data at History TAB
   */
  public set_anbu_history_showing_data(allHistory: ShowingData[]): void {
    allHistory.sort((a: ShowingData, b: ShowingData): number => {
      return b.key - a.key;
    });
    this.anbuHistoryShowingData = allHistory;
  }

  /**
   * Toggle Fold State By Id
   */
  public set_toggle_fold_state_by_id(id: number): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == id);
    this.anbuHistoryShowingData[idx].isFolded =
      !this.anbuHistoryShowingData[idx].isFolded;
  }

  /**
   * Toggle Guide State By Id
   */
  public set_toggle_guide_state_by_id(id: number): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == id);
    this.anbuHistoryShowingData[idx].isGuideFolded =
      !this.anbuHistoryShowingData[idx].isGuideFolded;
  }

  /**
   * Toggle Comment Fold State By Id
   */
  public set_comment_toggle_fold_state_by_id(id: number): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == id);
    this.anbuHistoryShowingData[idx].isCommentFolded =
      !this.anbuHistoryShowingData[idx].isCommentFolded;
  }

  /* Set Report Url By Id */
  public set_report_url_by_id(val: HistoryCardStringState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    if (idx >= 0) {
      this.anbuHistoryShowingData[idx].reportUrl = val.value;
    }
  }

  /* Set Result Type By Id */
  public set_result_type_by_id(val: HistoryCardConclusionTypeState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    if (idx >= 0) {
      this.anbuHistoryShowingData[idx].conclusionType = val.value;
    }
  }

  /* Set Result Part State By Id */
  public set_result_part_state_by_id(val: HistoryCardResultTypeState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    if (idx >= 0) {
      this.anbuHistoryShowingData[idx].resultPartState = val.value;
    }
  }

  /**
   * Toggle Notice Fold State By Id
   */
  public set_notice_toggle_fold_state_by_id(id: number): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == id);
    this.anbuHistoryShowingData[idx].isNoticeFolded =
      !this.anbuHistoryShowingData[idx].isNoticeFolded;
  }

  /**
   * SetAgentContract
   */
  public set_agent_contract_by_id(val: HistoryCardBooleanState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    this.anbuHistoryShowingData[idx].isAgentContract = val.value;
  }

  /**
   * SetOver1Year
   */
  public set_over_1_year_by_id(val: HistoryCardBooleanState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    this.anbuHistoryShowingData[idx].isLongerThan1Year = val.value;
  }

  /**
   * SetLoan
   */
  public set_loan_by_id(val: HistoryCardBooleanState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    this.anbuHistoryShowingData[idx].isLoan = val.value;
  }

  /**
   * SetOfficetelType
   */
  public set_officetel_type_by_id(val: HistoryCardBooleanState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    this.anbuHistoryShowingData[idx].isOfficetelForHouse = val.value;
  }

  /**
   * SetAnbuReportGenerateState
   */
  public set_anbu_report_generate_state_by_id(
    val: HistoryCardStringState
  ): void {
    if (this.anbuHistoryShowingData) {
      let idx = this.anbuHistoryShowingData.findIndex(
        (obj) => obj.key == val.id
      );
      if (val.value && idx > -1) {
        this.anbuHistoryShowingData[idx].generateState = val.value;
      }
    }
  }

  /**
   * UpCount Generate State
   */
  public upcount_generate_state_by_id(_id: number): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == _id);
    if (this.anbuHistoryShowingData[idx]?.generateState) {
      let upcountMax = (
        progressState.length -
        resultProgressLength -
        1
      ).toString();
      if (
        Number(this.anbuHistoryShowingData[idx].generateState) >=
        Number(upcountMax)
      ) {
        this.anbuHistoryShowingData[idx].generateState = upcountMax;
      } else {
        this.anbuHistoryShowingData[idx].generateState = (
          Number(this.anbuHistoryShowingData[idx].generateState) + 1
        ).toString();
      }
    }
  }

  /**
   * Set Deposit
   */
  public set_deposit_by_id(val: HistoryCardNumberState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    this.anbuHistoryShowingData[idx].deposit = Number(val.value);
  }

  /**
   * Set prior deposit
   */
  public set_prior_deposit_by_id(val: HistoryCardNumberState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    this.anbuHistoryShowingData[idx].priorTenantDept = Number(val.value);
  }

  /**
   * Set Auction Ratio
   */
  public set_auction_ratio_by_id(val: HistoryCardNumberState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    if (Number(val.value / 100) > 1) {
      this.anbuHistoryShowingData[idx].auctionRatio = Number(1).toString();
    } else {
      this.anbuHistoryShowingData[idx].auctionRatio = Number(
        val.value / 100
      ).toString();
    }
  }

  /**
   * Set prior debt
   */
  public set_prior_debt_by_id(val: HistoryCardNumberState): void {
    let idx = this.anbuHistoryShowingData.findIndex((obj) => obj.key == val.id);
    this.anbuHistoryShowingData[idx].irosPriorDept = Number(val.value);
  }

  /**
   * Getter $currentTab
   * @return {currentTab }
   */
  public get_current_tab(): number {
    return this.currentTab;
  }

  /**
   * Setter $currentTab
   */
  public set_current_tab(tab_num: number): void {
    this.currentTab = tab_num;
  }

  /**
   * Getter $currentPage
   * @return {currentPage }
   */
  public get_current_page(): number {
    return this.currentPage;
  }

  /**
   * Setter $currentPage
   */
  public set_current_page(page_num: number): void {
    this.currentPage = page_num;
  }

  /**
   * Getter $searchInputKeyword
   * @return {searchInputKeyword }
   */
  public get_search_input_keyword(): string {
    return this.searchInputKeyword;
  }

  /**
   * Setter $searchInputkeyword
   */
  public set_search_input_keyword(keyword: string): void {
    this.searchInputKeyword = keyword;
  }

  /**
   * Getter $searchSido
   * @return {searchSido }
   */
  public get_search_sido(): string {
    return this.searchSido;
  }

  /**
   * Setter $searchSido
   */
  public set_search_sido(sido: string): void {
    this.searchSido = sido;
  }

  /**
   * Getter $searchInputStatus
   * @return {SearchInputStatusType }
   */
  public get_search_input_status(): SearchInputStatusType {
    return this.searchInputStatus;
  }

  /**
   * Setter $searchInputStatus
   */
  public set_search_input_status_type(_type: SearchInputStatusType): void {
    this.searchInputStatus = _type;
  }

  /**
   * Getter $searchResultStatus
   * @return {SearchResultStatusType }
   */
  public get_search_result_status(): SearchResultStatusType {
    return this.searchResultStatus;
  }

  /**
   * Setter $searchResultStatus
   */
  public set_search_result_status_type(_type: SearchResultStatusType): void {
    this.searchResultStatus = _type;
  }

  /**
   * Getter $priceLookupApiStatus
   * @return {PriceLookupStatusType }
   */
  public get_price_lookup_api_status(): PriceLookupStatusType {
    return this.priceLookupApiStatus;
  }

  /**
   * Setter $priceLookupApiStatus
   */
  public set_price_lookup_api_status(state: PriceLookupStatusType): void {
    this.priceLookupApiStatus = state;
  }

  /**
   * Getter $floorLookupApiStatus
   * @return {FloorLookupStatusType }
   */
  public get_floor_lookup_api_status(): FloorSelectStatusType {
    return this.floorLookupApiStatus;
  }

  /**
   * Setter $floorLookupApiStatus
   */
  public set_floor_lookup_api_status(state: FloorSelectStatusType): void {
    this.floorLookupApiStatus = state;
  }

  /**
   * Getter $priceLookupComment
   * @return {PriceLookupComment }
   */
  public get_price_lookup_comment(): string {
    return this.priceLookupComment;
  }

  /**
   * Setter $priceLookupComment
   * @return {PriceLookupComment }
   */
  public set_price_lookup_comment(text: string): void {
    this.priceLookupComment = text;
  }

  /**
   * Getter $searchResult
   * @return {SearchResult }
   */
  public get_search_result(): SearchResult | undefined {
    return this.searchResult;
  }

  /**
   * Setter $searchResult
   * @param {AddressResponse } response
   */
  public update_search_result(resp: AddressResponse | undefined): void {
    if (resp)
      this.searchResult = new SearchResult(
        resp.msg,
        resp.data.code,
        resp.data.pages,
        resp.data.data
      );
    else this.searchResult = undefined;
  }

  /**
   * Getter $searchStatus
   * @return {SearchStatus }
   */
  public get_search_status(): SearchStatus | undefined {
    return this.searchStatus;
  }

  /**
   * Setter $searchResult
   * @param {AddressStatusResponse } response
   */
  public update_search_status(resp: AddressStatusResponse | undefined): void {
    if (resp?.data) this.searchStatus = new SearchStatus(resp.data.available);
    else this.searchStatus = undefined;
  }

  /**
   * Getter $searchResult
   * @return {SearchResult }
   */
  public get_price_lookup(): PriceLookup | undefined {
    return this.priceLookup;
  }

  /**
   * Setter $searchResult
   * @param {AddressResponse } response
   */
  public update_price_lookup(resp: PriceLookupResponse | undefined): void {
    // 정상응답 케이스
    if (resp?.error_code === null) {
      if (resp?.data)
        this.priceLookup = new PriceLookup(
          resp.data.user_id,
          resp.data.possibility_id,
          resp.data.iros_full_address,
          resp.data.possible,
          resp.data.result_comment,
          resp.data.house_type,
          resp.data.is_dasaedae,
          resp.data.price_type,
          resp.data.price,
          resp.data.price_update_date,
          resp.data.iros_address_id,
          resp.data.priv_area,
          resp.data.pub_area,
          resp.data.first_candidate,
          resp.data.all_candidates
        );
    } else {
      if (resp?.error_code === 500 && resp?.error) {
        this.priceLookup = new PriceLookup(
          "",
          "",
          "",
          false,
          resp?.error,
          "",
          false,
          "",
          "",
          "",
          "",
          "",
          "",
          undefined,
          undefined
        );
      } else {
        this.priceLookup = new PriceLookup(
          "",
          "",
          "",
          false,
          "알 수 없는 오류가 발생했습니다",
          "",
          false,
          "",
          "",
          "",
          "",
          "",
          "",
          undefined,
          undefined
        );
      }
    }
  }

  public init_price_lookup(): void {
    this.priceLookup = undefined;
  }

  public clean_price_lookup(): void {
    this.priceLookup = undefined;
  }
}

export default SearchService;
