import styled from "styled-components";

export const Statement = styled.h1`
  font-size: 15px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  color: #48484a;
`;

export interface activeProp {
  active?: boolean;
  finished?: boolean;
}

export const Comment = styled.h5<activeProp>`
  display: ${(props) => (props.active ? "" : "none")};
  font-size: 12px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  font-family: NotoSansKR;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.08px;
  text-align: left;
  color: #008128;
`;

export const FailComment = styled.h5<activeProp>`
  display: ${(props) => (props.active ? "" : "none")};
  font-size: 12px;
  padding-left: 5px;
  font-weight: 520;
  line-height: 1.4;
  font-family: NotoSansKR;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.08px;
  text-align: left;
  color: red;
`;

export const GrayText = styled.span`
  color: #757575;
`;


export const PhoneNumberInputContainer = styled.div<activeProp>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: row;
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 3px;
  border-radius: 5px;
  border: solid 1px #d1d1d6;
  background-color: ${(props) => (props.finished ? "#f4f4f5" : "#fff")};
`;

export const AgentInfoContainer = styled.div<activeProp>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: row;
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  border: solid 1px #d1d1d6;
  background-color: ${(props) => (props.finished ? "#f4f4f5" : "#fff")};

  @media (max-width: 500px){
    font-size: 11px;
  }

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;

`;

export const PhoneNumberInput = styled.input`
  display: flex;
  width: 70%;
  padding-left: 1%;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 100%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #aeaeb2;
  }

  @media (max-width: 500px){
    font-size: 11px;
  }

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.54px;
  text-align: left;
  color: #48484a;
`;

export const DisabledBtn = styled.button`
  display: flex;
  z-index: 200;
  width: 20%;
  height: 100%;
  font-size: 14px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;

  border-radius: 5px;
  background-color: #aeaeb2;
`;

export const VerifyBtn = styled.button`
  display: flex;
  z-index: 200;
  width: 20%;
  height: 100%;
  font-size: 14px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;

  border-radius: 5px;
  background-color: #008128;

  :hover {
    opacity: 0.9;
  }
  @media (max-width: 500px){
    font-size: 11px;
    width: 30%;
  }
`;

export const ConfirmBtn = styled.button`
  display: flex;
  z-index: 200;
  width: 20%;
  height: 100%;
  font-size: 14px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;

  border-radius: 5px;
  background-color: #008128;

  :hover {
    opacity: 0.9;
  }

  @media (max-width: 500px){
    font-size: 11px;
    width: 30%;
  }

`;

export interface confirmProp {
  active?: boolean;
}

//   display: ${(props) => (props.active ? "flex" : "none")};
export const ContinueBtnContainer = styled.div<confirmProp>`
  display: ${(props) => (props.active ? "flex" : "flex")};
  flex-direction: column;
  height: 32px;
  border-radius: 5px;
  background-color: #008128;
  margin-top: auto;

  font-family: NotoSansKR;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  :hover {
    background-color: rgba(0, 162, 51, .9);
  }
`;

export const ContinueBtn = styled.button`
  margin-top: auto;
  width: 100%;
  height: 100%;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #008128;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }
`;

export const AgentBtnContainer = styled.div`
  display: flex;
  height: 37px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #008128;

  font-family: NotoSansKR;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  :hover {
    background-color: rgba(0, 162, 51, .9);
  }
`;

export const AgentBtn = styled.button`
  width: 100%;
  height: 100%;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #008128;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }
`;
