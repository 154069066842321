import { createReducer } from "@reduxjs/toolkit";
import axios from "axios";
import { _client } from "src/apis/client";
import { TokenData } from "src/data/api-response/token";
import { UserData } from "src/data/api-response/user";
import { UserLoginStatusType } from "src/data/type/status";
import {
  phone_auth_verify,
  user_join,
  user_login,
  user_logout,
  user_me,
  user_set_login_status,
  user_verify_token,
  set_agent_info,
  set_agent_boss_name,
  set_agent_office_name,
  set_agent_enroll_id,
  set_virtual_bank_account,
  set_virtual_bank_name,
  set_virtual_bank_amount_of_money,
  set_virtual_bank_deadline,
} from "./user.actions";
import UserService from "./user.service";

const UserReducer = (service: UserService) =>
  createReducer(service, (builder) => {
    builder
      .addCase(user_set_login_status, (service, action) => {
        service.set_login_status(action.payload);
      })
      .addCase(user_login.pending, (service, action) => {
        service.set_login_status(UserLoginStatusType.LOADING);
        service.set_login_fail_msg(undefined);
      })
      .addCase(user_login.fulfilled, (service, action) => {
        service.set_login_status(UserLoginStatusType.LOGGEDIN);
        service.set_login_fail_msg(undefined);
        const token = (action.payload.data as TokenData[])[0].access_token;
        sessionStorage.setItem("accessToken", token);
        if (action.meta.arg.saveLogin)
          localStorage.setItem("accessToken", token);
        else localStorage.removeItem("accessToken");
      })
      .addCase(user_login.rejected, (service, action) => {
        service.set_login_status(UserLoginStatusType.LOGGEDOUT);
        service.set_login_fail_msg(action.error.message);
      })
      .addCase(user_me.fulfilled, (service, action) => {
        service.update_user(action.payload.data as UserData);
      })
      .addCase(user_me.rejected, (service, action) => {
        service.update_user(undefined);
      })
      .addCase(user_verify_token.fulfilled, (service, action) => {
        action.meta.arg;
        service.set_login_status(UserLoginStatusType.LOGGEDIN);
      })
      .addCase(user_verify_token.pending, (service, action) => {
        service.set_login_status(UserLoginStatusType.LOADING);
      })
      .addCase(user_verify_token.rejected, (service, action) => {
        service.set_login_status(UserLoginStatusType.LOGGEDOUT);
      })
      .addCase(user_logout, (service) => {
        service.set_login_status(UserLoginStatusType.LOGGEDOUT);
        localStorage.removeItem("accessToken");
        sessionStorage.removeItem("accessToken");
      })
      .addCase(phone_auth_verify.fulfilled, (service) => {
        service.update_phone_verified(true);
      })
      .addCase(phone_auth_verify.rejected, (service) => {
        service.update_phone_verified(false);
      })
      .addCase(user_join.fulfilled, (service, action) => {
        /** 현재는 가입 후에 로그인 모달을 띄우는 쪽으로 한다. */
        service.set_login_status(UserLoginStatusType.LOGGEDOUT);
      })
      .addCase(user_join.rejected, (service) => {
        service.set_login_status(UserLoginStatusType.LOGGEDOUT);
        // localStorage.setItem('accessToken', "");
      })
      .addCase(set_agent_info, (service, action) => {
        service.set_agent_info(action.payload);
      })
      .addCase(set_agent_boss_name, (service, action) => {
        service.set_agent_boss_name(action.payload);
      })
      .addCase(set_agent_office_name, (service, action) => {
        service.set_agent_office_name(action.payload);
      })
      .addCase(set_agent_enroll_id, (service, action) => {
        service.set_agent_enroll_id(action.payload);
      })
      .addCase(set_virtual_bank_account, (service, action) => {
        service.set_virtual_bank_account(action.payload);
      })
      .addCase(set_virtual_bank_name, (service, action) => {
        service.set_virtual_bank_name(action.payload);
      })
      .addCase(set_virtual_bank_amount_of_money, (service, action) => {
        service.set_virtual_bank_amount_of_money(action.payload);
      })
      .addCase(set_virtual_bank_deadline, (service, action) => {
        service.set_virtual_bank_amount_of_deadline(action.payload);
      });
  });

export default UserReducer;
