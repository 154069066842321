import { useDispatch } from "react-redux";
import { UiComponentType } from "src/data/type/ui";
import { useSelector } from "src/hooks/useSelector";
import { uiVisible } from "src/services/ui/ui.actions";

export default function useLoginModal() {
    const dispatch = useDispatch();
    const visible = useSelector((state) => {
            return state.uiReducer.getUiComponent(UiComponentType.MODAL_LOGIN).visible
        }
    );

    const open_login_modal = () => {
        dispatch(uiVisible({'id': UiComponentType.MODAL_LOGIN, 'visible': true}))
    }

    const close_login_modal = () => {
        dispatch(uiVisible({'id': UiComponentType.MODAL_LOGIN, 'visible': false}))
    }


    return {visible, open_login_modal, close_login_modal}
}