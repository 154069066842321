import styled from "styled-components";


// Common
export const Wrapper = styled.div`
    width: 100vw;
    overflow-x: hidden;
    box-sizing: border-box;
    display: block;
`;

export const PageWrapper = styled.div`
    margin-left: 0;
    padding: 0;
    margin: 0 0 0 0;
    position: relative !important;
    box-sizing: border-box;
    display: block;
`;
