import { GenReportResponse } from "src/data/api-response/address";
import { AnbuResponse } from "src/data/api-response/anbu";
import AnbuHistory from "src/data/models/anbu";
import { GenReportStatusType } from "src/data/type/search";
import Service from "../service";

class AnbuService extends Service {
  private anbuHistory?: AnbuHistory;
  private genReportResponse?: GenReportResponse;

  /**
   * Getter $genReportResponse
   * @return {GenReportResponse}
   */
  public get_gen_report_response(): GenReportResponse | undefined {
    return this.genReportResponse;
  }

  /**
   * Clear $genReportResponse
   * @return {GenReportResponse}
   */
  public clear_gen_report_response() {
    console.log("genReportResponse clear")
    this.genReportResponse = undefined;
  }

  /**
   * Setter $genReportResponse
   * @param {GenReportResponse } response
   */
  public set_gen_report_response(resp: GenReportResponse | undefined): void {
    if (resp) {
      this.genReportResponse = resp;
    } else this.genReportResponse = undefined;
  }

  /**
   * Setter $genFreeReportResponse
   * @param {GenReportResponse } response
   */
  public set_gen_free_report_response(resp: GenReportResponse | undefined): void {
    if (resp) {
      this.genReportResponse = resp;
    } else this.genReportResponse = undefined;
  }

  /**
   * Getter $anbuHistory
   * @return {AnbuHistory }
   */
  public get_anbu_history(): AnbuHistory | undefined {
    return this.anbuHistory;
  }

  /**
   * Clear $anbuHistory
   * @return {AnbuHistory }
   */
  public clear_anbu_history() {
    this.anbuHistory = undefined;
  }

  /**
   * Setter $anbuHistory
   * @param {AnbuResponse } response
   */
  public update_anbu_history(resp: AnbuResponse | undefined): void {
    if (resp)
      this.anbuHistory = new AnbuHistory(
        resp.msg,
        resp.error,
        resp.error_code,
        resp.data
      );
    else this.anbuHistory = undefined;
  }

  /**
   * Setter $anbuHistory
   * @param {AnbuResponse } response
   */
  public update_free_anbu_history(resp: AnbuResponse | undefined): void {
    if (resp)
      this.anbuHistory = new AnbuHistory(
        resp.msg,
        resp.error,
        resp.error_code,
        resp.data
      );
    else this.anbuHistory = undefined;
  }

}

export default AnbuService;
