import styled from "styled-components";

export const BtnRow = styled.div`
  margin-top: 4%;
  padding: 2.5% 5% 2.5% 5%;
  font-family: NotoSansKR;
  font-size: 22px;
  font-weight: 600;
  box-sizing: border-box;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 0.9px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: 2px solid #008128;
  background-color: #008128;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    padding: 4% 5% 4% 5%;
    font-size: 18px;
  }
`;

export const BtnRowInvert = styled.div`
  margin-top: 4%;
  padding: 2.5% 5% 2.5% 5%;
  font-family: NotoSansKR;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 0.9px;
  text-align: center;
  color: #008128;
  border: 2px solid #008128;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    padding: 4% 5% 4% 5%;
    font-size: 18px;
  }
`;

export const BtnRowDisabled = styled.div`
  margin-top: 4%;
  padding: 2.5% 5% 2.5% 5%;
  box-sizing: border-box;
  font-family: NotoSansKR;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 0.9px;
  text-align: center;
  color: #595959;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #eeeeee;
  :hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    padding: 4% 5% 4% 5%;
    font-size: 18px;
  }
`;

export const BtnRowGray = styled.div`
  margin-top: 4%;
  padding: 2.5% 5% 2.5% 5%;
  box-sizing: border-box;
  font-family: NotoSansKR;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 0.9px;
  text-align: center;
  color: #595959;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #eeeeee;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    padding: 4% 5% 4% 5%;
    font-size: 18px;
  }
`;

export const ProgressWrapper = styled.div`
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export interface prop {
  active?: boolean;
}

export const ProgressItem = styled.div<prop>`
  display: flex;
  flex-direction: column;
  width: 190px;
  align-items: center;
  justify-content: center;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  text-align: center;
  color: #48484a;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const ProgressIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 50%;
  margin-bottom: 10px;
`;

export const CashOptionRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  height: 100%;
  padding: 3%;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: -0.28px;
  text-align: center;
  color: #48484a;

  @media (max-width: 500px) {
    margin-top: 15px;
    width: 80%;
  }
`;

export const CashOptionItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 3%;
  box-sizing: border-box;
  font-family: NotoSansCJKkr;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  text-align: left;
  color: #747474;
  border-bottom: solid 1px #d1d1d6;
`;

export const InfoBtn = styled.div`
  width: 20%;
  box-sizing: border-box;
  padding: 4%;
  font-family: NotoSansCJKkr;
`;

export const InfoTittle = styled.div`
  width: 40%;
  box-sizing: border-box;
  padding: 4%;
  font-family: NotoSansCJKkr;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 40%;
  box-sizing: border-box;
  padding: 4% 4% 4% 0%;
  font-family: NotoSansCJKkr;
`;

export const PaymentBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  box-sizing: border-box;
  padding: 4%;
  font-family: NotoSansCJKkr;
`;

export const PaymentTittle = styled.div`
  width: 40%;
  box-sizing: border-box;
  padding: 4%;
  color: #2c2c2e;
  font-size: 20px;
  font-family: NotoSansCJKkr;

  @media (max-width: 500px) {
    font-size: 17px;
  }

`;

export const PaymentContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 40%;
  box-sizing: border-box;
  padding: 4% 4% 4% 0%;
  font-family: NotoSansCJKkr;
`;

export const UnitName = styled.span`
  color: #747474;
`;

export const TitleRow = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-family: NotoSansKR;
  font-size: 22px;
  font-weight: 650;
  line-height: 1.43;
  letter-spacing: -0.28px;
  text-align: center;

  border-radius: 5px;
  background-color: rgba(142, 142, 147, 0.16);
  color: #48484a;

  @media (max-width: 500px) {
    height: 55px;
    font-size: 18px;
  }
`;

export const PayContentRow = styled.div`
  display: flex;
  box-sizing: border-box;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 350px;
  width: 100%;
  margin-top: 20px;

  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: -0.28px;
  text-align: center;

  border-radius: 5px;
  color: #48484a;

  padding-left: 5%;
  padding-right: 5%;
  padding-top: 7%;
  padding-bottom: 7%;

  border: solid 1px #d1d1d6;
  background-color: #fff;

  @media (max-width: 500px) {
    flex-direction: column;
    height: 550px;
    margin-top: 10px;
    font-size: 14px;
  }
`;

export const CurrentCashCntr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 35%;
  height: 100%;
  padding: 3%;
  border-radius: 10px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #d1d1d6;
  background-color: #fff;

  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const ModalInnerDiv = styled.div`
  padding: 5% 5% 4% 5%;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: "NotoSansKR";
  box-sizing: border-box;
  @media (max-width: 500px) {
    padding: 3%;
  }
`;

export const PayMainDiv = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 5px;
  width: 800px;
  top: 100px;
  left: 50%;
  margin-left: -400px;
  zindex: 10;

  @media (max-width: 1000px) {
    top: 80px;
    left: 10%;
    width: 80%;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

interface props {
  backgroundImage?: string;
}

export const CloseBtn = styled.button<props>`
  position: absolute;
  display: block;
  top: 0px;
  right: -50px;
  width: 30px;
  height: 30px;
  transition: transform 0.1;
  border: none;
  background-color: transparent;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  cursor: pointer;

  @media (max-width: 500px) {
    top: 120%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
  }
`;

export const MyShellTittle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-weight: 600;
  font-size: 20px;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const MyShellSubtittle = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 20px;
  font-weight: 400;
  font-size: 15px;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const MyShellImg = styled.div`
  display: flex;
  align-text: left;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
  }
`;

export const MyShellInner = styled.div`
  display: flex;
  align-text: left;
  width: 120px;
  height: 135px;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
  }
`;

export const MyShellCount = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;

  font-family: NotoSansKR;
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.31px;
  text-align: center;
  color: #00982f;

  @media (max-width: 500px) {
  }
`;

export const CoinImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  height: 70px;
  width: auto;
  max-height: 100%;
`;

export interface radioProps {
  active?: boolean;
}

export const RadioBtn = styled.input<radioProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  width: 90%;
  height: 90%;
  opacity: 1;
  border: solid 3px green;
  background-color: #aeaeb2;
  &:checked {
    border: solid 2px pink;
    background-color: #00982f;
  }
  :hover {
    opacity: 0.5;
  }
`;

export const RefundNoticeRow = styled.div`
  box-sizing: border-box;
  margin-top: 25px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: -0.28px;
  color: #48484a;

  border-top: solid 1px #757575;

  @media (max-width: 500px) {
    margin-top: 15px;
    padding-top: 15px;
    font-size: 14px;
  }
`;

export const RefundNoticeRow2 = styled.div`
  box-sizing: border-box;
  margin-top: 25px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: -0.28px;
  color: #48484a;

  @media (max-width: 500px) {
    margin-top: 15px;
    padding-top: 15px;
    font-size: 14px;
  }
`;

export const RefundNoticeExplains = styled.div`
  box-sizing: border-box;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: NotoSansKR;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: -0.28px;
  color: #48484a;
  text-align: left;

  @media (max-width: 500px) {
    margin-top: 15px;
    font-size: 12px;
  }
`;

export const AgentItemTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 40px;
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;
export const AgentNoticeExplains = styled.div`
  box-sizing: border-box;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: -0.28px;
  color: #48484a;
  text-align: left;

  @media (max-width: 500px) {
    margin-top: 15px;
    font-size: 12px;
  }
`;
