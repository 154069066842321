import { cloneDeep } from "lodash";
import { combineReducers } from "redux";
import UiComponent from "src/data/models/uiComponent";
import { UiComponentType } from "src/data/type/ui";
import PaymentReducer from "./payment/payment.reducer";
import PaymentService from "./payment/payment.service";
import UiReducer from "./ui/ui.reducer";
import UIService from "./ui/ui.service";
import UserReducer from "./user/user.reducer";
import UserService from "./user/user.service";
import SearchReducer from "./search/search.reducer";
import SearchService from "./search/search.service";
import AnbuService from "./anbu/anbu.service";
import AnbuReducer from "./anbu/anbu.reducer";
import payment from "./payment/payment.reducerV2";

const userService = new UserService();
const userReducer = UserReducer(userService);
const paymentService = new PaymentService();
const paymentReducer = PaymentReducer(paymentService);
const searchService = new SearchService();
const searchReducer = SearchReducer(searchService);
const anbuService = new AnbuService();
const anbuReducer = AnbuReducer(anbuService);

export const _uiComponents = new Array<UiComponent>();
_uiComponents.push(new UiComponent(UiComponentType.MODAL_LOGIN));
_uiComponents.push(new UiComponent(UiComponentType.MODAL_PAYMENT));
_uiComponents.push(new UiComponent(UiComponentType.MODAL_PAY));
_uiComponents.push(new UiComponent(UiComponentType.MODAL_AGENT_3_MONTH));
_uiComponents.push(new UiComponent(UiComponentType.MODAL_AGENT_12_MONTH));
_uiComponents.push(new UiComponent(UiComponentType.MODAL_AGENT_SEARCH));
_uiComponents.push(new UiComponent(UiComponentType.MODAL_PRICE_LOOKUP));
_uiComponents.push(new UiComponent(UiComponentType.MODAL_FLOOR_SELECT));
_uiComponents.push(new UiComponent(UiComponentType.PAGE));

const uiService = new UIService(cloneDeep(_uiComponents));
const uiReducer = UiReducer(uiService);


const rootReducer = combineReducers(
    {
        userReducer: userReducer,
        uiReducer: uiReducer,
        paymentReducer: paymentReducer,
        searchReducer: searchReducer,
        anbuReducer: anbuReducer,
        payment2Reducer: payment.reducer
    }
);

export default rootReducer;