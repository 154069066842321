import useFloorSelectModal from "./FloorSelectModal.hook";
import { useEffect } from "react";
import { useSelector } from "src/hooks/useSelector";
import { Path, _client } from "src/apis/client";
import { Link, useHistory } from "react-router-dom";
import ringLoadingGif from "src/assets/gif/ring-loading.gif";
import {
  ModalInnerDiv,
  FloorSelectMainDiv,
  CloseBtn,
  TitleRow,
  BldInfoRow,
  BtnRow,
  BldInfoItemRow,
  InfoTittle,
  InfoContent,
  UnitName,
  FloorOption,
} from "./FloorSelectModal.style";
// import Modal from "../common/Modal";
import React, { CSSProperties, ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { UiComponentType } from "src/data/type/ui";
import { uiVisible } from "src/services/ui/ui.actions";
import closeBtnImg from "src/assets/png/close.png";
import { setPriceLookupApiStatus } from "src/services/search/search.actions";
import useSearchPage from "../searchPage/searchPage.hooks";
import { DagaguFloorInfo } from "src/services/search/search.service";
import { FloorSelectStatusType } from "src/data/type/search";

interface ModalProps {
  isOpen: boolean;
  modalMainStyle?: CSSProperties;
  closeBtnStyle?: CSSProperties;
  children: ReactNode;
}

const backgroudStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  minWidth: "400px",
  minHeight: "400px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 10,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -50,
  display: "block",
  width: "28px",
  height: "27px",
  transition: "transform 0.1s",
  border: 0,
  backgroundColor: "transparent",
};

const FloorSelectModalPanel = (prop: ModalProps) => {
  const { isOpen, modalMainStyle, closeBtnStyle, children } = prop;
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_FLOOR_SELECT,
        visible: false,
      })
    );
  };

  let mainStyle = defatulMainStyle;
  if (modalMainStyle) mainStyle = modalMainStyle;

  if (isOpen)
    return (
      <>
        <div style={backgroudStyle} onClick={closeModal} />
        <FloorSelectMainDiv>
          <CloseBtn onClick={closeModal} backgroundImage={closeBtnImg} />
          {children}
        </FloorSelectMainDiv>
      </>
    );
  else return null;
};

export const FloorSelectModal = () => {
  const dispatch = useDispatch();
  const { floorSelectModalVisible } = useFloorSelectModal();
  const history = useHistory();

  const closeFloorSelectModal = () => {
    dispatch(
      uiVisible({
        id: UiComponentType.MODAL_FLOOR_SELECT,
        visible: false,
      })
    );
  };
  const { openPriceLookupPopup } = useSearchPage();
  const [targetFloor, setTargetFloor] = useState("");

  const floorApiStatus = useSelector((state) =>
    state.searchReducer.get_floor_lookup_api_status()
  );

  const dagaguAddrId = useSelector((state) =>
    state.searchReducer.get_dagagu_addrid()
  );
  const dagaguAddrText = useSelector((state) =>
    state.searchReducer.get_dagagu_addrtext()
  );
  const dagaguAddrType = useSelector((state) =>
    state.searchReducer.get_dagagu_addrtype()
  );
  const dagaguFloorList = useSelector((state) =>
    state.searchReducer.get_dagagu_floor_list()
  );

  // asign default value
  useEffect(() => {
    if (dagaguFloorList.length > 0) {
      setTargetFloor(dagaguFloorList[0])
    }
  }, [dagaguFloorList]);

  const confirmFloor = (
    type: string,
    addrid: string,
    addrtext: string,
    floor: string
  ) => {
    openPriceLookupPopup(type, addrid, addrtext, floor);
    closeFloorSelectModal();
  };

  if (
    floorSelectModalVisible &&
    dagaguFloorList.length > 0 &&
    floorApiStatus === FloorSelectStatusType.Possible
  ) {
    return (
      <>
        <FloorSelectModalPanel isOpen={floorSelectModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              <span>조회를 원하시는 층을 선택해주세요.</span>
            </TitleRow>
            <BldInfoRow>
              <BldInfoItemRow>{dagaguAddrText}</BldInfoItemRow>
              <BldInfoItemRow>
                <FloorOption
                  value={targetFloor}
                  onChange={(event) => {
                    setTargetFloor(event.target.value);
                  }}
                >
                  {dagaguFloorList.map((element) => {
                    return <option key={element}>{element}</option>;
                  })}
                </FloorOption>
              </BldInfoItemRow>
            </BldInfoRow>
            <BtnRow
              onClick={() =>
                confirmFloor(
                  dagaguAddrType.toString(),
                  dagaguAddrId.toString(),
                  dagaguAddrText.toString(),
                  targetFloor
                )
              }
            >
              확인
            </BtnRow>
          </ModalInnerDiv>
        </FloorSelectModalPanel>
      </>
    );
  } else if (
    floorSelectModalVisible &&
    floorApiStatus === FloorSelectStatusType.Impossible
  ) {
    return (
      <>
        <FloorSelectModalPanel isOpen={floorSelectModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              <span> 건축물 대장부 층 정보 조회에 실패하였습니다.</span>
            </TitleRow>
            <BtnRow onClick={() => closeFloorSelectModal()}>확인</BtnRow>
          </ModalInnerDiv>
        </FloorSelectModalPanel>
      </>
    );
  } else if (
    floorSelectModalVisible &&
    floorApiStatus === FloorSelectStatusType.Possible &&
    dagaguFloorList.length === 0
  ) {
    return (
      <>
        <FloorSelectModalPanel isOpen={floorSelectModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              <span> 건축물 대장부 층 정보 조회에 실패하였습니다.</span>
            </TitleRow>
            <BtnRow onClick={() => closeFloorSelectModal()}>확인</BtnRow>
          </ModalInnerDiv>
        </FloorSelectModalPanel>
      </>
    );
  } else if (
    floorSelectModalVisible &&
    floorApiStatus === FloorSelectStatusType.Loading
  ) {
    return (
      <>
        <FloorSelectModalPanel isOpen={floorSelectModalVisible}>
          <ModalInnerDiv>
            <TitleRow>
              <span> 조회중입니다..</span>
            </TitleRow>
            <img src={ringLoadingGif} width="10%" />
          </ModalInnerDiv>
        </FloorSelectModalPanel>
      </>
    );
  }
  return null;
};
