import styled from "styled-components";

export const RegisterBackground = styled.div`
  position: relative;

  padding: 0;
  margin: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  top: 4px;

  display: flex;
  z-index: 100;

  font-family: NotoSansKR;
  justify-content: center;
  box-sizing: border-box;
  background-color: #eee;
  @media (max-width: 500px) {
    top: 2px;
  }

  @media (max-height: 800px) {
    height: 900px;
  }
`;

export const RegisterInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 702px;
  height: 780px;
  border-radius: 14px;
  box-shadow: 0px 9px 18px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding-bottom: 25px;
  padding-top: 25px;

  @media (max-width: 702px) {
    margin-top: 25px;
    width: 90%;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  font-family: NotoSansKR;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.84px;
  text-align: center;
  color: #48484a;
  border-bottom: solid 2px #eee;
`;

export const LineDiv = styled.div`
  height: 2px;
  padding: 10px;
  border-bottom: solid 2px #eee;
`;

export const StepWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepDiv = styled.div`
  padding: 20px;
`;

export const ChildForm = styled.div`
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export interface prop {
  active?: boolean;
}

export const ProgressItem = styled.div<prop>`
  display: flex;
  flex-direction: column;
  width: 190px;
  align-items: center;
  justify-content: center;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  text-align: center;
  color: #48484a;
  @media (max-width: 500px) {
    font-size: 14px;
  }

`;

export const ProgressIcon = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 50%;
  margin-bottom: 10px;
`;

export const ContentDiv = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.08px;
`;
