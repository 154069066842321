import { GrayTextButton } from "src/styles/common/button";
import { useLocation } from "react-router";
import payment, { PaymentPayloadType } from "src/services/payment/payment.reducerV2";
import { Path, _client } from "src/apis/client";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserLoginStatusType } from "src/data/type/status";
import doc02Image from "src/assets/png/document-02.png";
import coinImage from "src/assets/png/coin-big.png";
import { PaymentModal } from "../paymentModal/PaymentModal";
import { useSelector } from "src/hooks/useSelector";
import usePaymentModal from "../paymentModal/PaymentModal.hook";
import {
  set_virtual_bank_account,
  set_virtual_bank_amount_of_money,
  set_virtual_bank_deadline,
  set_virtual_bank_name,
  user_me,
} from "src/services/user/user.actions";
import {
  MyPageBgr,
  StatisticsCtnr,
  MoreMenuCtnr,
  OuterCtnr,
  TopCtnr,
  UsrInfoCtnr,
  BottomCtnr,
  MyShellCtnr,
  InquiryCtnr,
  MyShellTittle,
  MyShellBtn,
  MyShellCount,
  MyShellImg,
  MyShellContents,
  InquiryEmailInput,
  InquiryBtn,
  InquiryContentInput,
  InquiryTittleInput,
  InquiryTittle,
  UsrInfoRowCtnr,
  StatsInnerCtnr,
  MoreMenuInnerCtnr,
  StatsTittleCtnr,
  StatsContentCtnr,
  StatsIconCtnr,
  StatsNumberCtnr,
  Img,
  MoreMenuTittleCtnr,
  MoreMenuRowCtnr,
  MyShellInner,
  CoinImg,
  InqueryNotice,
  MyShellSubtittle,
  MiddleCtnr,
  AgentOuterCtnr,
  AgentShellCtnr,
  AgentDescription,
  AgentBtn,
} from "./myPageStyle";
import usePayModal from "../payModal/PayModal.hook";
import { useState } from "react";

export const MyPage = () => {
  const location = useLocation();
  const { open_payment_modal, close_payment_modal } = usePaymentModal();
  const { open_pay_modal, close_pay_modal, open_agent_3month_modal, open_agent_12month_modal } = usePayModal();

  const [startAt, setStartAt] = useState<string>("-");
  const [endAt, setEndAt] = useState<string>("-");

  const dispatch = useDispatch();
  const openPaymentModal = () => {
    try {
      open_payment_modal();
    } catch (e) {
      console.error(`Failed to open payment modal ${e}`);
    }
  };

  const getSubscribeInfo = () => {
    if (user) {
      _client.postApiWithToken(Path.agent_subscribe_info, {}).then((e) => {
        console.log("TempLog");
        console.log(e);
        if (e) {
          setStartAt(e.data?.start_at);
          setEndAt(e.data?.end_at);
        }
      });
    }
  };
  const {
    loginStatus,
    user,
    point,
    virtual_bank_account,
    virtual_bank_name,
    virtual_bank_due_date,
    virtual_amount_of_money,
  } = useSelector((state) => {
    return {
      loginStatus: state.userReducer.get_login_status(),
      user: state.userReducer.get_user(),
      point: state.userReducer.get_user()?.point,
      virtual_bank_account: state.userReducer.get_virtual_bank_account(),
      virtual_bank_name: state.userReducer.get_virtual_bank_name(),
      virtual_bank_due_date: state.userReducer.get_virtual_bank_deadline(),
      virtual_amount_of_money: state.userReducer.get_virtual_bank_amount_of_money(),
    };
  });

  useEffect(() => {
    console.log("realtor: call info");
    if (user?.user_type === "realtor") {
      getSubscribeInfo();
    }
  }, [user]);

  const history = useHistory();
  const goto_reset_password = () => {
    history.push("/reset_password");
  };

  const unregister = () => {
    history.push("/unregister");
  };

  const refreshWindow = () => {
    window.location.reload();
  };

  const closePaymentModal = () => {
    try {
      close_payment_modal();
    } catch (e) {
      console.error(`Failed to open payment modal ${e}`);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const orderId = params.get("orderId");
    const paymentKey = params.get("paymentKey");
    const amount = params.get("amount");
    if (orderId && paymentKey && amount) {
      let res: Promise<any> = _client.fetchApiWithToken(
        `/payment/confirm-virtual-account?orderId=${orderId}&paymentKey=${paymentKey}&amount=${amount}`
      );
      res.then((res) => {
        console.log("confirm virtual account response has been arrived");
        console.log(res);
        const paymentPayload: PaymentPayloadType = {
          orderId,
          isError: false,
          errorMsg: undefined,
        };
        dispatch(payment.actions.updatePayment(paymentPayload));
        if (res.data) {
          if (res.data.status == "EXPIRED") {
            alert(res.data.msg);
            dispatch(set_virtual_bank_account(""));
            dispatch(set_virtual_bank_name(""));
            dispatch(set_virtual_bank_deadline(""));
            dispatch(set_virtual_bank_amount_of_money(""));
          } else if (res.data.status == "NOT_FOUND_PAYMENT_SESSION") {
            dispatch(set_virtual_bank_account(""));
            dispatch(set_virtual_bank_name(""));
            dispatch(set_virtual_bank_deadline(""));
            dispatch(set_virtual_bank_amount_of_money(""));
          } else if (res.data.status == "ALREADY_PAID") {
            //이미 callback에 의해 결제가 확인된 경우
            dispatch(set_virtual_bank_account(""));
            dispatch(set_virtual_bank_name(""));
            dispatch(set_virtual_bank_deadline(""));
            dispatch(set_virtual_bank_amount_of_money(""));
            alert("충전이 정상적으로 완료되었습니다.");
          } else if (res.data.status == "DONE") {
            dispatch(set_virtual_bank_account(""));
            dispatch(set_virtual_bank_name(""));
            dispatch(set_virtual_bank_deadline(""));
            dispatch(set_virtual_bank_amount_of_money(""));
            // 최초 입금 완료는 alert 표시
            alert(res.data.msg);
          } else if (res.data.status == "WAITING_FOR_DEPOSIT") {
            // 가상계좌 최초 생성 완료 후 입금 대기중인 상태
            dispatch(set_virtual_bank_account(res.data.account_number));
            dispatch(set_virtual_bank_name(res.data.bank));
            dispatch(set_virtual_bank_deadline(res.data.due_date));
            dispatch(set_virtual_bank_amount_of_money(res.data.total_amount));
            alert(res.data.msg);
          } else if (res.data.status == "ALREADY_PROCESSED_PAYMENT") {
            // 가상계좌 이미 생성 완료 후 입금 대기중인 상태
            dispatch(set_virtual_bank_account(res.data.account_number));
            dispatch(set_virtual_bank_name(res.data.bank));
            dispatch(set_virtual_bank_deadline(res.data.due_date));
            dispatch(set_virtual_bank_amount_of_money(res.data.total_amount));
          } else {
            alert(res.data.msg);
          }
        }
        dispatch(user_me());
      });
      res.catch((err) => {
        console.log(err);
        const paymentPayload: PaymentPayloadType = {
          orderId,
          isError: true,
          errorMsg: err,
        };
        dispatch(payment.actions.updatePayment(paymentPayload));
        dispatch(user_me());
      });
    } else {
      dispatch(set_virtual_bank_account(""));
      dispatch(set_virtual_bank_name(""));
      dispatch(set_virtual_bank_deadline(""));
      dispatch(set_virtual_bank_amount_of_money(""));
    }
  }, [location.search]);

  function generateUsrInfoRow(category: string, info: string | undefined) {
    let InfoDiv: Array<JSX.Element> = [];
    let _info: string;

    if (info != undefined) {
      _info = info;
    } else {
      _info = "";
    }

    InfoDiv.push(
      <div
        key={_info + category}
        style={{
          display: "flex",
          width: "100px",
          color: "#aeaeb2",
        }}
      >
        {category}
      </div>
    );

    InfoDiv.push(
      <div
        key={_info + category + "1"}
        style={{
          display: "inline-flex",
          height: "auto",
          marginLeft: "12px",
          color: "#fff",
          overflowWrap: "anywhere",
        }}
      >
        {_info}
      </div>
    );
    return <UsrInfoRowCtnr key={info + category + "2"}>{InfoDiv}</UsrInfoRowCtnr>;
  }

  function virtualAccountPart() {
    if (virtual_bank_account !== undefined) {
      if (virtual_bank_account.length > 1) {
        return (
          <MiddleCtnr>
            <MyShellTittle>[입금 대기중인 가상 계좌]</MyShellTittle>
            <MyShellContents>
              {virtual_bank_name}은행 {virtual_bank_account}
            </MyShellContents>
            <MyShellTittle>[입금하실 금액]</MyShellTittle>
            <MyShellContents>{virtual_amount_of_money} 원</MyShellContents>
            <MyShellSubtittle>예금주: 주식회사 그레이토터스 </MyShellSubtittle>
            <MyShellSubtittle>
              <br />* 입금 후 하단의 "결제 확인" 버튼을 클릭하시면 충전이 완료 됩니다. (결제 만료일:{" "}
              {virtual_bank_due_date})
            </MyShellSubtittle>
            <MyShellBtn style={{ cursor: "pointer" }} onClick={() => refreshWindow()}>
              결제 확인
            </MyShellBtn>
          </MiddleCtnr>
        );
      }
    }
  }
  // {if (virtual !== UserLoginStatusType.LOGGEDIN)
  //         (<MiddleCtnr>
  //   <MyShellTittle>[입금 대기중인 가상 계좌]</MyShellTittle>
  //   <MyShellContents>
  //     {virtual_bank_name}은행, {virtual_bank_account}
  //   </MyShellContents>
  //   <MyShellTittle>[입금하실 금액]</MyShellTittle>
  //   <MyShellContents>{virtual_amount_of_money} 원</MyShellContents>
  //   <MyShellSubtittle>예금주: 주식회사 그레이토터스 </MyShellSubtittle>
  //   <MyShellSubtittle>
  //     * 입금 후 새로고침을 하시면 충전이 완료 됩니다.
  //   </MyShellSubtittle>
  //   <MyShellSubtittle>
  //     * 결제 만료일: {virtual_bank_due_date}
  //   </MyShellSubtittle>) : NULL

  const allAnbuHistory = useSelector((state) => state.anbuReducer.get_anbu_history());


  if (loginStatus !== UserLoginStatusType.LOGGEDIN) {
    return (
      <MyPageBgr>
        <OuterCtnr>로그인이 필요한 서비스입니다.</OuterCtnr>
      </MyPageBgr>
    );
  } else {
    return (
      <MyPageBgr>
        <OuterCtnr>
          <TopCtnr>
            <UsrInfoCtnr>
              <br />
              {generateUsrInfoRow("이름", user?.full_name)}
              {generateUsrInfoRow("연락처", user?.phone)}
              {/* {generateUsrInfoRow("고객 유형", "개인 (일반)")} */}
              {generateUsrInfoRow("고객 유형", user?.user_type === "realtor" ? "공인중개사" : "개인 (일반)")}
              {generateUsrInfoRow("이메일", user?.email)}
              {user?.user_type === "realtor" && generateUsrInfoRow("정기권 시작일", startAt)}
              {user?.user_type === "realtor" && generateUsrInfoRow("정기권 종료일", endAt)}
              <br />
              {/*고객유형: 공인중개사 (거성파스텔공인중개)*/}
            </UsrInfoCtnr>
            <StatisticsCtnr>
              <StatsInnerCtnr>
                <StatsTittleCtnr>총 리포트 발급 개수</StatsTittleCtnr>
                <StatsContentCtnr>
                  <StatsIconCtnr>
                    <Img src={doc02Image} />
                  </StatsIconCtnr>
                  {/* <StatsNumberCtnr>{showingData? showingData.length: showingData}</StatsNumberCtnr> */}
                  <StatsNumberCtnr>{allAnbuHistory ? allAnbuHistory.anbuData.length : 0}</StatsNumberCtnr>
                </StatsContentCtnr>
              </StatsInnerCtnr>
            </StatisticsCtnr>
            <MoreMenuCtnr>
              <MoreMenuInnerCtnr>
                <MoreMenuTittleCtnr>설정</MoreMenuTittleCtnr>
                <MoreMenuRowCtnr onClick={() => goto_reset_password()}>비밀번호 변경하기 {">"}</MoreMenuRowCtnr>
                {/* <MoreMenuRowCtnr>민원캐시 등록하기 {">"}</MoreMenuRowCtnr> */}
                <MoreMenuRowCtnr onClick={() => unregister()}>탈퇴 신청 {">"}</MoreMenuRowCtnr>
              </MoreMenuInnerCtnr>
            </MoreMenuCtnr>
          </TopCtnr>
          {virtualAccountPart()}
          <BottomCtnr>
            <MyShellCtnr>
              <MyShellTittle>나의 껍데기</MyShellTittle>
              <MyShellSubtittle>(*껍데기란 리포트 발급시 결제에 사용되는 이용권 단위입니다.) </MyShellSubtittle>
              <MyShellImg>
                <MyShellInner>
                  <CoinImg src={coinImage} />
                </MyShellInner>
              </MyShellImg>
              <MyShellCount>{point} 개</MyShellCount>
              <MyShellBtn style={{ cursor: "pointer" }} onClick={() => open_pay_modal()}>
                껍데기 충전하기
              </MyShellBtn>
            </MyShellCtnr>
            <InquiryCtnr>
              <InquiryTittle>고객센터 문의</InquiryTittle>
              <InqueryNotice>
                문의사항은 contact@graytortoise.com 또는 <br /> 유선번호 070-4793-4825로 연락바랍니다. <br />
                (유선 번호가 부재중이거나 통화중인 경우 해당 번호로 문자를 남겨주시면 담당자 확인 후 순차적으로 답변을
                드리고 있사오니 이용에 참고 부탁드립니다.)
              </InqueryNotice>
              {/* <InquiryEmailInput>이메일을 입력하세요</InquiryEmailInput>
            <InquiryTittleInput>제목을 입력하세요</InquiryTittleInput>
            <InquiryContentInput>내용을 입력하세요</InquiryContentInput>
            <InquiryBtn>제출</InquiryBtn> */}
            </InquiryCtnr>
          </BottomCtnr>
          <AgentOuterCtnr>
            <AgentShellCtnr>
              <MyShellTittle>공인중개사 전용 이용권</MyShellTittle>
              <MyShellSubtittle>
                * 공인 중개사분들은 기간 단위 이용권을 활용하여 저렴하게 다량의 리포트와 등기부 등본을 발급 받으실 수
                있습니다.
              </MyShellSubtittle>
              <AgentDescription>
                [중개인 전용 3개월 이용권] <br />- 이용료 330,000 원 (부가세 포함) <br />- 월 50회 사용 가능 <br />-
                3개월간 총 150회 제공 (사용시 등기부등본 열람용 원본이 실시간 발급되어 함께 제공됩니다.) <br />- 개별
                구매가격 대비 66.6% 저렴한 가격으로 발급하실 수 있는 이용권입니다.
              </AgentDescription>
              <AgentBtn style={{ cursor: "pointer" }} onClick={() => open_agent_3month_modal()}>
                3개월 이용권 요청
              </AgentBtn>
              <AgentDescription>
                [중개인 전용 1년 이용권] <br />- 이용료 792,000 원 (부가세 포함) <br />- 월 50회 사용 가능 <br />-
                12개월간 총 600회 제공 (사용시 등기부등본 열람용 원본이 실시간 발급되어 함께 제공됩니다.)
                <br />- 개별 구매가격 대비 80% 저렴한 가격으로 발급하실 수 있는 이용권입니다.
              </AgentDescription>
              <AgentBtn style={{ cursor: "pointer" }} onClick={() => open_agent_12month_modal()}>
                1년 이용권 요청
              </AgentBtn>
            </AgentShellCtnr>
          </AgentOuterCtnr>
        </OuterCtnr>
      </MyPageBgr>
      // <div>
      //     사용자 정보 ~
      //     <PaymentModal/>
      //     <GrayTextButton onClick={openPaymentModal}> 결제창 </GrayTextButton>
      // </div>
    );
  }
};
