import styled, { keyframes } from "styled-components";
import loading01 from "src/assets/png/loading-walk01.png";
import loading02 from "src/assets/png/loading-walk02.png";
import loading03 from "src/assets/png/loading-walk03.png";

export interface tabProps {
  active?: boolean;
}

export const TabItem = styled.div<tabProps>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;

  font-family: NotoSansKR;
  font-size: 1.2vw;
  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.83px;
  cursor: pointer;

  color: ${(props) => (props.active ? "#00982f" : "#a6a6a6")};
  border-bottom: ${(props) => (props.active ? "2px solid #00982f" : "")};

  :hover {
    color: #00982f;
    border-bottom: 2px solid #f00982f;
  }

  @media (max-width: 500px) {
    font-size: 100%;
  }
`;
//  border: solid 1px blue;

export const TabContent = styled.div<tabProps>`
  ${(props) => (props.active ? "display:flex;" : "display:none;")}
  flex-direction: column;
  align-items: center;
  min-height: 500px;
`;

export const SearchContainer = styled.div`
  display: inline-flex;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
`;

export const SearchLeftContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 75%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export interface innerProps {
  height?: string;
}

export const SearchInnerContainer = styled.div<innerProps>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: ${(props) => props.height};
`;
// @media screen and (max-width: 500px) {
//   flex-wrap: wrap;
// }


export interface activeProps {
  active?: boolean;
}

export const SearchHistoryContainer = styled.div<activeProps>`
  display: ${(props) => props.active? "":"none"};
  position: absolute;
  width: 57.8%;
  padding-left: 12.8%;
  box-sizing: border-box;
  text-align: left;
  z-index: 0;
  cursor: default;
  -webkit-user-select: none;
  margin-top: -8px;

  @media (min-width: 2440px) {
    width: 57%;
    padding-left: 12.8%;
  }

  @media screen and (max-width: 500px) {
    width: 93.2%;
    padding-left: 28%;
  }
`;

// border-radius: 0 0 24px 24px;
export const SearchHistoryInner = styled.div`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  border-radius: 5px;
  box-shadow: 0px 3px 9.2px 0.8px rgba(0, 0, 0, 0.14);
  border: solid 3px #fff;
  background-color: #fff;
`;

export const SearchHistoryUl = styled.ul`
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding: 0;
  margin: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 2px;
`;

export const SearchHistoryLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  padding: 0;
  margin: 0;
  &:hover {
    background-color: #E3E3E3;
  }
`;

export const SearchHistoryItemDiv = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchHistoryIcon = styled.div`
  display: flex;
  align-items: center;
  margin: 0 13px 0 1px;
  background: url("src/assets/png/searchHistoryIcon.png") no-repeat 0 0;
  background-size: 20px;
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
`;

export const SearchHistoryImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: 95%;
  min-height: 18px;
  min-width: auto;
  height: 18px;
  width: auto;
  align-items: center;
  margin: 0 13px 0 2px;
  opacity: 0.5;
`;

export const SearchHistoryText = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  padding: 6px 0;
  font-family: NotoSansKR;
  fontSize: 1vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #757575;
  @media (max-width: 500px) {
    font-size: 3vw;
  }
`;

export interface inputProps {
  width?: string;
  mobileWidth?: string;
  active?: boolean;
}

export const SearchOptionContainer = styled.div<inputProps>`
  display: flex;
  height: 60%;
  flex-direction: row;
  justify-content: left;
  align-items: stretch;
  box-sizing: border-box;
  width: ${(props) => props.width};
  @media screen and (max-width: 500px) {
    width: ${(props) => props.mobileWidth};
    height: 60%;
  }
`;

export const SearchAddressContainer = styled.div<inputProps>`
  display: flex;
  height: 60%;
  flex-direction: row;
  justify-content: left;
  align-items: stretch;
  align-content: center;
  box-sizing: border-box;
  border-radius: 5px;
  padding-right: 5px;

  width: ${(props) => props.width};
  background-color: ${(props) => (props.active ? "#fff" : "#E2E2E2")};

  @media screen and (max-width: 500px) {
    width: ${(props) => props.mobileWidth};
    height: 60%;
  }
`;

interface inputProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

export const SearchRightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; 
  box-sizing: border-box;
  height: 100%;
  width: 25%;
  @media screen and (max-width: 500px) {
    display: none;
  }
}
`;

export const ImportImage = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
`;

interface Pprop {
  hide?: boolean;
  fontWeight: string;
  fontFamily: string;
  fontSize: string;
  color: string;
  margin: string;
  mobileFontSize?: string;
  maxFontSize?: string;
}

export const SearchP = styled.p<Pprop>`
  display: inline;
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};

  @media (min-width: 2000px) {
    font-size: ${({ maxFontSize }) => maxFontSize};
  }

  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const NoticeP = styled.p<Pprop>`
  display: ${({ hide }) => (hide ? "none" : "block")};
  line-height: 1.8;
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const VerticalBar = styled.div`
  width: 100%;
  height: 1px;
  margin: 0vw 0vw 0vw 0vw;
  border: solid 1px #eee;
  background-color: #bebebe;
`;

export const SearchSpan = styled.span<Pprop>`
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

interface optionProp {
  fontWeight: string;
  fontSize: string;
  mobileFontSize?: string;
}

export const SidoOption = styled.select<optionProp>`
  background-color: white;
  display: inline-flex;
  height: 100%;
  width: 90%;
  padding: 0.2vw;

  border-radius: 5px;
  border: solid 3px #fff;
  background-color: #fff;

  font-family: NotoSansKR;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }

  option {
    color: black;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const SearchInput = styled.input<inputProp>`
  display: flex;

  box-sizing: border-box;
  width: 100%;
  padding-left: 2%;
  outline: none;

  height: 100%;

  border: none;
  border-radius: 5px;
  background: transparent;

  font-family: NotoSansKR;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  text-align: left;
  color: #48484a;

  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const SearchButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  opacity: 1;
  :hover {
    opacity: 0.5;
  }

  :active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transition-duration: 0.4s;
  }
  :after {
    content: "";
    background: #90ee90;
    opacity: 0;
    transition: all 0.8s;
  }
  :active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }
`;

const circleAni = keyframes`
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(360deg);
  }
`;

const sourceAni = keyframes`
  0%{
    background-image: url(${loading01});
  }
  33%{
    background-image: url(${loading01});
  }
  66%{
    background-image: url(${loading02});
  }
  100%{
    background-image: url(${loading03});
  }
`;

// background: rgb(241, 243, 0) url(${loading03}) no-repeat 8px 50%
// background-size: auto | length | cover | contain | initial | inherit

export const LoadingImgContainer = styled.div`
  display: flex;
  width: 18%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  @media (max-width: 500px) {
    width: 20%;
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  height: 9vw;
  max-height: 120px;
  animation: ${sourceAni} 0.7s step-start infinite;
`;

export const BtnImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 90%;
`;

export const ResultImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 17%;
`;

export const ExplainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20%;
  padding-top: 1%;
  width: 100%;
  box-sizing: border-box;
  height: 100%;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.08px;
  text-align: left;
  @media (max-width: 500px) {
    padding-left: 0%;
  }
`;

export const ResultContainer = styled.div`
  display: inline-flex;
  width: 100%;
  height: 100%;
`;
