import { UserData } from "src/data/api-response/user";
import User from "src/data/models/user";
import { UserLoginStatusType } from "src/data/type/status";
import Service from "../service";

class UserService extends Service {
  private login_status: UserLoginStatusType = UserLoginStatusType.LOADING;
  private token?: string;
  private user?: User;
  private login_fail_msg?: string | undefined = undefined;
  private phone_verified = false;
  private agent_info = false;
  private agent_boss_name?: string;
  private agent_office_name?: string;
  private agent_enroll_id?: string;
  private virtual_bank_account?: string;
  private virtual_bank_name?: string;
  private virtual_bank_deadline?: string;
  private virtual_bank_amount_of_money?: string;

  /**
   * Getter 토큰 가져오기
   * @return {string }
   */
  public get_token(): string | undefined {
    return this.token;
  }

  /**
   * Setter token을 저장
   * @param {string } value
   */
  public set_token(value: string | undefined) {
    this.token = value;
  }

  /**
   * Getter $user
   * @return {User }
   */
  public get_user(): User | undefined {
    return this.user;
  }

  /**
   * Setter $user
   * @param {UserData } user
   */
  public update_user(user: UserData | undefined): void {
    if (user)
      this.user = new User(
        user.email,
        user.phone,
        user.full_name,
        user.user_type,
        user.point
      );
    else this.user = undefined;
  }

  public set_login_status(login_status: UserLoginStatusType) {
    this.login_status = login_status;
  }

  public get_login_status(): UserLoginStatusType {
    return this.login_status;
  }

  public set_login_fail_msg(value: string | undefined) {
    this.login_fail_msg = value;
  }

  public get_login_fail_msg() {
    return this.login_fail_msg;
  }

  public update_phone_verified(value: boolean) {
    this.phone_verified = value;
  }

  public get $phone_verified(): boolean {
    return this.phone_verified;
  }

  public get_agent_info() {
    return this.agent_info;
  }
  public get_agent_boss_name() {
    return this.agent_boss_name;
  }
  public get_agent_office_name() {
    return this.agent_office_name;
  }
  public get_agent_enroll_id() {
    return this.agent_enroll_id;
  }


  public set_virtual_bank_account(value: string | undefined) {
    this.virtual_bank_account = value;
  }
  public set_virtual_bank_name(value: string | undefined) {
    this.virtual_bank_name = value;
  }
  public set_virtual_bank_amount_of_money(value: string | undefined) {
    this.virtual_bank_amount_of_money = value;
  }
  public set_virtual_bank_amount_of_deadline(value: string | undefined) {
    this.virtual_bank_deadline = value;
  }
  public get_virtual_bank_account() {
    return this.virtual_bank_account;
  }
  public get_virtual_bank_name() {
    return this.virtual_bank_name;
  }
  public get_virtual_bank_amount_of_money() {
    return this.virtual_bank_amount_of_money;
  }
  public get_virtual_bank_deadline() {
    return this.virtual_bank_deadline;
  }

  public set_agent_info(value: boolean | undefined) {
    if (value) {
      this.agent_info = value;
    }
  }
  public set_agent_boss_name(value: string | undefined) {
    if (value) {
      this.agent_boss_name = value;
    } else {
      this.agent_boss_name = "";
    }
  }
  public set_agent_office_name(value: string | undefined) {
    if (value) {
      this.agent_office_name = value;
    } else {
      this.agent_office_name = "";
    }
  }
  public set_agent_enroll_id(value: string | undefined) {
    if (value) {
      this.agent_enroll_id = value;
    } else {
      this.agent_enroll_id = "";
    }
  }
}

export default UserService;
