import styled from "styled-components";

export const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

// No right padding because of main image
interface props {
  backgroundImage?: string;
}

// background-image: url(${({ backgroundImage }) => backgroundImage});
export const FirstRowContainer = styled.div<props>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 750px;
  z-index: 0;
  background-color: #ffffff;

  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;

  @media (max-width: 1440px) {
    height: 400px;
    padding-right: 0%;
    padding-left: 0%;
  }

  @media (max-width: 500px) {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.85),
        rgba(255, 255, 255, 0.85)
      ),
      url(${({ backgroundImage }) => backgroundImage});
    height: 90vw;
    padding-right: 0%;
    padding-left: 0%;
  }
`;

export const FirstRowLeftContainer = styled.div`
  display: flex;
  position: relative;
  left: 15%;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-bottom: 10%;
  padding-top: 10%;
  width: 60%;
  min-width: 200px;
  box-sizing: border-box;

  @media (max-width: 500px) {
    min-width: 80px;
    left: 5%;
  }
`;

export const FirstRowRightContainer = styled.div`
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  z-index: 0;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px outset pink;

  @media (max-width: 500px) {
    min-width: 80px;
  }
`;

export const FirstRowItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  z-index: 10;

  width: 1440px;
  padding-left: 144px;
  padding-right: 500px;

  @media (max-width: 1440px) {
    width: 80%;
    padding-left: 0%;
    padding-right: 250px;
  }

  @media (max-width: 500px) {
    width: 90%;
    padding-right: 0%;
    padding-left: 0%;
    justify-content: center;
    align-items: center;
  }
`;

// export const FirstRowItem = styled.div`
//   border: 1px solid black;
//   box-sizing: border-box;
//   position: relative;
//   left: -248px;
//   width: 45%;
//   max-width: 800px;
//   z-index: 10;

//   @media (max-width: 1440px) {
//     left: -180px;
//   }

//   @media (max-width: 500px) {
//         left: -22.5%;
//     }
// `;

// font-size: 3vw;
export const TextContainer = styled.div`
  display: flex;
  font-family: NotoSansKR;
  font-weight: bold;
  text-align: left;
  font-size: 50px;
  height: 100%;

  @media (max-width: 1440px) {
    font-size: 3vw;
  }

  @media (max-width: 500px) {
    text-align: center;
    font-size: 6vw;
  }
`;

export const BlackBoldText = styled.p`
  font-size: 100%;
  color: #2c2c2e;
`;

export const GreenBoldText = styled.span`
  color: #00982f;
`;

export const SubTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-contents: center;
  font-family: NotoSansKR;
  font-weight: bold;
  text-align: left;
  font-weight: 500;
  font-size: 30px;

  @media (max-width: 1440px) {
    font-size: 1.85vw;
  }

  @media (max-width: 500px) {
    text-align: center;
    width: 80%;
    font-size: 4vw;
  }
`;

export const BtnRowContainer = styled.div`
  z-index: 10;
  display: inline-flex;
  flex-direction: row;
  justify-content: left;
  align-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  @media (max-width: 500px) {
    flex-direction: row;
    justify-content: center;
    width: 90%;
  }
`;

interface BtnProp {
  color: string;
  borderColor?: string;
}

export const MainPageBtn = styled.button<BtnProp>`
  outline: none;
  background-color: ${({ color }) => color};
  background-repeat: no-repeat;
  cursor: pointer;
  border: 1px solid ${({ color, borderColor }) => (borderColor ? borderColor : color)};
  color: ${({ borderColor }) => (borderColor ? borderColor : "#ffffff")};
  opacity: 1;
  height: 40px;
  font-size: 18px;
  margin-right: 13px;

  text-align: center;
  &:hover {
    opacity: 0.65;
  }
  width: 50%;
  padding: 0.7%;
  border-radius: 8px;

  @media (max-width: 1440px) {
    height: 2.2vw;
    margin-right: 1vw;
    font-size: 1vw;
    border-radius: 8px;
  }

  @media (max-width: 500px) {
    width: 60%;
    height: auto;
    padding-top: 2.5vw;
    margin-right: 4vw;
    padding-bottom: 2.5vw;
    border-radius: 4.5vw;
  }
`;

interface Pprop {
  fontWeight: string;
  fontFamily: string;
  fontSize: string;
  color: string;
  margin: string;
  mobileFontSize?: string;
}

export const GrayNoticeP = styled.p<Pprop>`
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 18px;
  color: ${({ color }) => color};
  @media (max-width: 1440px) {
    font-size: ${({ fontSize }) => fontSize};
  }

  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const MainBtnP = styled.p<Pprop>`
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 21px;
  color: ${({ color }) => color};

  @media (max-width: 1440px) {
    font-size: ${({ fontSize }) => fontSize};
  }

  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const GrayP = styled.p<Pprop>`
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 26px;
  color: ${({ color }) => color};

  @media (max-width: 1440px) {
    font-size: ${({ fontSize }) => fontSize};
  }

  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const ProductP = styled.p<Pprop>`
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 26px;
  color: ${({ color }) => color};

  @media (max-width: 1440px) {
    font-size: ${({ fontSize }) => fontSize};
  }

  @media (max-width: 500px) {
    text-align: center;
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const FooterP = styled.p<Pprop>`
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 15px;
  color: ${({ color }) => color};

  @media (max-width: 1440px) {
    font-size: ${({ fontSize }) => fontSize};
  }

  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const FooterAppDownDesc = styled.p<Pprop>`
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  width: 80%;
  font-size: ${({ fontSize }) => fontSize};

  @media (max-width: 1440px) {
    font-size: ${({ fontSize }) => fontSize};
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 100%;
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const GraySpan = styled.span<Pprop>`
  margin: ${({ margin }) => margin};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  @media (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const NoticeContainer = styled.div`
  margin-top: 3vw;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
`;

export const MainIllustContainer = styled.div`
  display: inline-block;
  position: absolute;
  z-index: -1;
  overflow: hidden;
  box-sizing: border-box;
  @media (max-width: 500px) {
    width: 523px;
    height: 189px;
    left: 40%;
  }
`;

export const MainIllustImg = styled.img`
  box-sizing: border-box;
  right: 0%;
  background: rbg(255, 255, 255);
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;

//   border: 1px outset blue;
export const MainIllustImgOrig = styled.img`
  box-sizing: border-box;
  border: 10px outset pink;
  background: rbg(255, 255, 255);
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;

//   object-position: 16vw -3vh;
interface OtherFlexProp {
  backgroundColor: string;
}

export const OtherRowBackground = styled.div<OtherFlexProp>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-repeat: no-repeat;
  overflow: hidden;
  width: 100%;
`;

export const OtherRowContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  background-repeat: no-repeat;
  overflow: hidden;

  width: 1440px;
  padding-left: 144px;
  padding-right: 144px;

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const ProductRowContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-evenly;
  box-sizing: border-box;
  align-items: center;

  background-repeat: no-repeat;
  overflow: hidden;

  width: 1440px;
  margin-top: 80px;
  padding-left: 144px;
  padding-right: 144px;

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 500px) {
    margin-top: 0px;
    flex-direction: column-reverse;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const FooterRowContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  background-repeat: no-repeat;
  overflow: hidden;

  width: 1440px;
  padding-left: 144px;
  padding-right: 144px;

  @media (max-width: 1440px) {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const BackgroundRowContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  height: 10vw;
  align-items: center;
  background-repeat: no-repeat;
  background: linear-gradient(to bottom, white, #cecece);
  @media (max-width: 500px) {
    height: 20vw;
  }
`;

export const BackgroundContainer = styled.div<DivProp>`
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  text-align: left;
  box-sizing: border-box;
`;

interface DivProp {
  position?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  justifyContent?: string;
  alignItems?: string;
  textAlign?: string;
}

export const OtherContainer = styled.div<DivProp>`
  display: flex;
  flex-direction: column;
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  width: 50%;
  height: 250px;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  text-align: ${({ textAlign }) => textAlign};
  box-sizing: border-box;

  @media (max-width: 1440px) {
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }

  @media (max-width: 500px) {
    justify-content: center;
    aligh-items: center;
    width: 100%;
    height: 40vw;
    margin: 0 0 0 0;
    bottom: -10px;
  }
`;

export const ProductContainer = styled.div<DivProp>`
  display: flex;
  flex-direction: column;
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  text-align: ${({ textAlign }) => textAlign};
  box-sizing: border-box;

  @media (max-width: 1440px) {
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }

  @media (max-width: 500px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    bottom: -10px;
  }
`;

export const FooterContainer = styled.div<DivProp>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  width: 50%;
  height: 250px;
  align-items: ${({ alignItems }) => alignItems};
  text-align: ${({ textAlign }) => textAlign};
  box-sizing: border-box;

  @media (max-width: 1440px) {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }

  @media (max-width: 500px) {
    justify-content: center;
    aligh-items: center;
    width: 90vw;
    height: 200px;
  }
`;

export const FooterBadgeContainer = styled.div<DivProp>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  width: 80%;
  height: 250px;
  align-items: ${({ alignItems }) => alignItems};
  text-align: ${({ textAlign }) => textAlign};
  box-sizing: border-box;

  @media (max-width: 1440px) {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: ${({ margin }) => margin};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }

  @media (max-width: 500px) {
    justify-content: center;
    aligh-items: center;
    width: 80vw;
    height: 200px;
  }
`;
export const OtherImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;

  width: 80%;
`;

export const ProductImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;

  width: 100%;
`;

export const ProductSmallImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;

  width: 150%;
`;

export const BgrImg = styled.img`
  box-sizing: border-box;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

//   background: linear-gradient(to top, #cecece, #fff);
export const FooterLogoImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 25%;

  @media (max-width: 700px) {
    width: 50%;
  }
`;
export const FooterBadgeImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 100%;
  margin-left: 15px;
  cursor: pointer; 
  &:hover {
    opacity: 0.65;
  }

  @media (max-width: 700px) {
    padding-bottom: 10px;
    width: 90%;
  }
`;
