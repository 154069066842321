import {
  OuterContainer,
  Card,
  CardStateRow,
  CardStateLeft,
  CardStateRight,
  CardPreviewRow,
  CardPreviewLeft,
  CardPreviewRight,
  CardContentRow,
  CardContentLeft,
  CardContentRight,
  CardConfirmRow,
  CardConfirmLeft,
  CardConfirmRight,
} from "src/components/openHistory/openHistoryStyle";

import {
  BodyWrapper,
  TabRow,
  SearchRow,
  ResultRow,
  NoticeRow,
  ResultInnerContainer,
  ResultTableContainer,
  HistoryContainer,
  Title,
  RemoveText,
  ListContainer,
  KeywordContainer,
  Keyword,
  RemoveButton,
} from "src/components/searchPage/searchPageStyle";

import ReadingImg from "src/assets/png/reading.png";
import searchIconImg from "src/assets/png/search.png";
import SearchFailImg from "src/assets/png/too-many.png";
import searchHistoryImg from "src/assets/png/searchHistoryIcon.png";
import removeHistoryImg from "src/assets/png/cancel.png";

import {
  SearchContainer,
  TabItem,
  TabContent,
  SearchLeftContainer,
  SearchRightContainer,
  ImportImage,
  SearchInnerContainer,
  SearchP,
  SearchSpan,
  NoticeP,
  SidoOption,
  SearchOptionContainer,
  SearchAddressContainer,
  ExplainContainer,
  SearchInput,
  SearchButton,
  BtnImg,
  ResultImg,
  LoadingBox,
  VerticalBar,
  LoadingImgContainer,
  SearchHistoryContainer,
  SearchHistoryInner,
  SearchHistoryUl,
  SearchHistoryLi,
  SearchHistoryItemDiv,
  SearchHistoryIcon,
  SearchHistoryText,
  SearchHistoryImg,
} from "src/components/searchPage/searchPageStyleTab";

import useSearchPage from "./searchPage.hooks";
import SearchPageTable from "../table/searchPageTable";
import {
  OpenHistoryStatus,
  SearchInputStatusType,
  SearchResultStatusType,
} from "src/data/type/search";
import OpenHistoryList from "../openHistory/openHistory";
import { useSelector } from "src/hooks/useSelector";

export const SearchPage = () => {
  const {
    tabClick,
    currentTab,
    sidoName,
    setSidoName,
    searchInput,
    setSearchInput,
    searchBtnClick,
    PageSize,
    totalCount,
    searchInputStatus,
    searchResultStatusAtService,
    currentResultMsg,
    addSearchHistoryItem,
    removeSearchHistoryItem,
    searchHistory,
    setHistoryOpen,
    historyOpen,
    searchBtnClickUsingHistory,
  } = useSearchPage();

  const openHistoryStatus = useSelector((state) =>
    state.searchReducer.get_open_history_status()
  );

  // const [focused, setFocused] = React.useState(false);
  const onFocus = () => setHistoryOpen(true);
  const onBlur = () => setHistoryOpen(false);

  function selectSearchHistory(element: string) {
    setSearchInput(element);
    setHistoryOpen(false);
    searchBtnClickUsingHistory(element);
  }

  // let sidoOptions: Array<string> = ["서울특별시"];
  // let sidoOptions: Array<string> = ["서울특별시", "경기도"];
  let sidoOptions: Array<string> = [
    "서울특별시",
    "경기도",
    "인천광역시",
    "대전광역시",
    "대구광역시",
    "울산광역시",
    "광주광역시",
    "부산광역시",
  ];

  return (
    <BodyWrapper>
      <TabRow>
        <TabItem onClick={() => tabClick(0)} active={currentTab === 0}>
          검색하기
        </TabItem>
        <TabItem onClick={() => tabClick(1)} active={currentTab === 1}>
          조회내역
        </TabItem>
      </TabRow>
      <TabContent active={currentTab === 0}>
        <SearchRow active={true}>
          <SearchContainer>
            <SearchLeftContainer>
              <SearchInnerContainer height={"45%"}>
                <SearchP
                  margin={"none"}
                  fontWeight={"400"}
                  fontSize={"2vw"}
                  color="#fff"
                  fontFamily="NotoSansCJKkr"
                  mobileFontSize={"5vw"}
                  maxFontSize={"2.5em"}
                >
                  전세 계약에 필요한 점검 사항들을 <br />
                  <SearchSpan
                    margin={"none"}
                    fontWeight={"720"}
                    fontSize={"100%"}
                    color="fff"
                    fontFamily="NotoSansCJKkr"
                    mobileFontSize={"5vw"}
                  >
                    주소검색 한번으로{" "}
                  </SearchSpan>
                  간편하게 확인하세요!
                </SearchP>
              </SearchInnerContainer>
              <SearchInnerContainer height={"25%"}>
                <SearchOptionContainer width={"20%"} mobileWidth={"30%"}>
                  <SidoOption
                    fontSize={"1vw"}
                    mobileFontSize={"3vw"}
                    fontWeight={"500"}
                    value={sidoName}
                    onChange={(event) => {
                      setSidoName(event.target.value);
                    }}
                  >
                    {sidoOptions.map((element) => {
                      return <option style={{color: "black"}} key={element}>{element}</option>;
                    })}
                  </SidoOption>
                </SearchOptionContainer>
                <SearchAddressContainer
                  width={"70%"}
                  mobileWidth={"70%"}
                  active={
                    searchInputStatus === SearchInputStatusType.Disabled
                      ? false
                      : true
                  }
                >
                  <SearchInput
                    disabled={
                      searchInputStatus === SearchInputStatusType.Disabled
                        ? true
                        : false
                    }
                    onFocus={onFocus}
                    // onBlur={onBlur}
                    fontSize={"1vw"}
                    mobileFontSize={"3vw"}
                    fontWeight={"500"}
                    type="text"
                    placeholder="어떤 집을 알아보고 계시나요?"
                    value={searchInput}
                    onChange={({ target: { value } }) => setSearchInput(value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        searchBtnClick();
                      }
                    }}
                  ></SearchInput>
                  <SearchButton
                    disabled={
                      searchInputStatus === SearchInputStatusType.Disabled
                        ? true
                        : false
                    }
                    onClick={searchBtnClick}
                  >
                    <BtnImg src={searchIconImg} />
                  </SearchButton>
                </SearchAddressContainer>
              </SearchInnerContainer>
              <SearchHistoryContainer
                active={historyOpen && searchHistory?.length > 0}
              >
                <SearchHistoryInner>
                  <SearchHistoryUl>
                    {/* {getSearchHistory()} */}
                    {/* {JSON.parse(
                      localStorage.getItem("searchHistory") || "[]" */}
                    {searchHistory
                      .slice(0)
                      .reverse()
                      .map((element: string) => {
                        // return <li>{element}</li>;
                        return (
                          <SearchHistoryLi key={element}>
                            <SearchHistoryItemDiv>
                              <SearchHistoryImg src={searchHistoryImg} />
                              <SearchHistoryText
                                style={{ cursor: "pointer" }}
                                onClick={() => selectSearchHistory(element)}
                              >
                                {element}
                              </SearchHistoryText>
                              <SearchHistoryImg
                                style={{ cursor: "pointer" }}
                                onClick={() => removeSearchHistoryItem(element)}
                                src={removeHistoryImg}
                              />
                            </SearchHistoryItemDiv>
                          </SearchHistoryLi>
                        );
                      })}
                  </SearchHistoryUl>
                </SearchHistoryInner>
              </SearchHistoryContainer>
              {/* <HistoryContainer>
                <HeaderContainer>
                  <Title> 최근 검색어 </Title>
                  <RemoveText onClick={() => onClearKeywords}>전체삭제</RemoveText>
                  <RemoveText> 전체 삭제</RemoveText>
                </HeaderContainer>
                <ListContainer>
                      <KeywordContainer key={1}>
                        <Keyword>
                          key1
                        </Keyword>
                        <RemoveButton>
                          삭제
                        </RemoveButton>
                      </KeywordContainer>
                </ListContainer>                
              </HistoryContainer> */}
              <SearchInnerContainer height={"30%"}>
                <ExplainContainer>
                  <SearchP
                    margin={"0px 0px 0px 0px"}
                    fontWeight={"400"}
                    fontSize={"0.9vw"}
                    color="#fff"
                    fontFamily="NotoSansCJKkr"
                    mobileFontSize={"2.5vw"}
                  >
                    * 현재 서비스 가능 지역은 수도권 및 6대 광역시 입니다.{" "}
                    <br />* 본 검색은 인터넷 등기소의 주소 검색창과 연동되어
                    있습니다. <br /> (검색 예시) 독도대로 10길 101호인 경우
                    '독도대로 10 101' 입력
                    <br />
                  </SearchP>
                </ExplainContainer>
              </SearchInnerContainer>
            </SearchLeftContainer>
            <SearchRightContainer>
              <ImportImage src={ReadingImg} />
            </SearchRightContainer>
          </SearchContainer>
        </SearchRow>
        <NoticeP
          hide={
            searchResultStatusAtService === SearchResultStatusType.TooMany
              ? false
              : true
          }
          margin={"0 18px 0 18px"}
          fontWeight={"600"}
          fontSize={"1.0vw"}
          color="#aeaeb2"
          fontFamily="NotoSansCJKkr"
          mobileFontSize={"3vw"}
        >
          너무 많은 주소가 검색되어 일부 결과가 생략되었습니다. 검색 조건을 더
          입력해보세요.
        </NoticeP>
        <ResultRow
          border={
            searchResultStatusAtService === SearchResultStatusType.Normal ||
            searchResultStatusAtService === SearchResultStatusType.TooMany
              ? true
              : false
          }
        >
          <ResultInnerContainer
            active={
              searchResultStatusAtService === SearchResultStatusType.Ready
                ? true
                : false
            }
          >
            <NoticeP
              margin={"none"}
              fontWeight={"600"}
              fontSize={"1.0vw"}
              color="#aeaeb2"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3vw"}
            >
              주소를 검색해 주세요.
            </NoticeP>
          </ResultInnerContainer>
          <ResultInnerContainer
            active={
              searchResultStatusAtService === SearchResultStatusType.IrosError
                ? true
                : false
            }
          >
            <ResultImg src={SearchFailImg} />
            <NoticeP
              margin={"none"}
              fontWeight={"600"}
              fontSize={"1.0vw"}
              color="#aeaeb2"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3vw"}
            >
              {/* 인터넷 등기소 점검시간 입니다. 아래 공지사항을 확인 후 다시
              시도해주세요. */}
              인터넷 등기소 점검 시간입니다. 다음에 다시 이용해 주세요.
              {/* 인터넷 등기소 점검 시간 입니다. <br /> (2022년 10월 20일(목) 21:00 ~ 10월 21일(금) 06:00) */}
              {/* 서비스 점검 중입니다. <br /> (2022년 10월 27일(목) 18:00 ~ 10월 28일(금) 06:00) */}
            </NoticeP>
          </ResultInnerContainer>
          <ResultInnerContainer
            active={
              searchResultStatusAtService ===
              SearchResultStatusType.InternalError
                ? true
                : false
            }
          >
            <ResultImg src={SearchFailImg} />
            <NoticeP
              margin={"none"}
              fontWeight={"600"}
              fontSize={"1.0vw"}
              color="#aeaeb2"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3vw"}
            >
              {/* 서비스 점검 시간 입니다. */}
              {/* 서비스 점검 중 입니다. */}
              {/* 서비스 점검 중입니다. <br /> (2022년 10월 27일(목) 18:00 ~ 10월 28일(금) 06:00) */}
              인터넷 등기소 점검 시간입니다. 다음에 다시 이용해 주세요.
              {/* 인터넷 등기소 점검 시간 입니다. <br /> (2022년 10월 20일(목) 21:00 ~ 10월 21일(금) 06:00) */}
            </NoticeP>
          </ResultInnerContainer>
          <ResultInnerContainer
            active={
              searchResultStatusAtService === SearchResultStatusType.Zero
                ? true
                : false
            }
          >
            <ResultImg src={SearchFailImg} />
            <NoticeP
              margin={"none"}
              fontWeight={"600"}
              fontSize={"1.0vw"}
              color="#aeaeb2"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3vw"}
            >
              주소를 검색하지 못했습니다. 검색어를 변경해보세요.
            </NoticeP>
          </ResultInnerContainer>
          <ResultInnerContainer
            active={
              searchResultStatusAtService === SearchResultStatusType.Loading
                ? true
                : false
            }
          >
            <LoadingImgContainer>
              <LoadingBox />
            </LoadingImgContainer>
            <NoticeP
              margin={"none"}
              fontWeight={"600"}
              fontSize={"1.1vw"}
              color="#aeaeb2"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3vw"}
            >
              인터넷 등기소에서 주소를 검색중입니다..
            </NoticeP>
          </ResultInnerContainer>
          <ResultInnerContainer
            active={
              searchResultStatusAtService === SearchResultStatusType.TooMany
                ? true
                : false
            }
          >
            {/* <ResultImg src={SearchFailImg} /> */}
          </ResultInnerContainer>
          <ResultTableContainer
            active={
              searchResultStatusAtService === SearchResultStatusType.Normal ||
              searchResultStatusAtService === SearchResultStatusType.TooMany
                ? true
                : false
            }
          >
            <SearchPageTable siblingCount={10} pageSize={PageSize} />
          </ResultTableContainer>
        </ResultRow>
        <NoticeRow>
          <NoticeP
            margin={"none"}
            fontWeight={"400"}
            fontSize={"1.0vw"}
            color="#48484a"
            fontFamily="NotoSansCJKkr"
            mobileFontSize={"3vw"}
          >
            공지사항
          </NoticeP>
          <VerticalBar />
          <NoticeP
            margin={"none"}
            fontWeight={"500"}
            fontSize={"0.83vw"}
            color="#757575"
            fontFamily="NotoSansCJKkr"
            mobileFontSize={"2.8vw"}
          >
            <br />
            * 본 서비스는 등본 발급 행정 수수료를 포함한 유료 서비스입니다.
            <br />
            * 모바일 서비스가 공개 되었습니다. 화면 하단의 앱 링크를 통해 더욱
            다양한 기능을 사용해보세요!
            <br />
            <br />
            [통계 정보 (2024년 10월 10일 기준)] <br />
            - 누적 가입자 수: 13,157 명 <br />
            - 발급 리포트 수: 12,552 건 <br />
            - 분석한 보증금 총 액: 4조 9,882억 원 <br />
            - 분석 결과 '안전' 주택 비율: 33.41 % <br />
            {/* * 신규 가입자에게 무료 껍데기 (이용권) 2회 증정
            이벤트를 진행 중이니 회원가입을 서둘러주세요!
            <br /> */}
            <br />
            [시세 정보]
            <br />-
            <a
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#005d1d",
                textDecoration: "none",
                whiteSpace: "pre-wrap",
                textDecorationLine: "none",
                width: "100%",
              }}
              href="https://www.khug.or.kr/hug/web/ig/dr/igdr000001.jsp"
            >
              {" "}
              주택도시 보증공사
            </a>
            의 주택가격 산정 방식으로 시세를 결정합니다.
            <br />- KB시세:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: "#005d1d", textDecoration: "none" }}
              href="https://kbland.kr"
            >
              https://kbland.kr
            </a>
            , 매주 금요일 업데이트 <br />- 오피스텔: 국세청 기준시가 (
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: "#005d1d", textDecoration: "none" }}
              href="https://www.hometax.go.kr"
            >
              https://www.hometax.go.kr
            </a>
            ), 매년 초 업데이트 <br />- 다세대, 원룸 등: 공동주택 공시가격 (
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: "#005d1d", textDecoration: "none" }}
              href="https://www.realtyprice.kr"
            >
              https://www.realtyprice.kr
            </a>
            ), 반기별 업데이트 <br />- 단독주택: 개별단독 주택가격 (
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: "#005d1d", textDecoration: "none" }}
              href="https://www.realtyprice.kr"
            >
              https://www.realtyprice.kr
            </a>
            ), 반기별 업데이트 <br />
            <br />
            [공공 데이터 업데이트 주기] <br />- 건축물대장: 매월 마지막 주
            금요일 <br />
            <br />
            {/* [정기 점검 일정] <br />
            * 인터넷 등기소 점검시간에는 실시간 등기부등본 발급이 불가하여
            서비스 이용이 불가능합니다. 이 때 주소검색창이 비활성화 되니 서비스
            이용에 참고부탁드립니다. <br />
            - 정기 변경 작업: 매월 첫째 주, 셋째 주 목요일 21:00 - 06:00 <br />
            - 수시 변경 작업: 관리자가 등기소 작업 일정을 확인하여 수시로
            공지해드립니다. <br /> */}
          </NoticeP>
        </NoticeRow>
      </TabContent>
      <TabContent active={currentTab === 1}>
        <OuterContainer
          active={openHistoryStatus === OpenHistoryStatus.HistoryZero}
        >
          조회 내역이 존재하지 않습니다.
        </OuterContainer>
        <OuterContainer
          active={openHistoryStatus === OpenHistoryStatus.HistoryMoreThanOne}
        >
          <OpenHistoryList />
        </OuterContainer>
      </TabContent>
    </BodyWrapper>
  );
};
