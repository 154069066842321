import { useState } from "react";
import { useDispatch } from "react-redux";
import { UiComponentType } from "src/data/type/ui";
import { useSelector } from "src/hooks/useSelector";
import { uiVisible } from "src/services/ui/ui.actions";

export default function useAgentSearchModal() {
  const dispatch = useDispatch();
  const visible = useSelector((state) => {
    return state.uiReducer.getUiComponent(UiComponentType.MODAL_AGENT_SEARCH)
      .visible;
  });

  const open_agent_search_modal = () => {
    console.log("hey");
    dispatch(
      uiVisible({ id: UiComponentType.MODAL_AGENT_SEARCH, visible: true })
    );
  };

  const close_agent_search_modal = () => {
    dispatch(
      uiVisible({ id: UiComponentType.MODAL_AGENT_SEARCH, visible: false })
    );
  };

  return {
    visible,
    open_agent_search_modal,
    close_agent_search_modal,
  };
}
