import styled from "styled-components";

export const AbTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;
`;

export const AbThead = styled.thead`
  font-weight: 500;
  text-align: left;
`;
export const AbTr = styled.tr`
  font-weight: 500;
  text-align: left;
`;
export const AbTh = styled.th`
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: 500;
  text-align: center;
`;

export interface tbodyProps {
  backgroundColor?: string;
}

export const AbTbodyTr = styled.tr<tbodyProps>`
  background-color: ${(props) => props.backgroundColor};

  &:hover {
    background-color: #E3E3E3;
  }

  @media (max-width: 500px) {
    font-size: 4.5vw;
  }

`;

export interface tdProp {
  width?: string;
  mobileWidth?: string;
}

export const AbTbodyTd = styled.td<tdProp>`
  width: ${(props) => props.width};
  padding: 8px;
  overflow-wrap: break-word;
  padding-top: 1%;
  padding-bottom: 1%;
  @media (max-width: 500px) {
    width: ${(props) => props.mobileWidth};
  }

`;

export const BtnContainer = styled.div`
  align-items: center;
  text-align: center;
  width: 80%;
  height: 100%;
  border-radius: 5px;
  border: solid 2.5px #008128;
  background-color: #fff;
  font-family: NotoSansKR;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #008128;
  &:hover {
    cursor: pointer;
    opacity: 0.70;
  }

  @media (max-width: 500px) {
    border: solid 1px #008128;
    width: 100%;
  }
`;

export const BtnDisabledContainer = styled.div`
  align-items: center;
  text-align: center;
  width: 80%;
  height: 100%;
  border-radius: 5px;
  border: solid 2.5px #aeaeb2;
  background-color: #fff;
  font-family: NotoSansKR;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #aeaeb2;
 
  @media (max-width: 500px) {
    border: solid 1px #aeaeb2;
    width: 100%;
  }
`;

export const BtnText = styled.span`
  font-size: 90%;
`;

interface BtnProp {
  fontSize: string;
}

export const LookupDisabledBtn = styled.button<BtnProp>`
  font-size: ${({ fontSize }) => fontSize};
  background-color: transparent;
  width: 100%;
  padding: 0;
  background: none;
  border: none;
  color: #aeaeb2;
`

export const LookupBtn = styled.button<BtnProp>`
  font-size: ${({ fontSize }) => fontSize};
  background-color: transparent;
  width: 100%;
  padding: 0;
  background: none;
  border: none;
  color: #008128;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
  // outline: none;
  // background-color: ${({ color }) => color};
  // background-repeat: no-repeat;
  // cursor: pointer;
  // font-size: 1vw;
  // text-align: center;
  // opacity: 1;
  // &:hover {
  //   opacity: 0.65;
  // }
  // width: 50%;
  // height: 2.2vw;
  // margin-right: 1vw;
  // padding: 0.7%;
  // border-radius: 8px;
  // border: 0px;
  // @media (max-width: 500px) {
  //     height: 3vw;
  // }
