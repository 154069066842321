import { ColFlex } from "src/styles/common/flex";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import mainIllust from "src/assets/png/main-illust.png";
import tenantIllust from "src/assets/png/tenant.png";
import agentIllust from "src/assets/png/agent.png";
import ownerIllust from "src/assets/png/owner.png";
import footerLogoIllust from "src/assets/png/footer-logo.png";
import reportExampleImg from "src/assets/png/example_report.png";
import insuranceExampleImg from "src/assets/png/product_02.png";
import product03 from "src/assets/png/product_03.png";
import banner01 from "src/assets/png/banner01.png";
import bannerModified from "src/assets/png/banner-mod.png";
import banner2Modified from "src/assets/png/banner-mod2.png";
import example5 from "src/assets/png/example5.png";
import smallbanner02 from "src/assets/png/smallbanner02.png";
import smallbanner01 from "src/assets/png/smallbanner01.png";
import { Link, useHistory } from "react-router-dom";
import { SearchPage } from "../searchPage/searchPage";
import bgrIllust from "src/assets/png/background.png";
// import {
//   TextContainer,
//   BlackBoldText,
//   GreenBoldText,
//   SubTextContainer,
//   FirstRowFlex,
//   MainIllustImg,
//   FirstRowItem,
//   BtnRowContainer,
//   MainPageBtn,
//   GrayP,
//   NoticeContainer,
//   MainIllustContainer,
//   OtherRowContainer,
//   OtherContainer,
//   OtherImg,
// } from "src/styles/mainPage/mainPage";
import { setCurrentTab } from "src/services/search/search.actions";

import {
  BodyWrapper,
  FirstRowContainer,
  TextContainer,
  BlackBoldText,
  GreenBoldText,
  SubTextContainer,
  MainIllustImg,
  FirstRowItem,
  BtnRowContainer,
  MainPageBtn,
  GrayNoticeP,
  GrayP,
  NoticeContainer,
  MainIllustContainer,
  OtherRowBackground,
  OtherRowContainer,
  OtherContainer,
  BackgroundRowContainer,
  BackgroundContainer,
  OtherImg,
  FooterLogoImg,
  FooterP,
  FooterContainer,
  FirstRowLeftContainer,
  FirstRowRightContainer,
  BgrImg,
  MainBtnP,
  ProductRowContainer,
  ProductContainer,
  ProductImg,
  ProductP,
  ProductSmallImg,
} from "src/components/introPage/IntroPageStyle";

export const IntroPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });

  const goSearchPage = () => {
    try {
      dispatch(setCurrentTab(0));
      history.push("/search");
    } catch (e) {
      console.error(`Failed to go search page ${e}`);
    }
  };

  const goHistoryPage = () => {
    try {
      dispatch(setCurrentTab(1));
      history.push("/search");
    } catch (e) {
      console.error(`Failed to go search page ${e}`);
    }
  };

  return (
    //TODO: 8월 첫째주 중 디자인 적용된 화면 적용: intro page에 하단으로 더 붙이기
    <BodyWrapper>
      <FirstRowContainer backgroundImage={mainIllust}>
        <FirstRowItem>
          <TextContainer>
            <BlackBoldText>
              {/* 전세 계약 점검사항은 <br />
              <GreenBoldText>안전한 부동산을 부탁해 </GreenBoldText>
              하나면 해결! */}
              우리 집은 <GreenBoldText>깡통전세</GreenBoldText>일까? <br />
              지금 바로 확인해보세요!
            </BlackBoldText>
          </TextContainer>
          <SubTextContainer>
            <BlackBoldText>
              내 보증금의 위험도를 1분 만에 진단해보세요. {" "}
              {isMobile ? <br /> : ""}
              가상 경매, 실시간 등본 발급, 전세보증보험 가입 조건 확인까지 한 번에!
            </BlackBoldText>
          </SubTextContainer>
          <BtnRowContainer>
            <MainPageBtn color="#ffaa00" onClick={goSearchPage}>
              <MainBtnP
                margin={"auto"}
                fontWeight={"550"}
                color="#ffffff"
                fontSize={"120%"}
                fontFamily="NotoSansKR"
                mobileFontSize={"3.3vw"}
              >
                주소검색 시작하기
              </MainBtnP>
            </MainPageBtn>
            <MainPageBtn
              color="#008128"
              borderColor="#008128"
              onClick={goHistoryPage}
            >
              <MainBtnP
                margin={"auto"}
                fontWeight={"550"}
                fontSize={"120%"}
                color="#ffffff"
                fontFamily="NotoSansKR"
                mobileFontSize={"3.3vw"}
              >
                무료 체험해보기
              </MainBtnP>
            </MainPageBtn>
          </BtnRowContainer>
          <NoticeContainer>
            <GrayNoticeP
              margin={"none"}
              fontWeight={"normal"}
              fontSize={"1vw"}
              color="#8e8e93"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3vw"}
            >
              * 본 서비스는 크롬 브라우저에 최적화 되어있습니다.
            </GrayNoticeP>
          </NoticeContainer>
        </FirstRowItem>
      </FirstRowContainer>
      <OtherRowBackground backgroundColor="#ffffff">
        <OtherRowContainer>
          <OtherContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"0%"}
            bottom={"0%"}
            margin={"1vw 0vw 1vw 0vw"} // top right bottom left
            padding={"0%"}
            width={"35vw"}
            height={"15vw"}
          >
            <GrayP
              margin={"0.2%"}
              fontWeight={"bold"}
              fontSize={"1.5vw"}
              color="#ffaa00"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"100%"}
            >
              이런 분들에게 추천합니다!
            </GrayP>{" "}
            <GrayP
              margin={"none"}
              fontWeight={"bold"}
              fontSize={"1.8vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.7vw"}
            >
              전세 보증금을 돌려받지 못할까봐 <br />
              걱정되시는 분
            </GrayP>
            <GrayP
              margin={"none"}
              fontWeight={"550"}
              fontSize={"1.2vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.2vw"}
            >
              “이 집..진짜 괜찮은거 맞을까? <br />
              나중에 내 보증금을 안전하게 돌려받을 수 있을까?”
            </GrayP>
          </OtherContainer>
          <OtherContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"0%"}
            bottom={"-25px"}
            margin={"2vw 0vw 0vw 0vw"}
            padding={"0%"}
            width={"35vw"}
            height={"100%"}
            alignItems={"flex-end"}
          >
            <OtherImg src={tenantIllust} />
          </OtherContainer>
        </OtherRowContainer>
      </OtherRowBackground>
      <OtherRowBackground backgroundColor={"#f3d040"}>
        <OtherRowContainer>
          {!isMobile ? (
            <OtherContainer
              position={"relative"}
              left={"0%"}
              right={"0%"}
              top={"0%"}
              bottom={"-25px"}
              margin={"2vw 0vw 0vw 0vw"}
              padding={"0%"}
              width={"35vw"}
              height={"100%"}
            >
              <OtherImg src={agentIllust} />
            </OtherContainer>
          ) : null}
          <OtherContainer
            position={"relative"}
            left={"0"}
            right={"0%"}
            top={"0%"}
            bottom={"0%"}
            margin={"1vw 0vw 1vw 0vw"} // top right bottom left
            padding={"0%"}
            width={"35vw"}
            height={"15vw"}
            textAlign={"right"}
          >
            <GrayP
              margin={"0.2%"}
              fontWeight={"bold"}
              fontSize={"1.5vw"}
              color="#ffffff"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"100%"}
            >
              이런 분들에게 추천합니다!
            </GrayP>{" "}
            <GrayP
              margin={"none"}
              fontWeight={"bold"}
              fontSize={"1.8vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.7vw"}
            >
              양질의 정보를 제공하여 <br />
              계약 성사율을 높이려는 공인중개사
            </GrayP>
            <GrayP
              margin={"none"}
              fontWeight={"550"}
              fontSize={"1.2vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.2vw"}
            >
              "고객님, 이 매물은 융자가 없어 안전하고, <br />
              보증보험 가입이 가능합니다."
            </GrayP>{" "}
          </OtherContainer>
          {isMobile ? (
            <OtherContainer
              position={"relative"}
              left={"0%"}
              right={"0%"}
              top={"0%"}
              bottom={"-25px"}
              margin={"2vw 0vw 0vw 0vw"}
              padding={"0%"}
              width={"35vw"}
              height={"100%"}
            >
              <OtherImg src={agentIllust} />
            </OtherContainer>
          ) : null}
        </OtherRowContainer>
      </OtherRowBackground>
      <OtherRowBackground backgroundColor={"#ffffff"}>
        <OtherRowContainer>
          <OtherContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"1vw"}
            bottom={"0%"}
            margin={"1vw 0vw 1vw 0vw"} // top right bottom left
            padding={"0%"}
            width={"35vw"}
            height={"15vw"}
          >
            <GrayP
              margin={"0.2%"}
              fontWeight={"bold"}
              fontSize={"1.5vw"}
              color="#ffaa00"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"100%"}
            >
              {isMobile ? <br /> : ""}
              이런 분들에게 추천합니다!
            </GrayP>{" "}
            <GrayP
              margin={"none"}
              fontWeight={"bold"}
              fontSize={"1.8vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.7vw"}
            >
              적정 보증금을 통해 공실 없이 <br />
              세를 놓으려는 전세 임대인
            </GrayP>
            <GrayP
              margin={"none"}
              fontWeight={"550"}
              fontSize={"1.2vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.2vw"}
            >
              "보험, 대출 조건을 만족하도록 세를 놓으면 <br />
              다른 매물들 보다 계약이 잘 되겠지?"
            </GrayP>{" "}
          </OtherContainer>
          <OtherContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"0%"}
            bottom={"0%"}
            margin={"3vh 0vw 0vh"}
            padding={"0%"}
            width={"35vw"}
            height={"0%"}
            alignItems={"flex-end"}
          >
            <OtherImg src={ownerIllust} />
          </OtherContainer>
        </OtherRowContainer>
      </OtherRowBackground>
      <OtherRowBackground backgroundColor={"#ffffff"}>
        <ProductRowContainer>
          {isMobile ? (
            <GrayP
              margin={"none"}
              fontWeight={"550"}
              fontSize={"1.2vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.7vw"}
            >
              등기부등본, 시세, 유형을 조회하고 <br />
              분석 결과를 리포트 형태로 생성해드립니다.
            </GrayP>
          ) : (
            ""
          )}
          <ProductContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"0%"}
            bottom={"0%"}
            margin={"3vh 0vw 0vh"}
            padding={"0%"}
            width={"60%"}
            height={"0%"}
            alignItems={"flex-start"}
          >
            <ProductImg src={reportExampleImg} />
          </ProductContainer>
          <ProductContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"1vw"}
            bottom={"0%"}
            margin={"1vw 0vw 1vw 2vw"} // top right bottom left
            padding={"0%"}
            width={"40%"}
            height={"15vw"}
            alignItems={"flex-start"}
          >
            <GrayP
              margin={"0.2%"}
              fontWeight={"bold"}
              fontSize={"1.5vw"}
              color="#008128"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"150%"}
            >
              {isMobile ? <br /> : ""}
              위험도 분석
            </GrayP>{" "}
            <GrayP
              margin={"none"}
              fontWeight={"bold"}
              fontSize={"1.8vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.7vw"}
            >
              주소 검색 한번으로 {isMobile ? "" : <br />}
              전세금의 위험도를 {isMobile ? <br /> : ""}
              1분 만에 {isMobile ? "" : <br />}
              진단해보세요.
            </GrayP>
            {isMobile ? (
              ""
            ) : (
              <ProductP
                margin={"none"}
                fontWeight={"550"}
                fontSize={"1.2vw"}
                color="#2c2c2e"
                fontFamily="NotoSansCJKkr"
                mobileFontSize={"3.3vw"}
              >
                등기부등본, 시세, 유형을 조회하고 <br />
                분석 결과를 리포트 형태로 생성해드립니다.
              </ProductP>
            )}
          </ProductContainer>
        </ProductRowContainer>
      </OtherRowBackground>
      <OtherRowBackground backgroundColor={"#ffffff"}>
        <ProductRowContainer>
          {isMobile ? (
            <GrayP
              margin={"none"}
              fontWeight={"550"}
              fontSize={"1.2vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.7vw"}
            >
              복잡한 가입 조건들을 확인하고 <br />
              요건을 만족하는지 점검해보세요.
            </GrayP>
          ) : (
            ""
          )}
          <ProductContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"0%"}
            bottom={"0%"}
            margin={"3vh 0vw 0vh"}
            padding={"0%"}
            width={"60%"}
            height={"0%"}
            alignItems={"flex-start"}
          >
            <ProductImg src={insuranceExampleImg} />
          </ProductContainer>
          <ProductContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"1vw"}
            bottom={"0%"}
            margin={"1vw 0vw 1vw 2vw"} // top right bottom left
            padding={"0%"}
            width={"40%"}
            height={"15vw"}
            alignItems={"flex-start"}
          >
            <GrayP
              margin={"0.2%"}
              fontWeight={"bold"}
              fontSize={"1.5vw"}
              color="#008128"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"150%"}
            >
              {isMobile ? <br /> : ""}
              <br />
              <br />
              전세 보증보험
            </GrayP>{" "}
            <GrayP
              margin={"none"}
              fontWeight={"bold"}
              fontSize={"1.8vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"4vw"}
            >
              가입에 필요한 주요 조건을 <br />
              쉽고 편리하게 확인 {isMobile ? "" : ""}할 수 있습니다.{" "}
              {isMobile ? "" : <br />}
            </GrayP>
            {isMobile ? (
              ""
            ) : (
              <ProductP
                margin={"none"}
                fontWeight={"550"}
                fontSize={"1.2vw"}
                color="#2c2c2e"
                fontFamily="NotoSansCJKkr"
                mobileFontSize={"3.7vw"}
              >
                복잡한 가입 조건들을 확인하고 <br />
                요건을 만족하는지 점검해보세요.
              </ProductP>
            )}
          </ProductContainer>
        </ProductRowContainer>
      </OtherRowBackground>
      <OtherRowBackground backgroundColor={"#ffffff"}>
        <ProductRowContainer>
          {isMobile ? (
            <GrayP
              margin={"none"}
              fontWeight={"550"}
              fontSize={"1.0vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"3.7vw"}
            >
              압류, 가처분과 같은 권리 침해 요소 및<br />
              채권 최고액 등을 정리하여 <br />
              알기 쉽게 알려드립니다.
            </GrayP>
          ) : (
            ""
          )}
          <ProductContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"0%"}
            bottom={"0%"}
            margin={"3vh 0vw 0vh"}
            padding={"0%"}
            width={"60%"}
            height={"0%"}
            alignItems={"flex-start"}
          >
            <ProductImg src={example5} />
          </ProductContainer>
          <ProductContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"1vw"}
            bottom={"0%"}
            margin={"1vw 0vw 1vw 2vw"} // top right bottom left
            padding={"0%"}
            width={"40%"}
            height={"15vw"}
            alignItems={"flex-start"}
          >
            <GrayP
              margin={"0.2%"}
              fontWeight={"bold"}
              fontSize={"1.5vw"}
              color="#008128"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"150%"}
            >
              {isMobile ? <br /> : ""}
              <br />
              <br />
              등기부등본
            </GrayP>{" "}
            <GrayP
              margin={"none"}
              fontWeight={"bold"}
              fontSize={"1.8vw"}
              color="#2c2c2e"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"4vw"}
            >
              실시간 발급된 등본을 통해 <br />
              주요 사항을 찾아냅니다.
              {isMobile ? "" : <br />}
            </GrayP>
            {isMobile ? (
              ""
            ) : (
              <ProductP
                margin={"none"}
                fontWeight={"550"}
                fontSize={"1.2vw"}
                color="#2c2c2e"
                fontFamily="NotoSansCJKkr"
                mobileFontSize={"3.7vw"}
              >
                압류, 가처분과 같은 권리 침해 요소 및<br />
                채권 최고액 등을 정리하여 <br />
                알기 쉽게 알려드립니다.
              </ProductP>
            )}
          </ProductContainer>
        </ProductRowContainer>
      </OtherRowBackground>
      <OtherRowBackground backgroundColor={"#ffffff"}>
        <ProductRowContainer>
          <ProductContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"0%"}
            bottom={"0%"}
            margin={"3vh 0vw 0vh"}
            padding={"0%"}
            width={"100%"}
            height={"0%"}
            alignItems={"center"}
          >
            <ProductImg src={bannerModified} />
          </ProductContainer>
        </ProductRowContainer>
      </OtherRowBackground>
      <OtherRowBackground backgroundColor={"#ffffff"}>
        <ProductRowContainer>
          <ProductContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"0%"}
            bottom={"0%"}
            margin={"3vh 0vw 0vh"}
            padding={"0%"}
            width={"100%"}
            height={"0%"}
            alignItems={"center"}
          >
            <ProductImg src={banner2Modified} />
          </ProductContainer>
        </ProductRowContainer>
      </OtherRowBackground>
      <BackgroundRowContainer>
        {/* <BackgroundContainer
          position={"relative"}
          left={"0%"}
          right={"0%"}
          top={"0%"}
          bottom={"-5px"}
          margin={"0vw 0vw 0vw 0vw"}
          padding={"0%"}
          width={"100vw"}
          height={"15vw"}
        >
          <BgrImg src={bgrIllust} /> */}
        {/* </BackgroundContainer> */}
      </BackgroundRowContainer>
      {/* <OtherRowBackground backgroundColor="#2c2c2e">
        <OtherRowContainer>
         <FooterContainer
            position={"relative"}
            left={"0%"}
            right={"0%"}
            top={"0%"}
            bottom={"0%"}
            margin={"0vw 0vw 0vw 0vw"}
            padding={"0"}
            width={"35vw"}
            height={"auto"}
            alignItems={"flex-start"}
          >
            <FooterP
              margin={"1%"}
              fontWeight={"300"}
              fontSize={"0.8vw"}
              color="#a6a6a6"
              fontFamily="NotoSansCJKkr"
              mobileFontSize={"2.8vw"}
            >
              (주) 그레이토터스 <br />
              공동대표이사: 심재균, 육지환 <br />
              사업자 등록번호 488-88-01449 <br />
              특허 번호 (등록): 제 1996797 호 <br />
              상표 번호: 40-2020-0017118 <br />
              유선문의: 070-4793-4825 <br />
              이메일: jaekyun.shim@graytortoise.com <br />
            </FooterP>
          </FooterContainer>
          <FooterContainer
            position={"relative"}
            left={"0px"}
            right={"0%"}
            top={"0px"}
            bottom={"0%"}
            margin={"0vw 0vw 0vw 0vw"}
            padding={"0vw 0vw 0vw 0vw"}
            width={"35vw"}
            height={"6vw"}
            alignItems={"center"}
          >
            <FooterLogoImg src={footerLogoIllust} />
          </FooterContainer>
        </OtherRowContainer>
      </OtherRowBackground> */}
    </BodyWrapper>
  );
};
