import { useEffect, useState } from "react";
import { Path, _client } from "src/apis/client";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "src/hooks/useSelector";
import {
  set_agent_info,
  set_agent_boss_name,
  set_agent_enroll_id,
  set_agent_office_name,
} from "src/services/user/user.actions";
import Modal from "../common/Modal";
import searchIconImg from "src/assets/png/search.png";
import useAgentSearchModal from "./AgentSearchModal.hook";
import {
  TitleDiv,
  OuterDiv,
  InputText,
  SearchButton,
  BtnImg,
  AgentListDiv,
} from "./AgentSearchModal.style";
export interface AgentResponse {
  data: AgentItem[];
  msg: string | null;
  error: string | null;
  error_code: number | null;
}

export interface AgentItem {
  register_name: string;
  office_name: string;
  address: string;
  boss_name: string;
}

export const AgentSearchModal = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState(true);
  const [agentName, setAgentName] = useState("");
  const [agentList, setAgentList] = useState<AgentResponse>();
  const { visible, close_agent_search_modal } = useAgentSearchModal();


  // useEffect(() => {
  //   if (selectedAgent) {
  //     console.log("here1");
  //     console.log(selectedAgent);
  //     setSelectedAgentOfficeName();
  //   }
  // }, [selectedAgent]);

  // useEffect(() => {
  //   console.log("[useEffect]");
  //   console.log(agentList);
  // }, [agentList]);

  useEffect(() => {
    if (agentName.toString().length < 1) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [agentName]);


  const setAgentInfo = (
    bossName: string,
    officeName: string,
    enrollId: string
  ) => {
    if (bossName.length > 1) {
      dispatch(set_agent_info(true))
      dispatch(set_agent_enroll_id(enrollId))
      dispatch(set_agent_boss_name(bossName))
      dispatch(set_agent_office_name(officeName))
      close_agent_search_modal()
    }
  };

  const handleClick = () => {
    if (agentName) {
      console.log("검색 시도" + agentName.toString());
      _client
        .postApi(Path.search_agent_list, { name: agentName })
        .then((e: AgentResponse) => {
          setAgentList(e);
        })
        .catch((err) => {
          console.error(err);
          alert("다음과 같은 이유로 조회에 실패하였습니다: " + err);
        });
    }
  };

  function createAgentList(agentData: AgentResponse | undefined) {
    if (agentData) {
      let AgentList: Array<JSX.Element> = [];

      agentData.data.forEach((value, index, arr) => {
        AgentList.push(
          <AgentListDiv
            key={index}
            onClick={() =>
              setAgentInfo(
                value.boss_name,
                value.office_name,
                value.register_name
              )
            }
            style={{
              boxSizing: "border-box",
              textAlign: "left",
              borderBottom: "1px solid #d1d1d6",
              display: "inline-flex",
              flexDirection: "column",
              width: "100%",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <h1
              style={{
                color: "#333333",
                fontFamily: "NotoSansKR",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              {value.office_name}{" "}
              <span style={{ color: "#757575", height: 9, fontSize: 18 }}>
                ({value.boss_name}){" "}
              </span>
            </h1>{" "}
            <h1
              style={{
                color: "#8e8e93",
                fontFamily: "NotoSansKR",
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {" "}
              {value.address}{" "}
            </h1>
            {/* <br /> {value.address}{" "} */}
          </AgentListDiv>
        );
      });
      return <div>{AgentList}</div>;
    } else {
      return null;
    }
  }

  const isDesktop = useMediaQuery({
    query: "(min-width: 500px)",
  });

  // if (loginStatus === UserLoginStatusType.LOGGEDIN) return null;
  return (
    <>
      <Modal isOpen={visible}>
        <OuterDiv mobile={!isDesktop}>
          <div
            style={{ textAlign: "center", paddingTop: 10, paddingBottom: 10 }}
          >
            <TitleDiv>중개사무소 찾기</TitleDiv>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "left",
                marginTop: 35,
                paddingTop: 10,
                paddingBottom: 10,
                color: "#757575",
                fontWeight: 550,
                border: "2px solid #008128",
                borderRadius: "5px",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  width: "85%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <InputText
                  color={agentName ? "#48484a" : "#d1d1d6"}
                  value={agentName}
                  onChange={(e) => setAgentName(e.target.value)}
                  type="text"
                  placeholder="중개소 이름을 입력해주세요"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleClick();
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "15%",
                }}
              >
                <SearchButton onClick={() => handleClick()}>
                  <BtnImg src={searchIconImg} />
                </SearchButton>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: isDesktop ? "370px" : "280px",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                marginTop: 15,
                marginBottom: 25,
                color: "#757575",
                fontWeight: 550,
                fontSize: 18,
                border: "1px solid #d1d1d6",
                borderRadius: "5px",
                boxSizing: "border-box",
                overflow: "auto",
              }}
            >
              {createAgentList(agentList)}
            </div>
            {/* <button
              style={{
                width: "100%",
                height: 40,
                borderRadius: 5,
                border: 0,
                cursor: "pointer",
                fontSize: 22,
                fontWeight: "bold",
                color: "#fff",
                backgroundColor: buttonDisable ? "#d1d1d6" : "#008128",
              }}
              onClick={close_agent_search_modal}
              disabled={buttonDisable}
            >
              확인
            </button> */}
          </div>
        </OuterDiv>
      </Modal>
    </>
  );
};
