import { useState } from "react";
import { Path, _client } from "src/apis/client";
import { useHistory } from "react-router-dom";
import { UserLoginStatusType } from "src/data/type/status";
import { useSelector } from "src/hooks/useSelector";
import { userJoin } from "src/services/user/user.actions";
import completeImg from "src/assets/png/coplete.png";

import {
  Statement,
  GreenBoldText,
  TermsBox,
  Article,
  ArticleTittle,
  ArticleText,
  PolicyTittle,
  AgreeBtnContainer,
  AgreeBtn,
  SplitContainer,
  HoriLine,
  AgentBtnContainer,
  AgentBtn,
  AgreementContainer,
} from "src/components/registerPage/agreementPage.style";

export const privacyPage = () => {
  return (
    <Article>
      <ArticleText>
        <ArticleTittle>1. 수집하는 개인정보 </ArticleTittle>
        (주) 그레이토터스는 서비스 이용을 위해 필요한 최소한의 개인정보를
        수집합니다. <br />
        회원가입 시점에 (주) 그레이토터스가 이용자로부터 수집하는 개인정보는
        아래와 같습니다.
        <br />
        - 회원 가입 시에 ‘휴대전화번호, 비밀번호, 이름, 이메일 주소’를
        필수항목으로 수집합니다. 만약 이용자가 입력하는 생년월일이 만14세 미만
        아동일 경우에는 법정대리인 정보(법정대리인의 이름, 생년월일, 성별,
        중복가입확인정보(DI), 휴대전화번호)를 추가로 수집합니다.
        <br />
        - 단체아이디로 회원가입 시 단체아이디, 비밀번호, 단체이름, 이메일주소,
        휴대전화번호를 필수항목으로 수집합니다. 그리고 단체 대표자명을
        선택항목으로 수집합니다.
        <br />
        - 사용자의 비밀번호는 복호화가 불가능한 일방향 암호화를 통해 보관 및
        관리됩니다.
        <br />
        - (주) 그레이토터스 내의 개별 서비스 이용, 이벤트 응모 및 경품 신청
        과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수
        있습니다. 추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서
        이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적,
        개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다.
        <br />
        서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보,
        위치정보가 생성되어 수집될 수 있습니다. 또한 이미지 및 음성을 이용한
        검색 서비스 등에서 이미지나 음성이 수집될 수 있습니다.
        <br />
        구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된
        방법으로 생성하여 이를 저장(수집)하거나,
        <br />
        2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게
        변환하여 수집합니다. 서비스 이용 과정에서 위치정보가 수집될 수 있으며,
        <br />
        (주) 그레이토터스에서 제공하는 위치기반 서비스에 대해서는 '(주)
        그레이토터스 위치정보 이용약관'에서 자세하게 규정하고 있습니다.
        <br />
        이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에
        해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.
        <br />
        <br />
        <ArticleTittle>2. 수집한 개인정보의 이용</ArticleTittle>
        (주) 그레이토터스 및 (주) 그레이토터스 관련 제반 서비스(모바일 웹/앱
        포함)의 회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축
        등 아래의 목적으로만 개인정보를 이용합니다.
        <br />
        - 회원 가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 이용자 및
        법정대리인의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를
        위하여 개인정보를 이용합니다.
        <br />
        - 콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석,
        서비스 방문 및 이용기록의 분석, 개인정보 및 관심에 기반한 이용자간
        관계의 형성, 지인 및 관심사 등에 기반한 맞춤형 서비스 제공 등 신규
        서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를
        이용합니다.
        <br />
        - 법령 및 (주) 그레이토터스 이용약관을 위반하는 회원에 대한 이용 제한
        조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는
        행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의
        고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및
        서비스 운영을 위하여 개인정보를 이용합니다.
        <br />
        - 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제, 상품 및
        서비스의 배송을 위하여 개인정보를 이용합니다.
        <br />
        - 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션
        목적으로 개인정보를 이용합니다.
        <br />
        - 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석
        및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
        <br />
        - 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는
        서비스 이용환경 구축을 위해 개인정보를 이용합니다.
        <br />
        <br />
        <ArticleTittle>3. 개인정보의 기술적/관리적 보호 대책</ArticleTittle>
        - 회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출,
        변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
        대책을 강구하고 있습니다. 단, 이용자 본인의 부주의나 인터넷 또는
        통신상의 문제로 아이디, 비밀번호 등 개인정보가 유출되어 발생한 문제에
        대해 회사는 일체의 책임을 지지 않습니다.
        <br />
        - 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며,
        개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만
        가능합니다.
        <br />
        - 회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나
        훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해
        자료를 수시로 백업하고 있고, 최신 보안 업데이트 설치 및 기술적 조치를
        하여 이용자의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고
        있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게
        전송할 수 있도록 하고 있습니다. 그리고 방화벽을 구축하여 외부로부터의
        무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한
        가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
        <br />
        - 회사는 개인정보처리 관련 담당자를 한정시키고 있고 이를 위한 별도의
        비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 정기적
        교육을 통하여 바로바로 개인정보처리방침의 준수를 항상 강조하고 있습니다.
        <ArticleTittle>4. 개인정보의 보관기간</ArticleTittle>
        회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고
        있습니다.
        <br />
        단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는
        법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안
        개인정보를 안전하게 보관합니다.
        <br />
        이용자에게 개인정보 보관기간에 대해 회원가입 시 또는 서비스 가입 시
        동의를 얻은 경우는 아래와 같습니다.
        <br />
        - 부정 가입 및 이용 방지
        <br />
        부정 이용자의 가입인증 휴대전화번호 또는 DI (만14세 미만의 경우
        법정대리인DI) : 탈퇴일로부터 6개월 보관
        <br />
        탈퇴한 이용자의 휴대전화번호(복호화가 불가능한 일방향 암호화(해시처리))
        : 탈퇴일로부터 6개월 보관
        <br />
        전자상거래 등에서의 소비자 보호에 관한 법률, 전자문서 및 전자거래
        기본법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는
        경우는 아래와 같습니다. (주) 그레이토터스는 이 기간 동안 법령의 규정에
        따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지
        않습니다.
        <br />
        - 전자상거래 등에서 소비자 보호에 관한 법률
        <br />
        계약 또는 청약철회 등에 관한 기록: 5년 보관
        <br />
        대금결제 및 재화 등의 공급에 관한 기록: 5년 보관
        <br />
        소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관
        <br />
        - 전자문서 및 전자거래 기본법
        <br />
        공인전자주소를 통한 전자문서 유통에 관한 기록 : 10년 보관
        <br />
        - 전자서명 인증 업무
        <br />
        인증서와 인증 업무에 관한 기록: 인증서 효력 상실일로부터 10년 보관
        <br />
        - 통신비밀보호법
        <br />
        로그인 기록: 3개월
        <br />
        참고로 (주) 그레이토터스는 ‘개인정보 유효기간제’에 따라 1년간 서비스를
        이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.
        <br />
        <br />
        <ArticleTittle>
          5. 개인정보 수집 및 이용 동의를 거부할 권리
        </ArticleTittle>
        이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입
        시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용 동의를
        거부하실 경우, 회원가입이 어려울 수 있습니다.
        <br />
      </ArticleText>
    </Article>
  );
};
