class SearchStatus {
  private _searchStatus: boolean | undefined;

  constructor(status: boolean | undefined) {
    this._searchStatus = status;
  }

  /**
   * Getter searchStatus
   * @return {boolean | undefined}
   */
  public get searchStatus(): boolean | undefined {
    return this._searchStatus;
  }

  /**
   * Setter resultCode
   * @param {boolean | undefined} value
   */
  public set searchStatus(value: boolean | undefined) {
    this._searchStatus = value;
  }
}

export default SearchStatus;
