import { useState } from "react";
import { Path, _client } from "src/apis/client";
import { useHistory } from "react-router-dom";
import { UserLoginStatusType } from "src/data/type/status";
import { useSelector } from "src/hooks/useSelector";
import { userJoin } from "src/services/user/user.actions";
import completeImg from "src/assets/png/coplete.png";

import {
  ChildForm,
  LineDiv,
  Background,
  Inner,
  StepDiv,
  StepWrapperDiv,
  TitleDiv,
  ContentDiv,
  ProgressItem,
  ProgressIcon,
  CompleteIcon,
  CompleteItem,
  CompleteBtn,
  CompleteBtnContainer,
  Statement,
  PhoneNumberInputContainer,
  PhoneNumberInput,
  VerifyBtn,
  Comment,
  FailComment,
} from "./findPassword.Style";
import useLoginModal from "../loginModal/LoginModal.hook";

export interface RegformProp {
  setStep(step: number): void;
  setFormData(form: any): void;
  formData: userJoin;
}

export const findPassword = () => {
  const history = useHistory();
  const login_status = useSelector((state) =>
    state.userReducer.get_login_status()
  );
  if (login_status === UserLoginStatusType.LOGGEDIN) {
    history.push("/");
    return null;
  }
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [codeHasBeenSent, setCodeHasBeenSent] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const [phone, setPhone] = useState("");
  const onChangePhoneNumber = (e: string) => {
    setPhone(e);
  };
  const clickHandlerCreate = () => {
    // let regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    let regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    setValidPhoneNumber(regExp.test(phone));

    setCodeHasBeenSent(true);

    if (regExp.test(phone)) {
      _client
        .postApi(Path.reset_password, {phone: phone})
        .catch((e) => {
          setIsButtonActive(false);
          alert(`다음과 같은 이유로 비밀번호 초기화에 실패하였습니다: ${e}`);
          setCodeHasBeenSent(false);
        })
        .then(() => setIsButtonActive(true));
    }
  };

  const { open_login_modal } = useLoginModal();
  const goMainPage = () => {
    try {
      open_login_modal();
    } catch (e) {
      console.error(`로그인 모달 열기 실패, 원인: ${e}`);
    }
  };

  return (
    <Background>
      <Inner>
        <CompleteItem>
          <TitleDiv>임시 비밀번호 발급</TitleDiv>
        </CompleteItem>
        <ContentDiv>
          {" "}
          입력하신 계정 (휴대폰 번호)의 비밀번호를 초기화 합니다. <br /> 발급된
          임시 비밀번호로 로그인 하신 후 비밀번호를 변경해주세요.
        </ContentDiv>
        <Statement>{"  "}휴대폰번호 </Statement>
        {/* <PhoneNumberInputContainer active={true} finished={verifySuccess}> */}
        <PhoneNumberInputContainer active={true} finished={false}>
          <PhoneNumberInput
            type="number"
            value={phone}
            disabled={false}
            onChange={(e) => onChangePhoneNumber(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                clickHandlerCreate();
              }
            }}
            placeholder="번호를 입력하세요"
          />
          <VerifyBtn
            className="VerifyBtn"
            disabled={false}
            //   disabled={!validPhoneNumber || isButtonActive}
            onClick={() => clickHandlerCreate()}
          >
            {" "}
            임시 비밀번호 발급{" "}
          </VerifyBtn>
        </PhoneNumberInputContainer>
        <Comment active={codeHasBeenSent && validPhoneNumber}>
          {" "}
          * 해당 휴대폰번호로 변경된 비밀번호를 전송했습니다. 문자를
          확인해주시기 바랍니다.{" "}
        </Comment>
        <FailComment active={codeHasBeenSent && !validPhoneNumber}>
          {" "}
          * 올바른 휴대폰 번호를 입력해주세요.{" "}
        </FailComment>
        <FailComment active={!codeHasBeenSent}></FailComment>
        <CompleteBtnContainer>
          <CompleteBtn onClick={() => goMainPage()}>로그인하기</CompleteBtn>
        </CompleteBtnContainer>
      </Inner>
    </Background>
  );
};
