import { useState } from "react";
import ringLoadingGif from "src/assets/gif/ring-loading.gif";
import { Path, _client } from "src/apis/client";
import { useHistory } from "react-router-dom";
import { UserLoginStatusType } from "src/data/type/status";
import { useSelector } from "src/hooks/useSelector";
import { userJoin } from "src/services/user/user.actions";
import completeImg from "src/assets/png/coplete.png";

import {
  ChildForm,
  LineDiv,
  Background,
  Inner,
  StepDiv,
  StepWrapperDiv,
  TitleDiv,
  ContentDiv,
  ProgressItem,
  ProgressIcon,
  CompleteIcon,
  CompleteItem,
  CompleteBtn,
  CompleteBtnContainer,
  Statement,
  PhoneNumberInputContainer,
  PhoneNumberInput,
  VerifyBtn,
  Comment,
  FailComment,
} from "./resetPassword.Style";
import useLoginModal from "../loginModal/LoginModal.hook";

export interface RegformProp {
  setStep(step: number): void;
  setFormData(form: any): void;
  formData: userJoin;
}

export const resetPassword = () => {
  const history = useHistory();

  const { loginStatus, user, point, phoneNumber } = useSelector((state) => {
    return {
      loginStatus: state.userReducer.get_login_status(),
      user: state.userReducer.get_user(),
      point: state.userReducer.get_user()?.point,
      phoneNumber: state.userReducer.get_user()?.phone,
    };
  });

  const [resultStatus, setResultStatus] = useState("false");
  const [oldPw, setOldPw] = useState("");
  const [newPw, setNewPw] = useState("");
  const [newConfirmPw, setNewConfirmPw] = useState("");

  const clickHandlerCreate = () => {
    if (newPw.toString().length < 8) {
      alert("비밀번호가 너무 짧습니다.");
    } else if (newPw !== newConfirmPw) {
      alert("신규 비밀번호 2개가 일치하지 않습니다.");
    } else if (oldPw === newPw) {
      alert("동일한 비밀번호 입니다.");
    } else {
      setResultStatus("loading");
      _client
        .putApiWithTokenV2(Path.update_password, {
          old_pw: oldPw,
          new_pw: newPw,
          phone: phoneNumber,
        })
        .then(() => setResultStatus("true"))
        .catch((response) => {
          if (response.status === 401) {
            alert("기존 비밀번호가 일치하지 않습니다.");
            setResultStatus("false");
          } else {
            alert("가입에 실패하였습니다: " + response.statusText);
            setResultStatus("false");
          }
        });
    }
  };

  const goMainPage = () => {
    history.push("/");
  };

  return (
    <Background>
      <Inner>
        <CompleteItem>
          <TitleDiv>비밀번호 재설정</TitleDiv>
        </CompleteItem>
        <br />
        <Statement>{"  "}기존 비밀번호 </Statement>
        <PhoneNumberInputContainer active={true} finished={false}>
          <PhoneNumberInput
            type="password"
            value={oldPw}
            disabled={false}
            onChange={(e) => setOldPw(e.target.value)}
            placeholder="현재 비밀번호를 입력하세요"
          />
        </PhoneNumberInputContainer>
        <br />
        <Statement>{"  "}신규 비밀번호 </Statement>
        <PhoneNumberInputContainer active={true} finished={false}>
          <PhoneNumberInput
            type="password"
            value={newPw}
            disabled={false}
            onChange={(e) => setNewPw(e.target.value)}
            placeholder="신규 비밀번호를 입력하세요"
          />
        </PhoneNumberInputContainer>
        <br />
        <Statement>{"  "}신규 비밀번호 확인</Statement>
        <PhoneNumberInputContainer active={true} finished={false}>
          <PhoneNumberInput
            type="password"
            value={newConfirmPw}
            disabled={false}
            onChange={(e) => setNewConfirmPw(e.target.value)}
            placeholder="신규 비밀번호를 한번 더 입력하세요"
          />
        </PhoneNumberInputContainer>
        <Comment active={resultStatus === "loading" ? true : false}>
          <img
            src={ringLoadingGif}
            style={{ display: resultStatus === "loading" ? "block" : "none" }}
            width="5%"
          />
          {" "} 요청중입니다..
        </Comment>
        <Comment active={resultStatus === "true" ? true : false}>
          비밀번호 변경이 완료되었습니다.
        </Comment>
        <br />
        <CompleteBtnContainer active={true}>
          <CompleteBtn onClick={() => clickHandlerCreate()}>
            비밀번호 변경하기
          </CompleteBtn>
        </CompleteBtnContainer>
        <br />
        <CompleteBtnContainer active={resultStatus === "true" ? true : false}>
          <CompleteBtn onClick={() => goMainPage()}>검색 바로가기</CompleteBtn>
        </CompleteBtnContainer>
      </Inner>
    </Background>
  );
};
