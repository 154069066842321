import { UiComponentType } from "../type/ui";
import { immerable } from "immer";

export default class UiComponent {
    
    [immerable] = true
    private _id: UiComponentType;
    private _visible: boolean;
    private _clicked: boolean;

	constructor(id: UiComponentType, visible = false, clicked = false) {
		this._id = id;
        this._visible = visible;
        this._clicked = clicked;
	}

    /**
     * Getter id
     * @return {string}
     */
	public get id(): UiComponentType {
		return this._id;
	}

    /**
     * Getter visible
     * @return {boolean }
     */
	public get visible(): boolean  {
		return this._visible;
	}

    /**
     * Getter clicked
     * @return {boolean }
     */
	public get clicked(): boolean  {
		return this._clicked;
	}

    /**
     * Setter id
     * @param {UiComponentType} value
     */
	public set id(value: UiComponentType) {
		this._id = value;
	}

    /**
     * Setter visible
     * @param {boolean } value
     */
	public set visible(value: boolean ) {
		this._visible = value;
	}

    /**
     * Setter clicked
     * @param {boolean } value
     */
	public set clicked(value: boolean ) {
		this._clicked = value;
	}

    public equals(compareTo: UiComponent): boolean {
        return this._id === compareTo._id;
    }

}