import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "src/hooks/useSelector";
import {
  ConclusionType,
  GenReportStatusType,
  OpenHistoryStatus,
  ResultPartType,
} from "src/data/type/search";
import { AnbuData } from "src/data/api-response/anbu";
import { UserLoginStatusType } from "src/data/type/status";
import {
  generateAnbuReport,
  generateFreeAnbuReport,
} from "src/services/anbu/anbu.actions";
import {
  getAnbuHistory,
  getFreeAnbuHistory,
} from "src/services/anbu/anbu.actions";
import {
  setCurrentTab,
  setAnbuHistoryShowingData,
  setToggleStateById,
  setAgentContractById,
  setOver1YearById,
  setLoanById,
  HistoryCardStringState,
  setDepositById,
  setPriorDepositById,
  setAuctionRatioById,
  setPriorDebtById,
  HistoryCardNumberState,
  setOpenHistoryStatus,
  setAnbuReportGenerateStateById,
  setOfficetelTypeById,
  setCommentToggleStateById,
  setNoticeToggleStateById,
  setReportUrlById,
  setConclusionTypeById,
  setResultPartStateById,
  HistoryCardConclusionTypeState,
  HistoryCardResultTypeState,
  upcountGenerateStateById,
  setGuideToggleStateById,
} from "src/services/search/search.actions";
import { LoadingImgContainer } from "../searchPage/searchPageStyleTab";
import { result } from "lodash";

interface TimerInterface {
  _showingDataKey: number;
  _timerID: number;
}

export interface ShowingData {
  key: number;
  houseType: string;
  deposit: number;
  priorTenantDept: number;
  isAgentContract: boolean;
  isLongerThan1Year: boolean;
  isLoan: boolean;
  housePrice: number;
  auctionRatio: string;
  irosPriorDept: number;
  isOfficetelForHouse: boolean;
  isFolded: boolean;
  isCommentFolded: boolean;
  isNoticeFolded: boolean;
  irosIssueState: boolean;
  bldInfoIssueState: boolean;
  isDasaedae: boolean;
  fullAddr: string;
  irosUniqueNumber: string;
  generateState: string;
  reportUrl: string;
  conclusionType: ConclusionType;
  resultPartState: ResultPartType;
  timerId: number;
  isGuideFolded: boolean;
}

export const progressState: string[] = [
  "인터넷등기소 접수중.. (1/10)",
  "인터넷등기소 접수중.. (1/10)",
  "인터넷등기소 접수중.. (1/10)",
  "인터넷등기소 접수중.. (1/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 발급중.. (2/10)",
  "등기부등본 업로드중.. (3/10)",
  "등기부등본 업로드중.. (3/10)",
  "등기부등본 업로드중.. (3/10)",
  "등기부등본 업로드중.. (3/10)",
  "소유주 확인중.. (4/10)",
  "압류 여부 확인중.. (5/10)",
  "근저당액 확인중.. (6/10)",
  "근저당액 확인중.. (6/10)",
  "선순위채권 확인중.. (7/10)",
  "선순위채권 확인중.. (7/10)",
  "낙찰가율 계산중.. (8/10)",
  "낙찰가율 계산중.. (8/10)",
  "낙찰가율 계산중.. (8/10)",
  "낙찰가율 계산중.. (8/10)",
  "예상 배당금 계산중.. (9/10)",
  "예상 배당금 계산중.. (9/10)",
  "예상 배당금 계산중.. (9/10)",
  "예상 배당금 계산중.. (9/10)",
  "분석 결과 확인중.. (10/10)",
  "분석 결과 확인중.. (10/10)",
  "리포트 생성중...",
  "결과리포트 다시 생성",
  "결과리포트 생성하기",
];
export const resultProgressLength = 3;

export default function useOpenHistory() {
  const dispatch = useDispatch();
  // const [openHistoryStatus, setOpenHistoryStatus] = useState<OpenHistoryStatus>(
  //   OpenHistoryStatus.HistoryMoreThanOne
  // );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentCardId, setCurrentCardId] = useState<number>(-2);
  const [currentAnbuUrl, setCurrentAnbuUrl] = useState<string>("");

  const allAnbuHistory = useSelector((state) =>
    state.anbuReducer.get_anbu_history()
  );

  const openHistoryStatus = useSelector((state) =>
    state.searchReducer.get_open_history_status()
  );

  const genReportResponseValue = useSelector((state) =>
    state.anbuReducer.get_gen_report_response()
  );

  const showingData = useSelector((state) =>
    state.searchReducer.get_anbu_history_showing_data()
  );

  const { loginStatus, user, point, phoneNumber, userName } = useSelector(
    (state) => {
      return {
        loginStatus: state.userReducer.get_login_status(),
        user: state.userReducer.get_user(),
        point: state.userReducer.get_user()?.point,
        phoneNumber: state.userReducer.get_user()?.phone,
        userName: state.userReducer.get_user()?.full_name,
      };
    }
  );

  useEffect(() => {
    if (allAnbuHistory) {
      if (allAnbuHistory.anbuData?.length < 1) {
        dispatch(setOpenHistoryStatus(OpenHistoryStatus.HistoryZero));
        setTotalCount(0);
      } else if (Number(allAnbuHistory.anbuData?.length) >= 1) {
        let dataLen: number = allAnbuHistory.anbuData.length;
        dispatch(setOpenHistoryStatus(OpenHistoryStatus.HistoryMoreThanOne));
        setTotalCount(dataLen);
        if (dataLen > 0) {
          let bufArr: ShowingData[] = [];

          allAnbuHistory.anbuData.forEach((e, idx) => {
            // 기존에 없던 ID이면 새로 생성된것이므로 defaultFoldState = False (펼침상태)
            let i = showingData.findIndex((obj) => obj.key === e.id);
            if (i >= 0) {
              let _generateState = showingData[i].generateState;
              if (e.iros_issue === true && e.bld_info_issue === true) {
                if (showingData[i].resultPartState === ResultPartType.Done) {
                  _generateState = (progressState.length - 2).toString(); //리포트 다시생성
                } else {
                  _generateState = (progressState.length - 1).toString(); //리포트 생성하기
                }
              }
              let value = {
                key: e.id,
                houseType: e.summary_bld_type,
                deposit: showingData[i].deposit,
                isAgentContract: showingData[i].isAgentContract,
                isLongerThan1Year: showingData[i].isLongerThan1Year,
                isLoan: showingData[i].isLoan,
                housePrice: Number(e.summary_house_price),
                auctionRatio: e.auction_ratio,
                irosPriorDept: Number(e.debt_iros),
                priorTenantDept: Number(e.debt_prior_tenant),
                isOfficetelForHouse: showingData[i].isOfficetelForHouse,
                isFolded: showingData[i].isFolded,
                isCommentFolded: showingData[i].isCommentFolded,
                isNoticeFolded: showingData[i].isNoticeFolded,
                isGuideFolded: showingData[i].isGuideFolded,
                irosIssueState: e.iros_issue,
                bldInfoIssueState: e.bld_info_issue,
                fullAddr: e.summary_addr,
                generateState: _generateState,
                irosUniqueNumber: e.iros_unique_number,
                reportUrl: showingData[i].reportUrl,
                resultPartState: showingData[i].resultPartState,
                conclusionType: showingData[i].conclusionType,
                timerId: showingData[i].timerId,
                isDasaedae: showingData[i].isDasaedae
              } as ShowingData;
              bufArr.push(value);
            } else {
              let _generateState = "1";
              if (e.iros_issue === true && e.bld_info_issue === true) {
                _generateState = (progressState.length - 1).toString();
              }
              let _defaultResultPartState = ResultPartType.None;
              let _defaultConclusionType = ConclusionType.None;
              let _reportUrl = "";
              if (e.result_category !== null) {
                // console.log("this one is not null")
                // console.log(e.result_category)
                _defaultResultPartState = ResultPartType.Done;
                if (e.anbu_url !== null) {
                  _reportUrl = e.anbu_url;
                }
                if (e.result_category == "안전") {
                  _defaultConclusionType = ConclusionType.Safe;
                } else if (e.result_category == "보완") {
                  _defaultConclusionType = ConclusionType.SafeEquip;
                } else if (e.result_category == "주의") {
                  _defaultConclusionType = ConclusionType.WatchOut;
                } else if (e.result_category == "위험") {
                  _defaultConclusionType = ConclusionType.Danger;
                } else {
                  _defaultConclusionType = ConclusionType.None;
                }
              }
              let value = {
                key: e.id,
                houseType: e.summary_bld_type,
                deposit: Number(e.deposit),
                isAgentContract: true,
                isLongerThan1Year: true,
                isLoan: false,
                housePrice: Number(e.summary_house_price),
                auctionRatio: e.auction_ratio,
                irosPriorDept: Number(e.debt_iros),
                priorTenantDept: Number(e.debt_prior_tenant),
                isOfficetelForHouse: true,
                isFolded: false,
                isCommentFolded: true,
                isNoticeFolded: true,
                isGuideFolded: false,
                irosIssueState: e.iros_issue,
                bldInfoIssueState: e.bld_info_issue,
                fullAddr: e.summary_addr,
                irosUniqueNumber: e.iros_unique_number,
                generateState: _generateState,
                reportUrl: _reportUrl,
                resultPartState: _defaultResultPartState,
                conclusionType: _defaultConclusionType,
                isDasaedae: e.is_dasaedae,
                timerId: -1,
              } as ShowingData;
              bufArr.push(value);
            }
          });
          dispatch(setAnbuHistoryShowingData(bufArr));
          // setShowingData(bufArr);
        }
      } else if (loginStatus !== UserLoginStatusType.LOGGEDIN) {
        dispatch(setOpenHistoryStatus(OpenHistoryStatus.NotLogged));
      }
    } // 아래 주석처리함, 가끔 undefined 되더라도 0으로 세팅하지는 말기
    // } else {
    //   dispatch(setOpenHistoryStatus(OpenHistoryStatus.HistoryZero));
    //   setTotalCount(0);
    // }
  }, [allAnbuHistory, user]);

  function deleteCard(id: number): void {
    console.log("[deleteCard] " + id.toString() + " will be removed");
    // dispatch()
  }

  function exportCard(id: number): void {
    // console.log("[exportCard] " + id.toString() + " will be exported");
    let targetOption = showingData?.find((e) => e.key === id);
    if (targetOption) {
      if (targetOption.reportUrl?.length < 10) {
        alert("결과리포트 생성하기 버튼을 먼저 클릭해주세요.");
      } else {
        // navigator.clipboard.writeText(targetOption.reportUrl)
        const textField = document.createElement("textarea");
        textField.innerText = targetOption.reportUrl;
        alert(
          "결과리포트 URL이 성공적으로 클립보드에 복사되었습니다. 공유를 원하는 곳에 붙여넣기 해주세요."
        );
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
      }
    } else {
      alert("결과리포트 생성하기 버튼을 먼저 클릭해주세요.");
    }
  }

  function refreshAllCards(): void {
    if (loginStatus === UserLoginStatusType.LOGGEDIN) {
      if (phoneNumber) {
        dispatch(
          getAnbuHistory({
            user_phone: phoneNumber,
          })
        );
      } else {
        console.log(
          "[openHistory.hooks.ts][refreshAllCards] user phone number is undefined"
        );
      }
    } else {
      dispatch(getFreeAnbuHistory());
    }
  }

  function updateDepositById(id: number, userDeposit: string) {
    let _a = Number(userDeposit.replace(/\D/g, ""));

    let depositValue: HistoryCardNumberState = {
      id: id,
      value: _a,
    };
    dispatch(setDepositById(depositValue));
  }

  function updatePriorDepositById(id: number, userPriorDeposit: string) {
    let _a = Number(userPriorDeposit.replace(/\D/g, ""));

    let priorDepositValue: HistoryCardNumberState = {
      id: id,
      value: _a,
    };
    dispatch(setPriorDepositById(priorDepositValue));
  }

  function updateAuctionRatioById(id: number, userAuctionRatio: string) {
    let _a = Number(userAuctionRatio.replace(/\D/g, ""));
    let auctionRatioValue: HistoryCardNumberState = {
      id: id,
      value: _a,
    };
    dispatch(setAuctionRatioById(auctionRatioValue));
  }

  function updatePriorDebtById(id: number, userPriorDebt: string) {
    let _a = Number(userPriorDebt.replace(/\D/g, ""));

    let priorDebtValue: HistoryCardNumberState = {
      id: id,
      value: _a,
    };
    dispatch(setPriorDebtById(priorDebtValue));
  }


  function toggleFoldStateById(id: number) {
    dispatch(setToggleStateById(id));
  }

  function toggleGuideStateById(id: number) {
    dispatch(setGuideToggleStateById(id));
  }

  function toggleCommentFoldStateById(id: number) {
    dispatch(setCommentToggleStateById(id));
  }

  function toggleNoticeFoldStateById(id: number) {
    dispatch(setNoticeToggleStateById(id));
  }

  function setAgentContract(id: number, value: boolean) {
    dispatch(setAgentContractById({ id, value }));
  }

  function setOver1Year(id: number, value: boolean) {
    dispatch(setOver1YearById({ id, value }));
  }

  function setLoan(id: number, value: boolean) {
    dispatch(setLoanById({ id, value }));
  }

  function setOfficetelType(id: number, value: boolean) {
    dispatch(setOfficetelTypeById({ id, value }));
  }

  function convNullToUndefined(obj: any) {
    if (obj === null || obj === undefined) {
      return undefined as any;
    } else {
      return obj;
    }
  }

  function openIrosCopyUrl(id: number) {
    if (allAnbuHistory) {
      let idx = allAnbuHistory.anbuData.findIndex((obj) => obj.id === id);
      let _url = convNullToUndefined(
        allAnbuHistory.anbuData[idx].iros_copy_url
      );
      if (_url) {
        window.open(_url);
      } else {
        alert("유효하지 않은 URL 입니다.");
      }
    }
  }

  function openBldCopyUrl(id: number) {
    if (allAnbuHistory) {
      let idx = allAnbuHistory.anbuData.findIndex((obj) => obj.id === id);
      let _url = convNullToUndefined(
        allAnbuHistory.anbuData[idx].bld_info_copy_url
      );
      if (_url) {
        window.open(_url);
      } else {
        alert("유효하지 않은 URL 입니다.");
      }
    }
  }

  // Showing Data에 변화가 생길 경우 조회가 진행중인 녀석을 찾아 Timer 등록
  const [currentTimerKeys, setCurrentTimerKeys] = useState<number[]>([]); // 조회내역 고유번호 = 타이머 키
  const [currentTimerIDs, setCurrentTimerIDs] = useState<TimerInterface[]>([]); // 현재 수행중인 타이머 아이디들
  useEffect(() => {
    if (showingData.length > 0) {
      showingData.forEach((e) => {
        if (Number(e.generateState) < 2) {
          // console.log(
          //   "Enroll new timer, key:" +
          //     e.key.toString() +
          //     ", value: " +
          //     e.generateState
          // );
          let is_dup = currentTimerKeys.find((a) => a === e.key);
          if (is_dup === undefined || is_dup === null) {
            currentTimerKeys.push(e.key);
            setCurrentTimerKeys(currentTimerKeys);

            const timer = setInterval((args) => {
              UpcountProgress(e.key);
            }, 3500);
            let _buf: TimerInterface = {
              _showingDataKey: e.key,
              _timerID: timer,
            };
            currentTimerIDs.push(_buf);
            setCurrentTimerIDs(currentTimerIDs);
            // console.log(
            //   "신규 타이머 등록, key: " +
            //     e.key.toString() +
            //     ", Timer ID: " +
            //     timer.toString()
            // );
            return () => {
              clearInterval(timer);
              let _buf: TimerInterface = {
                _showingDataKey: e.key,
                _timerID: timer,
              };
              const index = currentTimerIDs.indexOf(_buf, 0);
              if (index > -1) {
                currentTimerIDs.splice(index, 1);
                setCurrentTimerIDs(currentTimerIDs);
              }
            };
          }
        }
      });
    }
  }, [showingData.length]);

  function UpcountProgress(id: number) {
    let targetOption = showingData?.find((e) => e.key === id);
    if (targetOption) {
      dispatch(upcountGenerateStateById(id));
    }
    refreshAllCards();
  }

  // 시간이 도달한 경우 or 발급 완료된 경우 타이머에서 삭제
  useEffect(() => {
    if (showingData.length > 0) {
      showingData.forEach((e) => {
        let _remove_condition = false;
        // if (e.irosIssueState === true && e.bldInfoIssueState === true) {
        //   _remove_condition = true;
        // }
        // if (
        //   Number(e.generateState) >
        //     progressState.length - resultProgressLength - 1 ||
        //   _remove_condition
        // ) {
        if (Number(e.generateState) >= progressState.length - 1) {
          let _remove_target = currentTimerIDs.find(
            (val) => val._showingDataKey === e.key
          );
          if (_remove_target) {
            // console.log(
            //   "Remove timer, key:" +
            //     e.key.toString() +
            //     ", value: " +
            //     e.generateState
            // );
            const index = currentTimerIDs.indexOf(_remove_target, 0);
            if (index > -1) {
              currentTimerIDs.splice(index, 1);
              setCurrentTimerIDs(currentTimerIDs);
              clearInterval(_remove_target._timerID);
              let _msg =
                userName +
                "님께서 요청하신 " +
                e.fullAddr +
                " 조회가 완료 되었습니다. 보증금을 입력하신 후 결과 리포트를 생성해보세요!";
              alert(_msg);
            }
          }
        }
      });
    }
  }, [showingData]);

  function generateReport(id: number) {
    let targetOption = showingData?.find((e) => e.key === id);
    let targetData = allAnbuHistory?.anbuData.find((e) => e.id === id);
    if (targetOption && targetData) {
      // dispatch(updateGenReportStatus(GenReportStatusType.Loading));
      if (targetOption.deposit < 1) {
        alert("보증금을 입력하세요");
        if (targetOption.resultPartState === ResultPartType.Done) {
          let buf: HistoryCardStringState = {
            id: id,
            value: (progressState.length - 2).toString(), // 다시생성하기 문구로 변환
          };
          dispatch(setAnbuReportGenerateStateById(buf));
        } else {
          let buf: HistoryCardStringState = {
            id: id,
            value: (progressState.length - 1).toString(), // 최초 생성하기 문구로 변환
          };
          dispatch(setAnbuReportGenerateStateById(buf));
        }
        // dispatch(updateGenReportStatus(GenReportStatusType.Initial));
      } else if (targetOption.deposit > 99900000000) {
        alert("입력 불가능한 단위입니다.");
        if (targetOption.resultPartState === ResultPartType.Done) {
          let buf: HistoryCardStringState = {
            id: id,
            value: (progressState.length - 2).toString(), // 다시생성하기 문구로 변환
          };
          dispatch(setAnbuReportGenerateStateById(buf));
        } else {
          let buf: HistoryCardStringState = {
            id: id,
            value: (progressState.length - 1).toString(), // 최초 생성하기 문구로 변환
          };
          dispatch(setAnbuReportGenerateStateById(buf));
        }
        // dispatch(updateGenReportStatus(GenReportStatusType.Initial));
      } else {
        let buf: HistoryCardStringState = {
          id: id,
          value: (progressState.length - 3).toString(), // Loading 문구로 변환
        };
        setCurrentCardId(id);
        dispatch(setAnbuReportGenerateStateById(buf));

        // Update 하단 상태
        let buf4: HistoryCardResultTypeState = {
          id: id,
          value: ResultPartType.Loading,
        };
        dispatch(setResultPartStateById(buf4));

        if (loginStatus === UserLoginStatusType.LOGGEDIN) {
          dispatch(
            generateAnbuReport({
              report_id: id,
              deposit: targetOption.deposit.toString(),
              is_agent_contract: targetOption.isAgentContract,
              is_contract_longer_than_1_year: targetOption.isLongerThan1Year,
              is_borrow_money_from_bank: targetOption.isLoan,
              user_input_house_price: targetOption.housePrice.toString(),
              user_input_auction_ratio: targetOption.auctionRatio,
              user_input_iros_debt: targetOption.irosPriorDept.toString(),
              user_input_prior_deposit: targetOption.priorTenantDept.toString(),
              user_input_house_type: targetOption.houseType,
              user_input_officetel_type: targetOption.isOfficetelForHouse,
            })
          );
        } else {
          dispatch(
            generateFreeAnbuReport({
              report_id: id,
              deposit: targetOption.deposit.toString(),
              is_agent_contract: targetOption.isAgentContract,
              is_contract_longer_than_1_year: targetOption.isLongerThan1Year,
              is_borrow_money_from_bank: targetOption.isLoan,
              user_input_house_price: targetOption.housePrice.toString(),
              user_input_auction_ratio: targetOption.auctionRatio,
              user_input_iros_debt: targetOption.irosPriorDept.toString(),
              user_input_prior_deposit: targetOption.priorTenantDept.toString(),
              user_input_house_type: targetOption.houseType,
              user_input_officetel_type: targetOption.isOfficetelForHouse,
            })
          );
        }
      }
    }
  }

  useEffect(() => {
    if (genReportResponseValue) {
      //Client Error
      if (genReportResponseValue.error_code === 400) {
        alert(genReportResponseValue.error);
      } else if (genReportResponseValue.data?.url === undefined) {
        alert("유효하지 않은 URL 입니다.");
      } else {
        if (currentCardId && currentCardId > -999) {
          let buf: HistoryCardStringState = {
            id: currentCardId,
            value: (progressState.length - 2).toString(),
          };
          // 생성버튼은 원래대로 원복
          dispatch(setAnbuReportGenerateStateById(buf));

          // Update URL
          let buf2: HistoryCardStringState = {
            id: currentCardId,
            value: genReportResponseValue.data.url,
          };
          // console.log(buf2)
          dispatch(setReportUrlById(buf2));

          // Update 결론
          let conclusion: ConclusionType;
          let _result_type_string = genReportResponseValue.data.result_type;
          if (_result_type_string == "안전") {
            conclusion = ConclusionType.Safe;
          } else if (_result_type_string == "보완") {
            conclusion = ConclusionType.SafeEquip;
          } else if (_result_type_string == "주의") {
            conclusion = ConclusionType.WatchOut;
          } else if (_result_type_string == "위험") {
            conclusion = ConclusionType.Danger;
          } else {
            conclusion = ConclusionType.None;
          }
          let buf3: HistoryCardConclusionTypeState = {
            id: currentCardId,
            value: conclusion,
          };
          dispatch(setConclusionTypeById(buf3));

          // Update 하단 상태
          let buf4: HistoryCardResultTypeState = {
            id: currentCardId,
            value: ResultPartType.Done,
          };
          dispatch(setResultPartStateById(buf4));
        }
      }
    }
  }, [genReportResponseValue]);

  function openAnbuyUrl(id: number) {
    let targetOption = showingData?.find((e) => e.key === id);
    if (targetOption) {
      if (targetOption.reportUrl) {
        window.open(targetOption.reportUrl);
      }
    } else {
      alert("유효하지 않은 URL 입니다.");
    }
  }

  return {
    currentPage,
    setCurrentPage,
    totalCount,
    openHistoryStatus,
    showingData,
    exportCard,
    refreshAllCards,
    updateDepositById,
    updatePriorDepositById,
    updateAuctionRatioById,
    updatePriorDebtById,
    toggleFoldStateById,
    toggleCommentFoldStateById,
    toggleNoticeFoldStateById,
    toggleGuideStateById,
    setAgentContract,
    setOver1Year,
    setLoan,
    setOfficetelType,
    generateReport,
    openIrosCopyUrl,
    openBldCopyUrl,
    openAnbuyUrl,
    currentAnbuUrl,
  };
}
