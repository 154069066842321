import styled from "styled-components";

export const BtnRow = styled.div`
  margin-top: 5%;
  padding: 3.5% 5% 3.5% 5%;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 0.9px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  background-color: #008128;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const BldInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: -0.28px;
  text-align: center;
  color: #48484a;

  border-radius: 5px;
  background-color: #f4f4f5;

  @media (max-width: 500px) {
    padding-top: 5%;
  }
`;

export const BldInfoItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 3%;
  box-sizing: border-box;
  font-family: NotoSansCJKkr;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  text-align: left;
  color: #747474;
`;

export const InfoTittle = styled.div`
  width: 40%;
  box-sizing: border-box;
  padding: 4%;
  font-family: NotoSansCJKkr;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  text-align: left;
  color: #747474;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 60%;
  border-bottom: solid 1px #747474;;
  box-sizing: border-box;
  padding: 4% 4% 4% 0%;
  font-family: NotoSansCJKkr;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  text-align: left;
  color: #303032;
  border-bottom: 
`;

export const UnitName = styled.span`
  color: #747474;
`;


export const TitleRow = styled.div`
  padding: 0% 5% 5% 5%;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: -0.28px;
  text-align: center;
  color: #48484a;
  @media (max-width: 500px) {
    padding-top: 5%;
  }
`;


export const ModalInnerDiv = styled.div`
  padding: 10% 10% 7% 10%;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: "NotoSansKR";
  box-sizing: border-box;
  @media (max-width: 500px) {
    padding: 5%;
  }
`;

export const PriceLookupMainDiv = styled.div`
  position: fixed;
  background: #fff;
  border-radius: 5px;
  width: 460px;
  top: 50%;
  left: 50%;
  margin-top: -230px;
  margin-left: -230px;
  zindex: 10;
  @media (max-width: 500px) {
    top: 25%;
    left: 10%;
    width: 80%;
    margin-top: -100px;
    margin-left: 0px;
  }
`;
interface props {
  backgroundImage?: string;
}

export const CloseBtn = styled.button<props>`
  position: absolute;
  display: block;
  top: 0px;
  right: -50px;
  width: 30px;
  height: 30px;
  transition: transform 0.1;
  border: none;
  background-color: transparent;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  cursor: pointer;

  @media (max-width: 500px) {
    top: 100%;
    left: 50%;
    margin-top: 10px;
    margin-left: -15px;
  }
`;
