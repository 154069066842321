import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Path, _client } from "src/apis/client";
import { ShowingData } from "src/components/openHistory/openHistory.hooks";
import { AddressResponse, AddressStatusResponse, PriceLookupResponse } from "src/data/api-response/address";
import { ConclusionType, FloorSelectStatusType, OpenHistoryStatus, PriceLookupStatusType, ResultPartType, SearchInputStatusType, SearchResultStatusType } from "src/data/type/search";
import { DagaguFloorInfo } from "./search.service";


export const SEARCH_ADDR = "SEARCH_ADDR";

export interface searchAddr {
    addressType: string,
    sido: string,
    address: string,
    status: string,
    page: number
}

export const searchAddress = createAsyncThunk<AddressResponse, searchAddr>(
    SEARCH_ADDR,
    async (data) => {
      return await _client.postApi(Path.search_address_list_page, data);
    }
);

export const SEARCH_STATUS = "SEARCH_STATUS";

export const searchStatus = createAsyncThunk<AddressStatusResponse>(
    SEARCH_STATUS,
    async () => {
      return await _client.fetchApi(Path.search_address_status);
    }
);


export const PRICE_LOOKUP = "PRICE_LOOKUP";

export interface priceLookup {
    addrid: string,
    addrtype: string,
    addrtext: string,
    is_underground: boolean,
    floor: string

}

export const priceLookup = createAsyncThunk<PriceLookupResponse, priceLookup>(
    PRICE_LOOKUP,
    async (data) => {
      return await _client.postApi(Path.price_lookup, data);
    }
);

export const INIT_PRICE_LOOKUP_RESULT = "search/priceLookup"

export const initPriceLookupResult = createAction(
  INIT_PRICE_LOOKUP_RESULT,
);

export const cleanPriceLookup = createAction("priceLookup/update")

export const SET_PRICE_LOOKUP_API_STATUS = "search/priceLookupApiStatus"

export const setPriceLookupApiStatus = createAction<PriceLookupStatusType>(
  SET_PRICE_LOOKUP_API_STATUS,
);

export const SET_FLOOR_LOOKUP_API_STATUS = "search/floorLookupApiStatus"

export const setFloorLookupApiStatus = createAction<FloorSelectStatusType>(
  SET_FLOOR_LOOKUP_API_STATUS,
);

export const SET_DAGAGU_FLOOR_INFO = "dagagu/floorInfo"

export const setDagaguFloorInfo = createAction<DagaguFloorInfo>(
  SET_DAGAGU_FLOOR_INFO,
);


export const SET_PRICE_LOOKUP_COMMENT = "search/priceLookupComment"

export const setPriceLookupComment = createAction<string>(
  SET_PRICE_LOOKUP_COMMENT,
);

export const SET_SEARCH_INPUT_KEYWORD = "search/inputKeyword"

export const setSearchInputKeyword = createAction<string>(
  SET_SEARCH_INPUT_KEYWORD,
);

export const SET_SEARCH_SIDO = "search/sido"

export const setSearchSido = createAction<string>(
  SET_SEARCH_SIDO,
);


export const SET_SEARCH_INPUT_TYPE = "search/inputType"

export const setSearchInputStatusType = createAction<SearchInputStatusType>(
  SET_SEARCH_INPUT_TYPE,
);

export const SET_SEARCH_RESULT_TYPE = "search/resultType"

export const setSearchResultStatusType = createAction<SearchResultStatusType>(
  SET_SEARCH_RESULT_TYPE,
);

export const SET_CURRENT_TAB = "tab/set_current_tab"

export const setCurrentTab = createAction<number>(
  SET_CURRENT_TAB,
);

export const SET_CURRENT_PAGE = "tab/set_current_page"

export const setCurrentPageAtService = createAction<number>(
  SET_CURRENT_PAGE,
);

export const SET_ANBU_HISTORY_SHOWING_DATA = "tab/set_anbu_history_showing_data"

export const setAnbuHistoryShowingData = createAction<ShowingData[]>(
  SET_ANBU_HISTORY_SHOWING_DATA,
);

export interface HistoryCardBooleanState {
    id: number,
    value: boolean
}

export interface HistoryCardNumberState {
    id: number,
    value: number
}

export interface HistoryCardStringState {
    id: number,
    value: string
}

export interface HistoryCardResultTypeState {
    id: number,
    value: ResultPartType
}

export interface HistoryCardConclusionTypeState {
    id: number,
    value: ConclusionType
}

export const SET_TOGGLE_STATE_BY_ID = "tab/set_toggle_state_by_id"
export const SET_COMMENT_TOGGLE_STATE_BY_ID = "tab/set_comment_toggle_state_by_id"
export const SET_NOTICE_TOGGLE_STATE_BY_ID = "tab/set_notice_toggle_state_by_id"
export const SET_GUIDE_TOGGLE_STATE_BY_ID = "tab/set_guide_toggle_state_by_id"
export const SET_AGENT_CONTRACT_BY_ID = "tab/set_agent_contract_by_id"
export const SET_OVER_1_YEAR_BY_ID = "tab/set_over_1_year_by_id"
export const SET_LOAN_BY_ID = "tab/set_loan_by_id"
export const SET_OFFICETEL_TYPE_BY_ID = "tab/set_officetel_type_by_id"
export const SET_ANBU_REPORT_GENERATE_STATE_BY_ID = "tab/set_anbu_report_generate_state_by_id"
export const UPCOUNT_ANBU_REPORT_GENERATE_STATE_BY_ID = "tab/upcount_anbu_report_generate_state_by_id"
export const SET_DEPOSIT_BY_ID = "tab/set_deposit_by_id"
export const SET_PRIOR_DEPOSIT_BY_ID = "tab/set_prior_deposit_by_id"
export const SET_OPEN_HISTORY_STATUS = "tab/set_open_history_status"
export const SET_AUCTION_RATIO_BY_ID = "tab/set_auction_ratio_by_id"
export const SET_PRIOR_DEBT_BY_ID = "tab/set_prior_debt_by_id"

export const setToggleStateById = createAction<number>(
  SET_TOGGLE_STATE_BY_ID,
);

export const setCommentToggleStateById = createAction<number>(
  SET_COMMENT_TOGGLE_STATE_BY_ID,
);

export const setGuideToggleStateById = createAction<number>(
  SET_GUIDE_TOGGLE_STATE_BY_ID,
);

export const SET_REPORT_URL_BY_ID = "tab/set_report_url_by_id"
export const setReportUrlById = createAction<HistoryCardStringState>(
  SET_REPORT_URL_BY_ID,
);
export const SET_CONCLUSION_TYPE_BY_ID = "tab/set_result_type_by_id"
export const setConclusionTypeById = createAction<HistoryCardConclusionTypeState>(
  SET_CONCLUSION_TYPE_BY_ID,
);
export const SET_RESULT_PART_STATE_BY_ID = "tab/set_result_part_state_by_id"
export const setResultPartStateById = createAction<HistoryCardResultTypeState>(
  SET_RESULT_PART_STATE_BY_ID,
);

export const setNoticeToggleStateById = createAction<number>(
  SET_NOTICE_TOGGLE_STATE_BY_ID,
);

export const setAgentContractById = createAction<HistoryCardBooleanState>(
  SET_AGENT_CONTRACT_BY_ID,
);

export const setOver1YearById = createAction<HistoryCardBooleanState>(
  SET_OVER_1_YEAR_BY_ID,
);

export const setLoanById = createAction<HistoryCardBooleanState>(
  SET_LOAN_BY_ID,
);

export const setOfficetelTypeById = createAction<HistoryCardBooleanState>(
  SET_OFFICETEL_TYPE_BY_ID,
);

export const setAnbuReportGenerateStateById = createAction<HistoryCardStringState>(
  SET_ANBU_REPORT_GENERATE_STATE_BY_ID,
);

export const upcountGenerateStateById = createAction<number>(
  UPCOUNT_ANBU_REPORT_GENERATE_STATE_BY_ID,
);

export const setOpenHistoryStatus = createAction<OpenHistoryStatus>(
  SET_OPEN_HISTORY_STATUS,
);

export const setDepositById = createAction<HistoryCardNumberState>(
  SET_DEPOSIT_BY_ID,
);

export const setPriorDepositById = createAction<HistoryCardNumberState>(
  SET_PRIOR_DEPOSIT_BY_ID,
);

export const setAuctionRatioById = createAction<HistoryCardNumberState>(
  SET_AUCTION_RATIO_BY_ID,
);

export const setPriorDebtById = createAction<HistoryCardNumberState>(
  SET_PRIOR_DEBT_BY_ID,
);

