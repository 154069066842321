import { createReducer } from "@reduxjs/toolkit";
import { ShowingData } from "src/components/openHistory/openHistory.hooks";
import {
  AddressResponse,
  AddressStatusResponse,
  PriceLookupResponse,
} from "src/data/api-response/address";
import { FloorSelectStatusType, OpenHistoryStatus, PriceLookupStatusType, SearchInputStatusType, SearchResultStatusType } from "src/data/type/search";
import {
  priceLookup,
  setPriceLookupApiStatus,
  setPriceLookupComment,
  initPriceLookupResult,
  searchAddress,
  searchStatus,
  cleanPriceLookup,
  setCurrentTab,
  setAnbuHistoryShowingData,
  setToggleStateById,
  setCommentToggleStateById,
  setAgentContractById,
  HistoryCardBooleanState,
  HistoryCardStringState,
  setOver1YearById,
  setLoanById,
  setAnbuReportGenerateStateById,
  setOpenHistoryStatus,
  setDepositById,
  setPriorDepositById,
  HistoryCardNumberState,
  setOfficetelTypeById,
  setDagaguFloorInfo,
  setNoticeToggleStateById,
  setSearchInputKeyword,
  setSearchSido,
  setSearchInputStatusType,
  setSearchResultStatusType,
  setCurrentPageAtService,
  setFloorLookupApiStatus,
  setConclusionTypeById,
  setReportUrlById,
  setResultPartStateById,
  HistoryCardResultTypeState,
  HistoryCardConclusionTypeState,
  upcountGenerateStateById,
  setGuideToggleStateById,
  setAuctionRatioById,
  setPriorDebtById
} from "./search.actions";
import SearchService, { DagaguFloorInfo } from "./search.service";

const SearchReducer = (service: SearchService) =>
  createReducer(service, (builder) => {
    builder
      .addCase(searchAddress.fulfilled, (service, action) => {
        service.update_search_result(action.payload as AddressResponse);
      })
      .addCase(searchStatus.fulfilled, (service, action) => {
        service.update_search_status(action.payload as AddressStatusResponse);
      })
      .addCase(setSearchInputKeyword, (service, action) => {
        service.set_search_input_keyword(action.payload as string);
      })
      .addCase(setSearchSido, (service, action) => {
        service.set_search_sido(action.payload as string);
      })
      .addCase(setSearchInputStatusType, (service, action) => {
        service.set_search_input_status_type(action.payload as SearchInputStatusType);
      })
      .addCase(setSearchResultStatusType, (service, action) => {
        service.set_search_result_status_type(action.payload as SearchResultStatusType);
      })
      .addCase(initPriceLookupResult, (service) => {
        service.init_price_lookup();
      })
      .addCase(cleanPriceLookup, (service, action) => {
        service.clean_price_lookup();
      })
      .addCase(priceLookup.fulfilled, (service, action) => {
        service.update_price_lookup(action.payload as PriceLookupResponse);
      })
      .addCase(setPriceLookupApiStatus, (service, action) => {
        service.set_price_lookup_api_status(action.payload as PriceLookupStatusType);
      })
      .addCase(setFloorLookupApiStatus, (service, action) => {
        service.set_floor_lookup_api_status(action.payload as FloorSelectStatusType);
      })
      .addCase(setDagaguFloorInfo, (service, action) => {
        service.set_dagagu_floor_info (action.payload as DagaguFloorInfo);
      })
      .addCase(setPriceLookupComment, (service, action) => {
        service.set_price_lookup_comment(action.payload as string);
      })
      .addCase(setCurrentTab, (service, action) => {
        service.set_current_tab(action.payload as number);
      })
      .addCase(setCurrentPageAtService, (service, action) => {
        service.set_current_page(action.payload as number);
      })
      .addCase(setAnbuHistoryShowingData, (service, action) => {
        service.set_anbu_history_showing_data(action.payload as ShowingData[]);
      })
      .addCase(setToggleStateById, (service, action) => {
        service.set_toggle_fold_state_by_id(action.payload as number);
      })
      .addCase(setGuideToggleStateById, (service, action) => {
        service.set_toggle_guide_state_by_id(action.payload as number);
      })
      .addCase(setConclusionTypeById, (service, action) => {
        service.set_result_type_by_id(action.payload as HistoryCardConclusionTypeState);
      })
      .addCase(setReportUrlById, (service, action) => {
        service.set_report_url_by_id(action.payload as HistoryCardStringState);
      })
      .addCase(setResultPartStateById, (service, action) => {
        service.set_result_part_state_by_id (action.payload as HistoryCardResultTypeState);
      })
      .addCase(setCommentToggleStateById, (service, action) => {
        service.set_comment_toggle_fold_state_by_id(action.payload as number);
      })
      .addCase(setNoticeToggleStateById, (service, action) => {
        service.set_notice_toggle_fold_state_by_id(action.payload as number);
      })
      .addCase(setAgentContractById, (service, action) => {
        service.set_agent_contract_by_id(action.payload as HistoryCardBooleanState);
      })
      .addCase(setOver1YearById, (service, action) => {
        service.set_over_1_year_by_id(action.payload as HistoryCardBooleanState);
      })
      .addCase(setLoanById, (service, action) => {
        service.set_loan_by_id(action.payload as HistoryCardBooleanState);
      })
      .addCase(setOfficetelTypeById, (service, action) => {
        service.set_officetel_type_by_id(action.payload as HistoryCardBooleanState);
      })
      .addCase(setAnbuReportGenerateStateById, (service, action) => {
        service.set_anbu_report_generate_state_by_id(action.payload as HistoryCardStringState);
      })
      .addCase(upcountGenerateStateById, (service, action) => {
        service.upcount_generate_state_by_id(action.payload as number);
      })
      .addCase(setDepositById, (service, action) => {
        service.set_deposit_by_id(action.payload as HistoryCardNumberState);
      })
      .addCase(setPriorDepositById, (service, action) => {
        service.set_prior_deposit_by_id(action.payload as HistoryCardNumberState);
      })
      .addCase(setAuctionRatioById, (service, action) => {
        service.set_auction_ratio_by_id(action.payload as HistoryCardNumberState);
      })
       .addCase(setPriorDebtById, (service, action) => {
        service.set_prior_debt_by_id(action.payload as HistoryCardNumberState);
      })
      .addCase(setOpenHistoryStatus, (service, action) => {
        service.set_open_history_status(action.payload as OpenHistoryStatus);
      });
  });

export default SearchReducer;
