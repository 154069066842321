import { useDispatch } from "react-redux";
import { UiComponentType } from "src/data/type/ui";
import { useSelector } from "src/hooks/useSelector";
import { uiVisible } from "src/services/ui/ui.actions";

export default function usePaymentModal() {
    const dispatch = useDispatch();
    const visible = useSelector((state) => {
            return state.uiReducer.getUiComponent(UiComponentType.MODAL_PAYMENT).visible
        }
    );

    const open_payment_modal = () => {
        console.log("[paymentModal/PaymentModal.hook.ts] open_payment_modal() has been clicked")
        dispatch(uiVisible({'id': UiComponentType.MODAL_PAYMENT, 'visible': true}))
    }

    const close_payment_modal = () => {
        console.log("[paymentModal/PaymentModal.hook.ts] close_payment_modal() has been clicked")
        dispatch(uiVisible({'id': UiComponentType.MODAL_PAYMENT, 'visible': false}))
    }


    return {visible, open_payment_modal, close_payment_modal}
}