import styled from "styled-components";

export const BodyWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const TabRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 70vw;
  box-sizing: border-box;
  height: 2.8vw;
  min-height: 30px;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
  border-bottom: solid 2px #eee;

  @media (max-width: 500px) {
    width: 90vw;
    min-height: 10vw;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
  }
`;

// border: solid 1px blue;
export interface rowProp {
  active?: boolean;
}

export const SearchRow = styled.div<rowProp>`
  display: ${({ active }) => (active ? "inline-flex" : "none")};
  position: relative;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  height: 20vw;
  width: 70vw;
  min-height: 200px;
  max-width: 1600px;

  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
  padding: 2vw 5vw 2vw 5vw;

  @media (min-width: 2000px) {
    margin-left: auto;
    margin-right: auto;
    margin-right: auto;
  }

  @media (max-width: 500px) {
    width: 90vw;
    min-height: 250px;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
    padding: 3vw 3vw 3vw 3vw;
  }

  border-radius: 14px;
  background-color: #005d1d;
`;

export const ResultInnerContainer = styled.div<rowProp>`
  display: ${({ active }) => (active ? "inline-flex" : "none")};
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const ResultTableContainer = styled.div<rowProp>`
  display: ${({ active }) => (active ? "inline-flex" : "none")};
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export interface resultProp {
  border?: boolean;
}

// display: ${({ active }) => active ? 'inline-flex' : 'none'};
//   border: solid 2px #eee;
export const ResultRow = styled.div<resultProp>`
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70vw;
  box-sizing: border-box;
  min-height: 100px;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
  border-radius: 14px;
  border: ${({ border }) => (border ? "solid 2px #eee" : "none")};

  @media (max-width: 500px) {
    width: 90vw;
    min-height: 300px;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }
`;
// background-color: #eee;

export const NoticeRow = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 70vw;
  box-sizing: border-box;
  min-height: 100px;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 1vw;
  margin-bottom: 1vw;

  @media (max-width: 500px) {
    width: 90vw;
    min-height: 300px;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }
`;




export const HistoryContainer = styled.div`
  padding: 18px;
`
export const HeaderContainer = styled.div`
  overflow: hidden;
`
export const Title = styled.span`
  float: left;
  font-weight: 400;
  color: #666;
`
export const RemoveText = styled.span`
  float: right;
  color: #a7a7a7;
`

export const ListContainer = styled.ul`
  margin: 10px 0;
`

export const KeywordContainer = styled.li`
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const RemoveButton = styled.button`
  float: right;
  color: #0cde8b;
  border: 1px solid #0cde8b;
  padding: 3px 5px;
  border-radius: 15px;
`

export const Keyword = styled.span`
  font-size: 18px;
  font-weight: 400;
`