import { Path, _client } from "src/apis/client";
import { FloorSelectStatusType } from "src/data/type/search";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uiVisible } from "src/services/ui/ui.actions";
import { UiComponentType } from "src/data/type/ui";
import { useSelector } from "src/hooks/useSelector";
import { DagaguFloorInfo } from "src/services/search/search.service";
import {
  setDagaguFloorInfo,
  setFloorLookupApiStatus,
  setSearchResultStatusType,
} from "src/services/search/search.actions";
import { SearchResultStatusType } from "src/data/type/search";

export interface FloorListResponse {
  data: string[];
  msg: string | null;
  error: string | null;
  error_code: number | null;
}

export default function useFloorSelectModal() {
  const dispatch = useDispatch();
  const floorSelectModalVisible = useSelector((state) => {
    return state.uiReducer.getUiComponent(UiComponentType.MODAL_FLOOR_SELECT)
      .visible;
  });

  const openFloorSelectModal = (
    addrtype: string,
    addrtext: string,
    addrid: string
  ) => {
    dispatch(setFloorLookupApiStatus(FloorSelectStatusType.Loading))
    if (addrtext) {
      _client
        .postApi(Path.search_floor_list, {
          addrtext: addrtext,
          addrid: addrid,
          addrtype: addrtype,
        })
        .then((e: FloorListResponse) => {
          let _buf = {
            addrid: addrid,
            addrtext: addrtext,
            addrtype: addrtype,
            floorList: e.data,
          } as DagaguFloorInfo;
          dispatch(setFloorLookupApiStatus(FloorSelectStatusType.Possible))
          dispatch(setDagaguFloorInfo(_buf));
        })
        .catch((err) => {
          dispatch(setFloorLookupApiStatus(FloorSelectStatusType.Impossible))
        });
    }
    dispatch(
      uiVisible({ id: UiComponentType.MODAL_FLOOR_SELECT, visible: true })
    );
  };

  const closeFloorSelectModal = () => {
    dispatch(
      uiVisible({ id: UiComponentType.MODAL_FLOOR_SELECT, visible: false })
    );
  };

  return {
    floorSelectModalVisible,
    openFloorSelectModal,
    closeFloorSelectModal,
  };
}
