import styled from "styled-components";

export const Statement = styled.p`
  font-weight: 520;
  line-height: 1.4;
  color: #48484a;
`;

export const PolicyTittle = styled.h1`
  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.08px;
  text-align: left;
  color: #48484a;
`;

export const GreenBoldText = styled.span`
  color: #008128;
`;

export const TermsBox = styled.div`
  position: relative;
  overflow: auto;
  height: 90px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1p solid #fff;
  background: #eee;
  box-sizing: border-box;
`;

export const Article = styled.div`
  color: #48484a;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.07px;
  text-align: left;
`;

export const ArticleTittle = styled.h3`
  font-size: 12px;
  font-weight: 700;
  line-height: 2px;
  padding-bottom: 8px;
`;

export const ArticleText = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

export const AgreeBtnContainer = styled.div`
  display: flex;
  height: 37px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #008128;

  font-family: NotoSansKR;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  :hover {
    background-color: rgba(0, 162, 51, .9);
  }
`;

export const AgreeBtn = styled.button`
  width: 100%;
  height: 100%;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #008128;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 37px;
  text-align: center;
  align-items: center;
  justify-content: space-between;

  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.09px;
  text-align: center;
  color: #48484a;

  @media (max-width: 500px) {
    font-size: 15px;
  }


`;

export const HoriLine = styled.div`
  width: 35%;
  height: 2px;
  background-color: #eee;
  @media (max-width: 500px) {
    width: 20%;
  }
`;

export const AgentBtnContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 37px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: solid 3px #008128;
  background-color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  :hover {
    border: 3px solid rgba(0, 162, 51, .8);
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    height: 100%;
  }
`;

export const AgentBtn = styled.button`
  width: 100%;
  height: 100%;
  min-height: 33px;
  font-size: 17px;
  font-weight: 650;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.11px;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  color: #008128;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
  @media (max-width: 500px) {
    font-size: 17px;
  }
`;

export const AgreementContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`