import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Path, _client } from "src/apis/client";
import ApiResponse from "src/data/api-response/response";
import { TokenResponse } from "src/data/api-response/token";
import { UserResponse } from "src/data/api-response/user";
import { PhoneAuthType } from "src/data/type/phoneAuth";
import { UserLoginStatusType } from "src/data/type/status";

export const USER_LOGIN = "USER_LOGIN";
export const USER_REGISTER = "USER_REGISTER";
export const USER_SET_TOKEN = "USER_SET_TOKEN";
export const USER_SET_LOGIN_STATUS = "SET_LOGIN_STATUS";
export const USER_VERIFY_TOKEN = "USER_VERIFY_TOKEN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_JOIN = "USER_JOIN";
export const USER_ME = "USER_ME";

export interface userLogin {
    phone: string,
    pw: string,
    saveLogin: boolean
}

export interface userJoin {
  phone: string,
  pw: string,
  email: string,
  full_name: string,
  agent_enroll_id: string,
  user_type: string
}

export interface PhoneAuthParamProp {
  phone: string
  verify_code : string | undefined
}

export interface PhoneAuthProp {
  action : PhoneAuthType
  params:  PhoneAuthParamProp
}

export const user_login = createAsyncThunk<TokenResponse, userLogin>(
    USER_LOGIN,
    async (data) => {
      return await _client.postApi(Path.login("mobile"), data);
    }
);

export const user_me = createAsyncThunk<UserResponse>(
  USER_ME,
  async () => {
    return await _client.fetchApiWithToken(Path.me);
  }
);

export const user_logout = createAction(USER_LOGOUT);

export const user_verify_token = createAsyncThunk<TokenResponse>(
  USER_VERIFY_TOKEN,
  async () => {
    return await _client.fetchApiWithToken(Path.verify_token);
  }
);

export const user_join = createAsyncThunk<TokenResponse, userJoin>(
  USER_JOIN,
  async (data) => {
    return await _client.postApi(Path.join("mobile"), {phone: data.phone, pw: data.pw, email: data.email});
  }
);

export const phone_auth_verify = createAsyncThunk<ApiResponse<any>, PhoneAuthProp>(
  "phone_auth/verify",
  async (data) => {
    return await _client.postApi(Path.phone_auth(data.action, data.params));
  }
);

export const phone_auth_create = createAsyncThunk<ApiResponse<any>, PhoneAuthProp>(
  "phone_auth/create",
  async (data) => {
    return await _client.postApi(Path.phone_auth(data.action, data.params));
  }
);

export const user_set_login_status = createAction<UserLoginStatusType>(USER_SET_LOGIN_STATUS);


export const SET_AGENT_INFO = "agent/info"

export const set_agent_info = createAction<boolean>(
  SET_AGENT_INFO,
);

export const SET_AGENT_BOSS_NAME = "agent/boss_name"

export const set_agent_boss_name = createAction<string>(
  SET_AGENT_BOSS_NAME,
);

export const SET_AGENT_OFFICE_NAME = "agent/office_name"

export const set_agent_office_name = createAction<string>(
  SET_AGENT_OFFICE_NAME,
);

export const SET_AGENT_ENROLL_ID = "agent/enroll_id"

export const set_agent_enroll_id = createAction<string>(
  SET_AGENT_ENROLL_ID,
);

export const SET_VIRTUAL_BANK_ACCOUNT = "virtualbank/account"
export const SET_VIRTUAL_BANK_NAME = "virtualbank/name"
export const SET_VIRTUAL_BANK_AMOUNT_OF_MONEY = "virtualbank/amount_of_money"
export const SET_VIRTUAL_BANK_DEADLINE = "virtualbank/deadline"
export const set_virtual_bank_account = createAction<string>(
  SET_VIRTUAL_BANK_ACCOUNT,
);
export const set_virtual_bank_name = createAction<string>(
  SET_VIRTUAL_BANK_NAME,
);
export const set_virtual_bank_amount_of_money = createAction<string>(
  SET_VIRTUAL_BANK_AMOUNT_OF_MONEY,
);
export const set_virtual_bank_deadline = createAction<string>(
  SET_VIRTUAL_BANK_DEADLINE,
);
