import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { UserLoginStatusType } from "src/data/type/status";
import { useSelector } from "src/hooks/useSelector";
import { user_login, user_me } from "src/services/user/user.actions";
import Modal from "../common/Modal";
import useLoginModal from "./LoginModal.hook";
import {
  TitleDiv,
  MobileTitleDiv,
  OuterDiv,
  InputText,
} from "./LoginModal.style";

export const LoginModal = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState(true);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [loginMsg, setLoginMsg] = useState<string | undefined>();
  const [saveIdCheckBox, setSaveIdCheckBox] = useState(false);
  const { visible, close_login_modal } = useLoginModal();
  const { loginStatus, loginFailMsg } = useSelector((state) => {
    return {
      loginStatus: state.userReducer.get_login_status(),
      loginFailMsg: state.userReducer.get_login_fail_msg(),
    };
  });

  useEffect(() => {
    setLoginMsg(loginFailMsg);
  }, [loginFailMsg]);

  useEffect(() => {
    if (userId && password) setButtonDisable(false);
  }, [userId, password]);

  const focustInputText = () => {
    setLoginMsg(undefined);
  };

  const handleClick = () => {
    if (userId && password) {
      console.log("로그인 시도 중");
      dispatch(
        user_login({ phone: userId, pw: password, saveLogin: saveIdCheckBox })
      );
    }
  };
  const isDesktop = useMediaQuery({
    query: "(min-width: 500px)",
  });

  if (loginStatus === UserLoginStatusType.LOGGEDIN) return null;
  return (
    <>
      <Modal isOpen={visible}>
        <OuterDiv mobile={!isDesktop}>
          <div style={{ textAlign: "center" }}>
            <TitleDiv>로그인</TitleDiv>
            <div
              style={{
                textAlign: "left",
                marginTop: 49,
                color: "#757575",
                fontWeight: 550,
                fontSize: 18,
              }}
            >
              아이디 (휴대폰 번호)
              <div style={{ marginTop: 20, height: 30 }}>
                <InputText
                  color={userId ? "#48484a" : "#d1d1d6"}
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  placeholder="휴대폰 번호를 입력해주세요"
                  onFocus={() => focustInputText()}
                />
              </div>
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: 49,
                color: "#757575",
                fontWeight: 550,
                fontSize: 18,
              }}
            >
              비밀번호
              <div style={{ marginTop: 20, height: 30 }}>
                <InputText
                  color={password ? "#48484a" : "#d1d1d6"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  onFocus={() => focustInputText()}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleClick();
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ textAlign: "left", marginTop: 10, color: "#757575" }}>
              {loginStatus === UserLoginStatusType.LOADING ? (
                <div>{"로딩 중"}</div>
              ) : (
                <div style={{ color: "red" }}>{loginMsg}</div>
              )}
            </div>

            <div
              style={{
                textAlign: "left",
                marginTop: 25,
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  fontSize: 16,
                  color: "#48484a",
                }}
              >
                <input
                  type="checkbox"
                  name="save"
                  checked={saveIdCheckBox}
                  onChange={() => {
                    setSaveIdCheckBox(!saveIdCheckBox);
                  }}
                  style={{
                    backgroundColor: "#d1d1d6",
                    height: 16,
                    width: 16,
                    verticalAlign: "middle",
                    position: "relative",
                    marginRight: 11,
                  }}
                />
                <label>아이디 저장하기</label>
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: 30 }}>
              <button
                style={{
                  width: "100%",
                  height: 40,
                  borderRadius: 5,
                  border: 0,
                  cursor: "pointer",
                  fontSize: 22,
                  fontWeight: "bold",
                  color: "#fff",
                  backgroundColor: buttonDisable ? "#d1d1d6" : "#008128",
                }}
                onClick={handleClick}
                disabled={buttonDisable}
              >
                로그인
              </button>
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: 25,
              }}
            >
              <div style={{ display: "inline-block" }}>
                아직 회원이 아니신가요?
              </div>
              <a
                href="/register"
                rel="noreferrer"
                onClick={close_login_modal}
                style={{
                  display: "inline-block",
                  float: "right",
                  color: "#005d1d",
                  textDecoration: "underline",
                  whiteSpace: "pre-wrap",
                }}
              >
                회원가입
              </a>
            </div>
            <div style={{ height: 20, marginTop: 15, marginBottom: 15, textAlign: "left" }}>
              <div style={{ display: "inline-block" }}>
                비밀번호를 잊어버리셨나요?
              </div>
              <a
                href="/password"
                rel="noreferrer"
                style={{
                  display: "inline-block",
                  float: "right",
                  color: "#005d1d",
                  textDecoration: "underline",
                  whiteSpace: "pre-wrap",
                }}
              >
                비밀번호 찾기
              </a>
            </div>
          </div>
        </OuterDiv>
      </Modal>
    </>
  );
};
