export enum UserStatusType {
    PENDING = "PENDING", // 계정 가입 승인 대기
    BLOCKED = "BLOCKED", // 개정 블록 상태
}

export enum UserLoginStatusType {
    LOADING = "LOADING", // 계정 로그인 중 상태
    LOGGEDOUT = "LOGGEDOUT", // 계정 로그인 실패 상태
    LOGGEDIN = "LOGGEDIN" // 계정 로그인 성공 상태
}

export enum PaymentStatusType {
    DONE = "DONE", // 결제 완료
    CANCLED = "CANCLED", // 결제 취소
}

export enum PaymentReqStatusType {
    LOADING = "LOADING", // 결제 승인 요청
    DONE = "DONE", // 결제 승인 요청 완료
}