import styled from "styled-components";

export interface Props {
  active?: boolean;
}

export const OuterContainer = styled.div<Props>`
  display: ${({ active }) => (active ? "inline-flex" : "none")};
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  width: 1440px;
  padding-left: 144px;
  padding-right: 144px;

  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 1440px) {
    width: 80%;
    padding-right: 0px;
    padding-left: 0px;
  }

  @media (max-width: 500px) {
    width: 90%;
    padding-right: 0%;
    padding-left: 0%;

    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export interface rowProp {
  active?: boolean;
  borderColor?: string;
}

// border: solid 2px #d1d1d6;
export const Card = styled.div<rowProp>`
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: column;
  box-sizing: border-box;

  width: 1152px;

  height: ${({ active }) => (active ? "100%" : "none")};
  margin-bottom: 20px;
  border-radius: 7px;
  box-shadow: 0px 4px 6px 0 rgba(0, 0, 0, 0.11);
  border: solid 2px
    ${({ borderColor }) => (borderColor ? borderColor : "#d1d1d6")};

  @media (max-width: 1440px) {
    width: 80vw;
  }

  @media (max-width: 500px) {
    width: 90vw;
  }
`;

export const CardStateRow = styled.div<rowProp>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  border-bottom: solid 2px #e2e2e2;
  padding-top: 0.6%;
  padding-bottom: 0.6%;
`;

export const CardStateInner = styled.div`
  display: flex;
  width: 96%;
  flex-wrap: nowrap;
  flex-direction: row;
  box-sizing: border-box;
`;

export const CardRightSideButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }

  :active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transition-duration: 0.4s;
  }
  :after {
    content: "";
    background: #90ee90;
    opacity: 0;
    transition: all 0.8s;
  }
  :active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }
`;

export const CardRightSideImg = styled.img`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 100%;
`;

export const CardStateLeft = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: left;
`;

export interface stateProp {
  state?: boolean;
  color?: string;
}

export const State = styled.div<stateProp>`
  border: solid 1px ${({ state, color }) => (state ? color : "#d1d1d6")};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  cursor: ${({ state }) => (state ? "pointer" : "not-allowed")};
  :hover {
    opacity: 0.5;
  }

  background-color: ${({ state }) => (state ? "#fff" : "#f4f4f5")};

  font-size: 0.8vw;
  padding-left: 1.2vw;
  padding-right: 1.2vw;
  margin-right: 2%;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.56px;
  text-align: center;
  color: ${({ state, color }) => (state ? color : "#aeaeb2")};

  @media (max-width: 2000px) {
    font-size: 1rem;
    border: solid 2px ${({ state, color }) => (state ? color : "#d1d1d6")};
  }
  @media (max-width: 500px) {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
    font-size: 0.9rem;
  }
`;

export const CardStateRight = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  box-sizing: border-box;
 
  width: 20%;

  @media (max-width: 500px) {
    width: 40%;
  }
`;

export const CardPreviewRow = styled.div`
  display: inline-flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  min-height: 20%;
  border-bottom: solid 2px #e2e2e2;
`;

export const CardPreviewInner = styled.div`
  display: inline-flex;
  width: 96%;
  @media (max-width: 500px) {
    padding-left: 4vw;
  }
`;

export const OpenBtn = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  width: 40px;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  opacity: 0.4;
  :hover {
    opacity: 0.5;
  }

  :active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transition-duration: 0.4s;
  }
  :after {
    content: "";
    background: #90ee90;
    opacity: 0;
    transition: all 0.8s;
  }
  :active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }
`;

export interface imgProp {
  folded?: boolean;
}

export const OpenImg = styled.img<imgProp>`
  box-sizing: border-box;
  background: transparent;
  max-width: -webkit-fill-available;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  width: 100%;
  transform: ${({ folded }) => (folded ? "rotate(180deg)" : "none")};
`;

export const CardPreviewLeft = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 80%;
`;

export const PreviewAddr = styled.div`
  font-family: NotoSansKR-Medium;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;

  height: 100%;
  padding-top: 1%;
  padding-bottom: 1%;

  font-size: 1vw;
  line-height: 2;
  font-weight: 630;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.56px;
  color: #2c2c2e;

  @media (max-width: 2000px) {
    font-size: 1rem;
  }
  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

export const PreviewSpan = styled.span`
  color: #7c7c7c;
`;

export const CardPreviewRight = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: right;
  align-items: center;
  width: 20%;
`;

export interface rowProp {
  active?: boolean;
  clickable?: boolean;
}

export const CardContentRow = styled.div<rowProp>`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 50%;
`;

export const CardContentInner = styled.div`
  display: flex;
  width: 96%;
  height: 80%;

  @media (max-width: 700px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    padding-left: 4vw;
  }
`;

export const CardContentLeft = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  box-sizing: border-box;
  width: 50%;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

interface prop {
  inactive?: boolean;
}

export const ContentItem = styled.div<prop>`
  display: ${({ inactive }) => (inactive ? "none" : "flex")};
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  height: 60px;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.66px;
  color: #48484a;

  @media (max-width: 1440px) {
    height: 55px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 17px;
  }

  @media (max-width: 500px) {
    height: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 17px;
  }
`;

export const DetailOuterCtnr = styled.div<prop>`
  display: ${({ inactive }) => (inactive ? "none" : "flex")};
  flex-direction: column;
  margin-right: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f4f4f5;
  font-size: 13px;

  @media (max-width: 700px) {
    margin-right: 15px;
    font-size: 12px;
  }
`;

export const DetailComment = styled.div<prop>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  padding-top: 0px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.66px;
  color: #005d1d;

  @media (max-width: 1440px) {
    padding-right: 15px;
    font-size: 13px;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

interface Aprop {
  active?: boolean;
}

// export const DetailCommentArrow = styled.div<Aprop>`
//   width: 0;
//   height: 0;
//   margin-left: 5px;
//   border-left: 8px solid transparent;
//   border-right: 8px solid transparent;
//   border-top: ${({ active }) => (active ? "11px solid #005d1d" : "none")};
//   border-bottom: ${({ active }) => (active ? "none":"11px solid #005d1d")};
// `;
export const DetailCommentArrow = styled.div<Aprop>`
  border: solid #005d1d;
  border-width: 0 3px 3px 0;
  display: inline-block;
  margin-left: 5px;
  margin-top: ${({ active }) => (active ? "-5px" : "3px")};
  border-radius: 2px;
  padding: 3px;
  transform: ${({ active }) => (active ? "rotate(45deg)" : "rotate(-135deg)")};
  -webkit-transform: ${({ active }) =>
    active ? "rotate(45deg)" : "rotate(-135deg)"};
  cursor: pointer;
`;

export const ContentComments = styled.div<prop>`
  display: ${({ inactive }) => (inactive ? "none" : "flex")};
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 15px;
  box-sizing: border-box;
  height: 40px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.66px;
  color: #48484a;

  @media (max-width: 1440px) {
    height: 30px;
    padding-right: 2vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    font-size: 0.7vw;
  }

  @media (max-width: 500px) {
    height: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
  }
`;

interface tittleProp {
  width?: string;
}

export const ContentTittle = styled.div<tittleProp>`
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "40%")};
  font-family: NotoSansKR;
  flex-direction: row;

  @media (max-width: 350px) {
    width: 45%;
  }
`;

export const OfficetelComment = styled.div<tittleProp>`
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "40%")};
  font-family: NotoSansKR;
  flex-direction: row;
`;

export const CommentTittle = styled.div<tittleProp>`
  display: inline-flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  font-family: NotoSansKR;
  flex-direction: row;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  width: 60%;
  padding-right: 1vw;
  height: 100%;
`;

export const RadioBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  width: 70%;
  padding-right: 1vw;
  height: 100%;
`;

export const RadioBtnLabel = styled.label`
  display: flex;
  width: 0.9vw;
  height: 0.9vw;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

export interface radioProps {
  active?: boolean;
}

export const RadioBtn = styled.input<radioProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
  opacity: 1;
  border: solid 3px #005d1d;
  background-color: #005d1d;

  &:checked {
    border: solid 2px #005d1d;
    background-color: #005d1d;
  }

  :hover {
    opacity: 0.5;
  }

  @media (max-width: 1440px) {
    width: 1.3vw;
    height: 1.3vw;
    margin-left: 1vw;
    margin-right: 0.2vw;
  }

  @media (max-width: 500px) {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const MyRadioBtn = styled.input``;

interface anbuProp {
  width?: string;
}

export const AnbuContent = styled.div<anbuProp>`
  flex-direction: row;
  justify-content: right;

  display: flex;
  align-items: center;

  width: ${({ width }) => (width ? width : "60%")};
  height: 100%;

  border-radius: 5px;
  border: solid 1px #d1d1d6;
  background-color: #fff;
  font-family: NotoSansKR;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  color: #aeaeb2;
  font-size: 0.9em;
  padding-right: 5%;
  margin-right: 15px;

  font-size: 0.9em;

  @media (max-width: 700px) {
    margin-right: 0px;
  }
`;

export const InputContent = styled.div`
  flex-direction: row;
  justify-content: right;

  &:focus-within {
    border: solid 1.3px #008128;
  }

  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 5px;
  border: solid 1px #d1d1d6;
  background-color: #fff;
  font-size: 0.9em;
  font-family: NotoSansKR;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  color: #48484a;
  padding-right: 2%;
`;

export const DepositInput = styled.input`
  display: flex;
  justify-content: right;
  box-sizing: border-box;
  width: 100%;
  padding-right: 2%;
  outline: none;
  border: none;

  text-align: right !important;
  background: transparent;

  font-family: NotoSansKR;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.65px;
  color: #48484a;
  font-size: 1em;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 500px) {
    font-size: 1em;
  }
`;

export const CardContentRight = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  box-sizing: border-box;
  width: 50%;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const CardConfirmRow = styled.div<rowProp>`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-wrap: nowrap;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`;

export const CardConfirmInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  box-sizing: border-box;
  width: 96%;
  border-bottom: solid 2px #d1d1d6;
  @media (max-width: 500px) {
    border-top: solid 1px #d1d1d6;
    flex-direction: column;
    padding-left: 4vw;
    padding-right: 4vw;
  }
`;

export const CardConfirmLeft = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 70%;
  box-sizing: border-box;
  padding-top: 1%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const CardComment = styled.div`
  font-size: 15px;
  font-family: NotoSansKR;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.23px;
  text-align: left;
  color: #2c2c2e;
  height: 33%;

  @media (max-width: 1440px) {
    font-size: 0.8vw;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export const NewWindowComment = styled.div`
  font-size: 20px;
  font-family: NotoSansKR;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.23px;
  text-align: left;
  color: #2c2c2e;
  height: 33%;

  @media (max-width: 1440px) {
    font-size: 0.8vw;
  }

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const CardConfirmRight = styled.div<Props>`
  display: ${({ active }) => (active ? "flex" : "none")};
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  flex-wrap: nowrap;
  flex-direction: column;
  box-sizing: border-box;
  width: 30%;
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const GenerateButton = styled.button<rowProp>`
  background-repeat: no-repeat;
  border: none;
  height: 80%;
  width: 100%;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "not-allowed")};
  overflow: hidden;
  outline: none;
  opacity: 1;
  :hover {
    opacity: 0.5;
  }
  font-size: 120%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ borderColor }) => (borderColor ? borderColor : "#008128")};

  border-radius: 5px;
  background-color: #fff;

  border: solid 1.5px
    ${({ borderColor }) => (borderColor ? borderColor : "#008128")};

  @media (max-width: 1440px) {
    font-size: 1vw;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export const InitialGenerateButton = styled.button<rowProp>`
  background-repeat: no-repeat;
  border: none;
  height: 80%;
  width: 100%;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "not-allowed")};
  overflow: hidden;
  outline: none;
  opacity: 1;
  :hover {
    opacity: 0.5;
  }
  font-size: 120%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;

  border-radius: 5px;
  background-color: #005d1d;

  border: 1.5px solid #005d1d;

  @media (max-width: 1440px) {
    font-size: 1vw;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export interface progress {
  ratioLeft?: string;
  ratioRight?: string;
}

export const GenerateButtonDisabled = styled.button<progress>`
  background-repeat: no-repeat;
  border: solid 1px #d1d1d6;
  height: 80%;
  width: 100%;
  overflow: hidden;
  outline: none;
  opacity: 1;
  font-size: 120%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.65px;
  text-align: center;
  color: #aeaeb2;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    #f4f4f5 ${({ ratioLeft }) => (ratioLeft ? ratioLeft : "100%")},
    #fff ${({ ratioRight }) => (ratioRight ? ratioRight : "0%")}
  );

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    font-size: 1vw;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

///////////////
// const App = () => {
//   const [select, setSelect] = useState("betterPriceOnly");
//   const handleSelectChange = (event) => {
//     const value = event.target.value;
//     setSelect(value);
//   };
//   return (
//     <Wrapper>
//       <Item>
//         <RadioButton
//           type="radio"
//           name="radio"
//           value="betterPriceOnly"
//           checked={select === "betterPriceOnly"}
//           onChange={(event) => handleSelectChange(event)}
//         />
//         <RadioButtonLabel />
//         <div>Auto accept better price only</div>
//       </Item>
//       <Item>
//         <RadioButton
//           type="radio"
//           name="radio"
//           value="anyPriceChange"
//           checked={select === "anyPriceChange"}
//           onChange={(event) => handleSelectChange(event)}
//         />
//         <RadioButtonLabel />
//         <div>Auto accept any price change</div>
//       </Item>
//       <Item>
//         <RadioButton
//           type="radio"
//           name="radio"
//           value="neverAutoAccept"
//           checked={select === "neverAutoAccept"}
//           onChange={(event) => handleSelectChange(event)}
//         />
//         <RadioButtonLabel />
//         <div>Never auto accept a price change</div>
//       </Item>
//     </Wrapper>
//   );
// };

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0px 16px 24px 16px;
  box-sizing: border-box;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 20px;
  position: relative;
  margin-left: 15px;
  margin-right: 5px;

  @media (max-width: 1440px) {
    margin-left: 5px;
    margin-right: 2px;
    font-size: 90%;
  }
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #005d1d;
`;
export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #005d1d;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 6px;
      background: #005d1d;
    }
  }
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: #005d1d;
      border: 1px solid #005d1d;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin: 5px;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }
  `}
`;

/// Report 결과 Row
export const CardResultRow = styled.div<rowProp>`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-wrap: nowrap;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  @media (max-width: 500px) {
    padding-right: 4vw;
    padding-left: 4vw;
  }
`;

export interface borderColor {
  borderColor?: string;
}

export const CardResultInner = styled.div<borderColor>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  box-sizing: border-box;
  width: 96%;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
// border-top: solid 2px ${({ borderColor }) => (borderColor ? borderColor : "#d1d1d6")};

export const CardResultLeft = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  width: 70%;
  box-sizing: border-box;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 2%;

  font-size: 18px;
  line-height: 1.5;
  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.56px;
  color: #2c2c2e;

  @media (max-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 13px;
    width: 100%;
  }
`;

export const CardResultRight = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  box-sizing: border-box;
  padding-top: 1%;
  padding-bottom: 1%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const ResultLogo = styled.img`
  width: 106.5px;
  height: 41px;
  margin-right: 15px;

  @media (max-width: 500px) {
    width: 106.5px;
    height: 41px;
    margin-right: 15px;
  }
`;

export const ReportButton = styled.button<rowProp>`
  background-repeat: no-repeat;
  border: none;
  height: 90%;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  opacity: 1;
  :hover {
    opacity: 0.5;
  }
  font-size: 120%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;

  border-radius: 5px;
  background-color: ${({ borderColor }) =>
    borderColor ? borderColor : "#008128"};

  @media (max-width: 1440px) {
    font-size: 1vw;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export const ReportButtonDisabled = styled.button`
  background-repeat: no-repeat;
  border: solid 1px #d1d1d6;
  height: 90%;
  width: 100%;
  overflow: hidden;
  outline: none;
  opacity: 1;
  font-size: 120%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.65px;
  text-align: center;
  color: #aeaeb2;
  border-radius: 5px;
  background-color: #f4f4f5;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    font-size: 1vw;
  }

  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

export const ExplainCtnr = styled.div<prop>`
  display: ${({ inactive }) => (inactive ? "none" : "flex")};
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f4f4f5;
  font-size: 15px;
  width: auto;
  line-height: 1.5;

  @media (max-width: 700px) {
    margin-top: 10px;
    padding: 10px;
    font-size: 13px;
  }
`;

export const ExplainComment = styled.div<prop>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  padding-top: 0px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 530;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.66px;
  color: #005d1d;

  @media (max-width: 1440px) {
    padding-right: 15px;
    font-size: 13px;
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

export const ExplainTittle = styled.div<tittleProp>`
  font-size: 16px;
  display: inline-flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  font-family: NotoSansKR;
  flex-direction: row;
  cursor: pointer;
`;

export const ExplainArrow = styled.div<Aprop>`
  border: solid #005d1d;
  border-width: 0 3px 3px 0;
  display: inline-block;
  margin-left: 5px;
  margin-top: ${({ active }) => (active ? "-5px" : "3px")};
  border-radius: 2px;
  cursor: pointer;
  padding: 3px;
  transform: ${({ active }) => (active ? "rotate(45deg)" : "rotate(-135deg)")};
  -webkit-transform: ${({ active }) =>
    active ? "rotate(45deg)" : "rotate(-135deg)"};
`;
