import { createReducer } from "@reduxjs/toolkit";
import { PaymentData } from "src/data/api-response/payment";
import { fetchPayments } from "./payment.actions";
import PaymentService from "./payment.service";


const PaymentReducer = (service: PaymentService) =>
    createReducer(service, (builder) => {
        builder
        .addCase(
            fetchPayments.fulfilled, (service, action) => {
                service.updatePayments(action.payload.data as PaymentData[]);
            }
        );
});

export default PaymentReducer;