import { createReducer } from "@reduxjs/toolkit";
import { uiVisible, uiClicked } from "./ui.actions";
import UIService from "./ui.service";


const UiReducer = (service: UIService) =>
    createReducer(service, (builder) => {
        builder
        .addCase(
            uiVisible, (service, action) => {
                service.setVisiblebyId(action.payload.id, action.payload.visible);
            }
        )
        .addCase(
            uiClicked, (service, action) => {
                service.setClickedbyId(action.payload.id, action.payload.clicked);
            }
        );
});

export default UiReducer;