import { useState } from "react";
import { useDispatch } from "react-redux";
import ringLoadingGif from "src/assets/gif/ring-loading.gif";
import { Path, _client } from "src/apis/client";
import { useHistory } from "react-router-dom";
import { UserLoginStatusType } from "src/data/type/status";
import { useSelector } from "src/hooks/useSelector";
import { userJoin } from "src/services/user/user.actions";
import completeImg from "src/assets/png/coplete.png";
import {
  clearAnbuHistory,
  clearReportResponse,
  getAnbuHistory,
  getFreeAnbuHistory,
} from "src/services/anbu/anbu.actions";

import {
  user_logout,
  user_me,
  user_set_login_status,
  user_verify_token,
} from "src/services/user/user.actions";

import {
  ChildForm,
  LineDiv,
  Background,
  Inner,
  StepDiv,
  StepWrapperDiv,
  TitleDiv,
  ContentDiv,
  ProgressItem,
  ProgressIcon,
  CompleteIcon,
  CompleteItem,
  CompleteBtn,
  CompleteBtnContainer,
  Statement,
  PhoneNumberInputContainer,
  PhoneNumberInput,
  VerifyBtn,
  Comment,
  FailComment,
} from "./unregister.Style";
import useLoginModal from "../loginModal/LoginModal.hook";

export interface RegformProp {
  setStep(step: number): void;
  setFormData(form: any): void;
  formData: userJoin;
}

export const unregister = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { loginStatus, user, point, phoneNumber } = useSelector((state) => {
    return {
      loginStatus: state.userReducer.get_login_status(),
      user: state.userReducer.get_user(),
      point: state.userReducer.get_user()?.point,
      phoneNumber: state.userReducer.get_user()?.phone,
    };
  });

  const clickHandlerCreate = () => {
    console.log("execute Unregister");
    _client
      .postApiWithToken(Path.unregister, {})
      .then(() => {
        alert("성공적으로 탈퇴 되었습니다.");
        localStorage.clear();
        sessionStorage.clear();
        dispatch(user_logout());
        history.push("/");
        // 로그아웃시 안부 리포트 값 등이 리엑트 store에 남아있음, 초기화
        dispatch(clearReportResponse());
        dispatch(clearAnbuHistory());

      })
      .catch((response) => {
        alert(response);
      });
  };

  return (
    <Background>
      <Inner>
        <CompleteItem>
          <TitleDiv>회원탈퇴</TitleDiv>
        </CompleteItem>
        <br />
        <Statement>
          {"  "}회원 탈퇴시 모든 정보가 삭제되며 사용중인 이용권이 소멸됩니다.
          계속 하시겠습니까?
        </Statement>
        <CompleteBtnContainer active={true}>
          <CompleteBtn onClick={() => clickHandlerCreate()}>
            탈퇴하기
          </CompleteBtn>
        </CompleteBtnContainer>
      </Inner>
    </Background>
  );
};
