import React, { CSSProperties, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { UiComponentType } from "src/data/type/ui";
import { uiVisible } from "src/services/ui/ui.actions";
import closeBtnImg from "src/assets/png/close.png";
import { useMediaQuery } from "react-responsive";

export interface ModalProps {
  isOpen: boolean;
  modalMainStyle?: CSSProperties;
  closeBtnStyle?: CSSProperties;
  children: ReactNode;
}

const backgroudStyle: CSSProperties = {
  zIndex: 200,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.6)",
};

const defatulMainStyle: CSSProperties = {
  zIndex: 210,
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "400px",
  height: "600px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const mobileMainStyle: CSSProperties = {
  position: "fixed",
  background: "#fff",
  borderRadius: "5px",
  width: "300px",
  height: "auto",
  top: "45%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 210,
};

const defaultCloseBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: -45,
  display: "block",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  cursor: "pointer",
  zIndex: 210,
  border: 0
};

const mobileCloseBtnStyle: CSSProperties = {
  position: "absolute",
  display: "block",
  bottom: "-40px",
  left: "40%",
  width: "40px",
  height: "27px",
  transition: "transform 0.1s",
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
  objectFit: "cover",
  backgroundSize: "contain",
  zIndex: 210,
};

const Modal = (prop: ModalProps) => {
  const { isOpen, modalMainStyle, closeBtnStyle, children } = prop;
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(uiVisible({ id: UiComponentType.MODAL_LOGIN, visible: false }));
    dispatch(uiVisible({ id: UiComponentType.MODAL_AGENT_SEARCH, visible: false }));
  };
  const isDesktop = useMediaQuery({
    query: "(min-width: 500px)",
  });

  let mainStyle = defatulMainStyle;
  if (modalMainStyle) mainStyle = modalMainStyle;

  if (isOpen)
    return (
      <>
        <div style={backgroudStyle} onClick={closeModal} />
        <div style={isDesktop ? mainStyle : mobileMainStyle}>
        {/* <div style={mainStyle}> */}
          <button
            style={isDesktop ? defaultCloseBtnStyle : mobileCloseBtnStyle}
            onClick={closeModal}
          >
            <img
              style={{
                backgroundColor: "transparent",
              }}
              src={closeBtnImg}
              alt="test"
            />
          </button>
          {children}
        </div>
      </>
    );
  else return null;
};

export default Modal;
