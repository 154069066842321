import { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AddressList } from "src/data/api-response/address";
import {
  SearchInputStatusType,
  SearchResultStatusType,
} from "src/data/type/search";
import { useSelector } from "src/hooks/useSelector";
import {
  searchAddress,
  searchStatus,
  setCurrentTab,
  setSearchInputKeyword,
  setSearchResultStatusType,
  setSearchSido,
  setCurrentPageAtService,
} from "src/services/search/search.actions";

import {
  getAnbuHistory,
  getFreeAnbuHistory,
} from "src/services/anbu/anbu.actions";

import usePriceLookupModal from "../priceLookupModal/PriceLookupModal.hook";
import { UserLoginStatusType } from "src/data/type/status";

export default function useSearchPage() {
  const dispatch = useDispatch();

  function tabClick(tabID: number) {
    dispatch(setCurrentTab(tabID));
  }
  const [sidoName, setSidoName] = useState<string>("서울특별시");
  const [searchInput, setSearchInput] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);

  const [searchHistory, setSerachHistory] = useState<string[]>(
    JSON.parse(localStorage.getItem("searchHistory") || "[]")
  );

  const [searchInputStatus, setSearchInputStatus] =
    useState<SearchInputStatusType>(SearchInputStatusType.Enabled);

  const [searchResultStatus, setSearchResultStatus] =
    useState<SearchResultStatusType>(SearchResultStatusType.Initial);

  const [currentSearchResult, setCurrentSearchResult] =
    useState<Array<AddressList>>();
  const [currentResultMsg, setResultMsg] =
    useState<string | undefined | null>("");

  const [currentPage, setCurrentPage] = useState(1);
  const [currentUserPhone, setCurrentUserPhone] = useState<string>("");
  let PageSize = 10;

  const { loginStatus, user, point } = useSelector((state) => {
    return {
      loginStatus: state.userReducer.get_login_status(),
      user: state.userReducer.get_user(),
      point: state.userReducer.get_user()?.point,
    };
  });

  function addSearchHistoryItem(address: string) {
    setHistoryOpen(false)
    if (address !== null) {
      let allAddress: string[] = JSON.parse(
        localStorage.getItem("searchHistory") || "[]"
      );

      // if it's not exist in past, add it
      if (allAddress.indexOf(address) < 0) {

        // if it's over 5, remove old one
        if (allAddress.length > 4) {
          allAddress.shift();
        }
        allAddress.push(address);
        localStorage.setItem("searchHistory", JSON.stringify(allAddress));
        setSerachHistory(allAddress);
      }
    } else {
      console.log("Search address string is null");
    }
  }

  function removeSearchHistoryItem(address: string) {
    setHistoryOpen(true)
    if (address !== null) {
      let allAddress: string[] = JSON.parse(
        localStorage.getItem("searchHistory") || "[]"
      );
      const filteredArray = allAddress.filter((res) => res !== address);

      localStorage.setItem("searchHistory", JSON.stringify(filteredArray));
      allAddress = JSON.parse(localStorage.getItem("searchHistory") || "[]");
      setSerachHistory(allAddress);
    } else {
      console.log("Search address string is null");
    }
  }

  useEffect(() => {
    if (loginStatus === UserLoginStatusType.LOGGEDIN && user) {
      setCurrentUserPhone(user?.phone);
    }
  }, [loginStatus, user]);

  useEffect(() => {
    dispatch(setSearchInputKeyword(searchInput));
  }, [searchInput]);

  const getSearchResults = useSelector((state) =>
    state.searchReducer.get_search_result()
  );

  const getSearchStatus = useSelector((state) =>
    state.searchReducer.get_search_status()
  );

  const searchInputKeyword = useSelector((state) =>
    state.searchReducer.get_search_input_keyword()
  );

  const searchSido = useSelector((state) =>
    state.searchReducer.get_search_sido()
  );

  const searchResultStatusAtService = useSelector((state) =>
    state.searchReducer.get_search_result_status()
  );

  const currentTab = useSelector((state) =>
    state.searchReducer.get_current_tab()
  );

  const currentPageAtService = useSelector((state) =>
    state.searchReducer.get_current_page()
  );

  const { open_price_lookup_modal } = usePriceLookupModal();
  const openPriceLookupPopup = (
    addr_type: string,
    addr_id: string,
    addr_text: string,
    floor: string
  ) => {
    try {
      open_price_lookup_modal(addr_type, addr_id, addr_text, floor);
    } catch (e) {
      console.error(`시세 열람 모달 열기 실패, 원인: ${e}`);
    }
  };

  useEffect(() => {
    if (currentTab === 1) {
      if (loginStatus === UserLoginStatusType.LOGGEDIN && user) {
        dispatch(
          getAnbuHistory({
            user_phone: currentUserPhone,
          })
        );
      } else {
        dispatch(getFreeAnbuHistory());
      }
    }
  }, [currentTab]);

  // Update result array
  useEffect(() => {
    if (getSearchResults?.searchResult) {
      setCurrentSearchResult(getSearchResults?.searchResult);
    }
  }, [currentPage, getSearchResults]);

  // Update result message
  useEffect(() => {
    setResultMsg(getSearchResults?.resultMsg);
  }, [getSearchResults]);

  // 초기상태일때 status api 점검
  useEffect(() => {
    if (searchResultStatus === SearchResultStatusType.Initial) {
      setSearchInputStatus(SearchInputStatusType.Disabled);
      dispatch(searchStatus());
    }
  }, [searchResultStatus]);

  // Update result total Count
  useEffect(() => {
    let _resultCount = getSearchResults?.searchResult?.length;
    let _pageCount = getSearchResults?.pages;
    let _resultCode = getSearchResults?.resultCode;
    // Valid total count
    if (_resultCode && _pageCount) {
      let _resultCountNumber = Number(_resultCount);
      setTotalCount(_resultCountNumber);
      setPageCount(_pageCount);
      if (_resultCountNumber === 0) {
        setSearchInputStatus(SearchInputStatusType.Enabled);
        setSearchResultStatus(SearchResultStatusType.Zero);
        dispatch(setSearchResultStatusType(SearchResultStatusType.Zero));
      } else if (_pageCount > 9) {
        setSearchInputStatus(SearchInputStatusType.Enabled);
        setSearchResultStatus(SearchResultStatusType.TooMany);
        dispatch(setSearchResultStatusType(SearchResultStatusType.TooMany));
      } else if (
        _resultCountNumber === 1 &&
        getSearchResults?.searchResult[0].addrid === "1161-1996-059808"
      ) {
        setSearchInputStatus(SearchInputStatusType.Enabled);
        setSearchResultStatus(SearchResultStatusType.Zero);
        dispatch(setSearchResultStatusType(SearchResultStatusType.Zero));
      } else {
        setSearchInputStatus(SearchInputStatusType.Enabled);
        setSearchResultStatus(SearchResultStatusType.Normal);
        dispatch(setSearchResultStatusType(SearchResultStatusType.Normal));
      }
    } else {
      setSearchInputStatus(SearchInputStatusType.Enabled);
      setSearchResultStatus(SearchResultStatusType.Zero);
      dispatch(setSearchResultStatusType(SearchResultStatusType.Zero));
    }
  }, [getSearchResults]);

  useEffect(() => {
    if (getSearchStatus === undefined) {
      // 서비스 점검중, 잠시 후 다시 이용해주세요.
      setSearchInputStatus(SearchInputStatusType.Disabled);
      setSearchResultStatus(SearchResultStatusType.InternalError);
      dispatch(setSearchResultStatusType(SearchResultStatusType.InternalError));
    } else if (getSearchStatus.searchStatus === false) {
      // 인터넷 등기소 점검중, 잠시 후 다시 이용해주세요.
      setSearchInputStatus(SearchInputStatusType.Disabled);
      setSearchResultStatus(SearchResultStatusType.IrosError);
      dispatch(setSearchResultStatusType(SearchResultStatusType.IrosError));
    } else {
      // 정상 상태
      setSearchInputStatus(SearchInputStatusType.Enabled);
      setSearchResultStatus(SearchResultStatusType.Ready);
      dispatch(setSearchResultStatusType(SearchResultStatusType.Ready));
    }
  }, [getSearchStatus]);

  // 최초 검색버튼 (돋보기) 클릭
  const searchBtnClick = () => {
    if (searchInput.toString().length < 1) {
      console.log("empty search input, skip dispatch" + searchInput);
    } else if (searchInput.toString().trim().length === 0) {
      console.log("only spaces, skip dispatch" + searchInput);
    } else {
      addSearchHistoryItem(searchInput);
      setSearchInputStatus(SearchInputStatusType.Disabled);
      setSearchResultStatus(SearchResultStatusType.Loading);
      dispatch(setSearchResultStatusType(SearchResultStatusType.Loading));
      dispatch(
        searchAddress({
          addressType: "전체",
          sido: sidoName,
          address: searchInput,
          status: "현행",
          page: 1,
        })
      );
      dispatch(setSearchSido(sidoName));
      dispatch(setCurrentPageAtService(1));
    }
  };

  const searchBtnClickUsingHistory = (searchInputName: string) => {
    if (searchInputName.toString().length < 1) {
      console.log("empty search input, skip dispatch" + searchInputName);
    } else if (searchInputName.toString().trim().length === 0) {
      console.log("only spaces, skip dispatch" + searchInputName);
    } else {
      addSearchHistoryItem(searchInputName);
      setSearchInputStatus(SearchInputStatusType.Disabled);
      setSearchResultStatus(SearchResultStatusType.Loading);
      dispatch(setSearchResultStatusType(SearchResultStatusType.Loading));
      dispatch(
        searchAddress({
          addressType: "전체",
          sido: sidoName,
          address: searchInputName,
          status: "현행",
          page: 1,
        })
      );
      dispatch(setSearchSido(sidoName));
      dispatch(setCurrentPageAtService(1));
    }
  };

  // page 번호 클릭
  const pageBtnClick = (page_num: number) => {
    if (page_num > 0) {
      setSearchInputStatus(SearchInputStatusType.Disabled);
      setSearchResultStatus(SearchResultStatusType.Loading);
      dispatch(setSearchResultStatusType(SearchResultStatusType.Loading));
      dispatch(setCurrentPageAtService(page_num));
      dispatch(
        searchAddress({
          addressType: "전체",
          sido: searchSido,
          address: searchInputKeyword,
          status: "현행",
          page: page_num,
        })
      );
    }
  };

  return {
    currentTab,
    tabClick,
    sidoName,
    setSidoName,
    searchInput,
    setSearchInput,
    searchBtnClick,
    currentPage,
    setCurrentPage,
    PageSize,
    currentSearchResult,
    currentResultMsg,
    totalCount,
    searchInputStatus,
    searchResultStatus,
    searchResultStatusAtService,
    openPriceLookupPopup,
    pageBtnClick,
    pageCount,
    currentPageAtService,
    addSearchHistoryItem,
    removeSearchHistoryItem,
    searchHistory,
    historyOpen,
    setHistoryOpen,
    searchBtnClickUsingHistory
  };
}
