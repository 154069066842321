import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import coinImage from "src/assets/png/coin-big.png";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { _client } from "src/apis/client";
import { useSelector } from "src/hooks/useSelector";
import payment, {
  PaymentPayloadType,
} from "src/services/payment/payment.reducerV2";
import {
  user_me,
} from "src/services/user/user.actions";
import { GrayTextButton } from "src/styles/common/button";
import { GrayForm } from "../common/form/Form";
import {
  BottomCtnr,
  OuterCtnr,
  PaymentPageBgr,
  MyShellCtnr,
  MyShellTittle,
  MyShellSubtittle,
  MyShellImg,
  MyShellInner,
  CoinImg,
  MyShellCount,
  MyShellBtn,
} from "./PaymentPageStype";

export const PaymentSuccessPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isError = useSelector((state) => state.payment2Reducer.errorMsg);
  const orderId = useSelector((state) => state.payment2Reducer.orderId);
  const errorMsg = useSelector((state) => state.payment2Reducer.errorMsg);

  const { loginStatus, user, point } = useSelector((state) => {
    return {
      loginStatus: state.userReducer.get_login_status(),
      user: state.userReducer.get_user(),
      point: state.userReducer.get_user()?.point,
    };
  });

  const history = useHistory();
  const goSearch = () => {
    try {
      history.push("/search");
    } catch (e) {
      console.error(`Failed to go search page ${e}`);
    }
  };
  const goMyPage = () => {
    try {
      dispatch(user_me());
      history.push("/mypage");
    } catch (e) {
      console.error(`Failed to go search page ${e}`);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const orderId = params.get("orderId");
    const paymentKey = params.get("paymentKey");
    const amount = params.get("amount");
    if (orderId && paymentKey && amount) {
      let res: Promise<any> = _client.fetchApiWithToken(
        `/payment/approve?orderId=${orderId}&paymentKey=${paymentKey}&amount=${amount}`
      );
      res.then((res) => {
        console.log(res);
        const paymentPayload: PaymentPayloadType = {
          orderId,
          isError: false,
          errorMsg: undefined,
        };
        dispatch(payment.actions.updatePayment(paymentPayload));
        dispatch(user_me());
      });
      res.catch((err) => {
        console.log(err);
        const paymentPayload: PaymentPayloadType = {
          orderId,
          isError: true,
          errorMsg: err,
        };
        dispatch(payment.actions.updatePayment(paymentPayload));
        dispatch(user_me());
      });
    } else {
      const paymentPayload: PaymentPayloadType = {
        orderId: null,
        isError: true,
        errorMsg: "잘못된 경로입니다.",
      };
      dispatch(payment.actions.updatePayment(paymentPayload));
    }
  }, [location.search]);

  if (orderId && !isError)
    return (
      <PaymentPageBgr>
        <OuterCtnr>
          <BottomCtnr>
            <MyShellCtnr>
              <MyShellTittle>결제 성공!</MyShellTittle>
              <MyShellTittle>주문번호: {orderId}</MyShellTittle>
              <MyShellSubtittle>
                *껍데기란 리포트 발급시 결제에 사용되는 이용권 단위입니다.
              </MyShellSubtittle>
              <MyShellImg>
                <MyShellInner>
                  <CoinImg src={coinImage} />
                </MyShellInner>
              </MyShellImg>
              <MyShellCount>{point} 개</MyShellCount>
              <MyShellBtn onClick={goMyPage}>내 정보 페이지로 이동</MyShellBtn>
              <MyShellBtn onClick={goSearch}>주소 검색하기로 이동</MyShellBtn>
            </MyShellCtnr>
          </BottomCtnr>
        </OuterCtnr>
      </PaymentPageBgr>
    );
  return (
    <PaymentPageBgr>
      <OuterCtnr>
        <BottomCtnr>
          <MyShellCtnr>
            <MyShellTittle>{errorMsg}</MyShellTittle>
            <MyShellImg>
              <MyShellInner>
                <CoinImg src={coinImage} />
              </MyShellInner>
            </MyShellImg>
            <MyShellCount>{point} 개</MyShellCount>
            <MyShellBtn onClick={goMyPage}>내 정보 페이지로 이동</MyShellBtn>
            <MyShellBtn onClick={goSearch}>주소 검색하기로 이동</MyShellBtn>
          </MyShellCtnr>
        </BottomCtnr>
      </OuterCtnr>
    </PaymentPageBgr>
  );

  // if (orderId && !isError)
  //     return (
  //         <GrayForm>
  //             <div>
  //                 주문 번호 {orderId} 결제 성공
  //             </div>
  //             <Link to="/mypage">
  //                 <GrayTextButton>내 정보 페이지로 이동</GrayTextButton>
  //             </Link>
  //         </GrayForm>
  //     );
  // return (
  //     <GrayForm>
  //         <div>
  //             {errorMsg}
  //         </div>
  //         <Link to="/mypage">
  //             <GrayTextButton>내 정보 페이지로 이동</GrayTextButton>
  //         </Link>
  //     </GrayForm>
  // )
};
