import { strict } from "assert/strict";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Path, _client } from "src/apis/client";
import { user_join } from "src/services/user/user.actions";
import { RegformProp } from "./registerPage";
import {
  Input,
  ConfirmBtn,
  ConfirmBtnContainer,
  InputContainer,
  FormStatement,
  RedStar,
  SubInfo,
} from "./userForm.style";

import {
  Statement,
  GrayText,
  PhoneNumberInputContainer,
  PhoneNumberInput,
  VerifyBtn,
  DisabledBtn,
} from "./verifyPhonePage.style";

export const UserForm = (prop: RegformProp) => {
  const dispatch = useDispatch();
  const { setFormData, formData, setStep } = prop;
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [agentEnrollId, setAgentEnrollId] = useState("");
  const [userType, setUserType] = useState("general");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const emailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const usernameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value);
  };

  const agentEnrollIdHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgentEnrollId(e.currentTarget.value);
  };

  const userTypeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserType(e.currentTarget.value);
  };

  const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const passwordConfirmHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(e.currentTarget.value);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      email,
      pw: password,
      full_name: username,
      agent_enroll_id: agentEnrollId,
      user_type: userType,
    });
  }, [email, password, passwordConfirm, username, agentEnrollId, userType]);

  const join = () => {
    console.log("가입 시도");
    if (password !== passwordConfirm) {
      alert("비밀번호가 일치하지 않습니다");
    } else if (password.toString().length < 8) {
      alert("비밀번호가 너무 짧습니다.");
    } else {
      _client
        .postApiV2(Path.join("mobile"), formData)
        .then(() => {
          console.log("가입 성공");
          setStep(4);
        })
        .catch((err) => {
          if (err.status === 422) {
            alert("이메일 주소의 형식이 잘못되었습니다.");
          } else {
            console.log(err);
            if (err.data.error) {
              alert("가입에 실패하였습니다: " + err.data.error)
            } else {
              alert("가입에 실패하였습니다: " + err.statusText)
            }
          }
        });
    }
  };
  return (
    <>
      <Statement>
        {"  "}휴대폰번호 인증{" "}
        <GrayText>(휴대폰 번호가 로그인을 위한 ID로 사용됩니다.)</GrayText>
      </Statement>
      {/* <PhoneNumberInputContainer active={true} finished={verifySuccess}> */}
      <PhoneNumberInputContainer active={true} finished={true}>
        <PhoneNumberInput
          type="number"
          pattern="[0-9]*"
          inputMode="numeric"
          value={formData.phone}
          disabled={false}
          placeholder="본인의 휴대폰 번호를 입력하세요 (숫자만 입력)"
        />
        {/* <DisabledBtn className="VerifyBtn" disabled={true}>
          {" "}
          전송하기{" "}
        </DisabledBtn> */}
      </PhoneNumberInputContainer>
      <SubInfo>* 인증이 정상적으로 완료되었습니다.</SubInfo>
      <FormStatement className="passwordForm">
        <RedStar> *</RedStar> 비밀번호
      </FormStatement>
      <InputContainer>
        <Input
          type="password"
          value={password}
          onChange={(e) => passwordHandler(e)}
          placeholder="비밀번호(8자리 이상)를 입력해주세요"
        />
      </InputContainer>
      <FormStatement className="passwordConfirmForm">
        <RedStar> *</RedStar> 비밀번호 확인
      </FormStatement>
      <InputContainer>
        <Input
          type="password"
          value={passwordConfirm}
          onChange={(e) => passwordConfirmHandler(e)}
          placeholder="비밀번호(8자리 이상)를 입력해주세요"
        />
      </InputContainer>
      <FormStatement className="userForm">
        <RedStar> *</RedStar> 사용자 이름
      </FormStatement>
      <InputContainer>
        <Input
          type="text"
          value={username}
          onChange={(e) => usernameHandler(e)}
          placeholder="사용자 이름을 입력해주세요"
        />
      </InputContainer>
      <FormStatement className="emailForm">
        <RedStar> *</RedStar> 이메일 주소{" "}
      </FormStatement>
      <InputContainer>
        <Input
          type="text"
          value={email}
          onChange={(e) => emailHandler(e)}
          placeholder="이메일 주소를 입력해주세요"
        />
      </InputContainer>
      <ConfirmBtnContainer>
        <ConfirmBtn className="btn" onClick={() => join()}>
          가입 완료하기
        </ConfirmBtn>
      </ConfirmBtnContainer>
    </>
  );
};
