import { createReducer } from "@reduxjs/toolkit";
import { GenReportResponse } from "src/data/api-response/address";
import { AnbuResponse } from "src/data/api-response/anbu";
import {
  generateAnbuReport,
  getAnbuHistory,
  generateFreeAnbuReport,
  getFreeAnbuHistory,
  clearReportResponse,
  clearAnbuHistory
} from "./anbu.actions";
import AnbuService from "./anbu.service";

const AnbuReducer = (service: AnbuService) =>
  createReducer(service, (builder) => {
    builder
      .addCase(getAnbuHistory.fulfilled, (service, action) => {
        service.update_anbu_history(action.payload as AnbuResponse);
      })
      .addCase(generateAnbuReport.fulfilled, (service, action) => {
        service.set_gen_report_response(action.payload as GenReportResponse);
      })
      .addCase(getFreeAnbuHistory.fulfilled, (service, action) => {
        service.update_free_anbu_history(action.payload as AnbuResponse);
      })
      .addCase(generateFreeAnbuReport.fulfilled, (service, action) => {
        service.set_gen_free_report_response(action.payload as GenReportResponse);
      })
      .addCase(clearReportResponse, (service, action) => {
        service.clear_gen_report_response();
      })
      .addCase(clearAnbuHistory, (service, action) => {
        service.clear_anbu_history;
      })
  });

export default AnbuReducer;
