import { Candidate } from "../api-response/address";

class PriceLookup {
  private _user_id: string;
  public get user_id(): string {
    return this._user_id;
  }
  public set user_id(value: string) {
    this._user_id = value;
  }
  private _possibility_id: string;
  public get possibility_id(): string {
    return this._possibility_id;
  }
  public set possibility_id(value: string) {
    this._possibility_id = value;
  }
  private _iros_full_address: string;
  public get iros_full_address(): string {
    return this._iros_full_address;
  }
  public set iros_full_address(value: string) {
    this._iros_full_address = value;
  }
  private _possible: boolean;
  public get possible(): boolean {
    return this._possible;
  }
  public set possible(value: boolean) {
    this._possible = value;
  }
  private _result_comment: string;
  public get result_comment(): string {
    return this._result_comment;
  }
  public set result_comment(value: string) {
    this._result_comment = value;
  }
  private _house_type: string;
  public get house_type(): string {
    return this._house_type;
  }
  public set house_type(value: string) {
    this._house_type = value;
  }
  private _is_dasaedae: boolean;
  public get is_dasaedae(): boolean {
    return this._is_dasaedae;
  }
  public set is_dasaedae(value: boolean) {
    this._is_dasaedae = value;
  }
  private _price_type: string;
  public get price_type(): string {
    return this._price_type;
  }
  public set price_type(value: string) {
    this._price_type = value;
  }
  private _price: string;
  public get price(): string {
    return this._price;
  }
  public set price(value: string) {
    this._price = value;
  }
  private _price_update_date: string;
  public get price_update_date(): string {
    return this._price_update_date;
  }
  public set price_update_date(value: string) {
    this._price_update_date = value;
  }
  private _iros_address_id: string;
  public get iros_address_id(): string {
    return this._iros_address_id;
  }
  public set iros_address_id(value: string) {
    this._iros_address_id = value;
  }
  private _priv_area: string;
  public get priv_area(): string {
    return this._priv_area;
  }
  public set priv_area(value: string) {
    this._priv_area = value;
  }
  private _pub_area: string;
  public get pub_area(): string {
    return this._pub_area;
  }
  public set pub_area(value: string) {
    this._pub_area = value;
  }
  private _first_candidate: Candidate | undefined;
  public get first_candidate(): Candidate | undefined {
    return this._first_candidate;
  }
  public set first_candidate(value: Candidate | undefined) {
    this._first_candidate = value;
  }
  private _all_candidates: Candidate[] | undefined = [];
  public get all_candidates(): Candidate[] | undefined {
    return this._all_candidates;
  }
  public set all_candidates(value: Candidate[] | undefined) {
    this._all_candidates = value;
  }

  constructor(
    user_id: string,
    possibility_id: string,
    iros_full_address: string,
    possible: boolean,
    result_comment: string,
    house_type: string,
    is_dasaedae: boolean,
    price_type: string,
    price: string,
    price_update_date: string,
    iros_address_id: string,
    priv_area: string,
    pub_area: string,
    first_candidate: Candidate | undefined,
    all_candidates: Candidate[] | undefined
  ) {
    (this._user_id = user_id),
      (this._possibility_id = possibility_id),
      (this._iros_full_address = iros_full_address),
      (this._possible = possible),
      (this._result_comment = result_comment),
      (this._house_type = house_type),
      (this._is_dasaedae = is_dasaedae),
      (this._price_type = price_type),
      (this._price = price),
      (this._price_update_date = price_update_date),
      (this._iros_address_id = iros_address_id),
      (this._priv_area = priv_area),
      (this._pub_area = pub_area),
      (this._first_candidate = first_candidate),
      (this._all_candidates = all_candidates);
  }
}
export default PriceLookup;
