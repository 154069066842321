import UiComponent from "src/data/models/uiComponent";
import { UiComponentType } from "src/data/type/ui";
import Service from "../service";


export default class UIService extends Service {
    private _uiComponents : UiComponent[];

	constructor(uiComponents?: Array<UiComponent>) {
        super();
        this._uiComponents = uiComponents || new Array<UiComponent>();
	}

    /**
     * Getter uiComponents
     * @return {UiComponent[] }
     */
	public get uiComponents(): UiComponent[]  {
		return this._uiComponents;
	}

    public getUiComponent(id : string): UiComponent {
		let uiFound = this._uiComponents.find(uiComponent => uiComponent.id === id);
        if (uiFound === undefined) throw new Error(`ui component not found: ${id}`);
        return uiFound;
	}

    /**
     * get uiComponent Visible by Component Id
     * @return {UiComponent}
     */
	public getVisibleById(id : string): boolean{
        return this.getUiComponent(id).visible;
	}

    /**
     * get uiComponent Clicked by Component Id
     * @return {UiComponent}
     */
	public getClickedById(id : string): boolean{
		return this.getUiComponent(id).clicked;
	}

    /**
     * Setter uiComponents
     * @param {UiComponent} value
     */
	public setVisiblebyId(id : UiComponentType, visible : boolean) {
        this.getUiComponent(id).id = id;
        this.getUiComponent(id).visible = visible;
	}

    public setClickedbyId(id : string, clicked : boolean) {
        this.getUiComponent(id).clicked = clicked;
	}

}