import React from "react";
import { Provider } from "react-redux";
import store from "src/services/store"
import { MainPage } from "./components/mainPage/MainPage";



function App() {
  return (
      <Provider store={store}>
        <MainPage/>
      </Provider>
  );
}

export default App;
