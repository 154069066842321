import { AddressList } from "../api-response/address";

class SearchResult {
  private _resultMsg: string | null;
  private _resultCode: number | null;
  private _pages: number | null;
  private _searchResult: AddressList[] = [];

  constructor(
    msg: string | null,
    code: number | null,
    pages: number | null,
    result: AddressList[]
  ) {
    this._resultMsg = msg;
    this._resultCode = code;
    this._pages = pages;
    this._searchResult = result;
  }

  /**
   * Getter resultMsg
   * @return {string | null}
   */
  public get resultMsg(): string | null {
    return this._resultMsg;
  }

  /**
   * Getter resultCode
   * @return {number | null}
   */
  public get resultCode(): number | null {
    return this._resultCode;
  }

  /**
   * Getter pages
   * @return {number | null}
   */
  public get pages(): number | null {
    return this._pages;
  }

  /**
   * Getter searchResult
   * @return {AddressList[]}
   */
  public get searchResult(): AddressList[] {
    return this._searchResult;
  }

  /**
   * Setter resultMsg
   * @param {string | null} value
   */
  public set resultMsg(value: string | null) {
    this._resultMsg = value;
  }

  /**
   * Setter resultCode
   * @param {number | null} value
   */
  public set resultCode(value: number | null) {
    this._resultCode = value;
  }

  /**
   * Setter search Result
   * @param {AddressList[]}
   */
  public set searchResult(value: AddressList[]) {
    this._searchResult = value;
  }

}

export default SearchResult;
